<template>
  <div :class="['menu', {collapsed: collapse}]">
    <div class="logo">
      <img alt="OvdInfo" src='../../assets/img/logo.svg'/>
    </div>
    <div v-if="isAuthenticated" class="collapse" @click="toggleCollapseMenu">
      <span v-if="!collapse">Свернуть</span>
      <Icon name="chevron-light"/>
    </div>
    <div v-if="isAuthenticated" :class="['menu-tabs']">
      <router-link class="active" to="/call">
        <span><Icon name="phone"/><span v-if="!collapse">Звонок</span></span>
      </router-link>
      <router-link to="/chat">
        <span><Icon name="chat"/><span v-if="!collapse">Чат</span></span>
      </router-link>
      <router-link to="/freeEntry">
        <span><Icon name="status"/><span v-if="!collapse">Анкета</span></span>
      </router-link>
      <router-link to="/avtozaki">
        <span><Icon name="search"/><span v-if="!collapse">Поиск</span></span>
      </router-link>
      <router-link to="/jurhelpclientpack">
        <span><Icon name="lawyer"/><span v-if="!collapse">Юристы</span></span>
      </router-link>
      <router-link to="/obzvon">
        <span><Icon name="obzvon"/><span v-if="!collapse">Обзвон</span></span>
      </router-link>
      <router-link to="/court" v-if="courts_on === 'true'">
        <span><Icon name="court"/><span v-if="!collapse">Результаты судов</span></span>
      </router-link>
      <router-link to="/sessions">
        <span><Icon name="person-fill"/><span v-if="!collapse">Сессии</span></span>
      </router-link>
      <router-link to="/sip-test" v-if="sip_on === 'true'">
        <span><Icon name="outbound-call"/><span v-if="!collapse">Звонилка</span></span>
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from '../ui/Icon';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'MenuSidebar',
  components: {Icon},
  data() {
    return {
      sip_on: process.env.VUE_APP_SIP_ON,
      courts_on: process.env.VUE_APP_COURTS_ON,
    }
  },
  computed: {
    ...mapGetters({isAuthenticated: 'isAuthenticated', collapse: 'collapseMenu'}),
  },
  methods: {
    ...mapActions(['toggleCollapseMenu']),
  }
}
</script>

<style lang="less" scoped>
.menu {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .logo {
    height: 70px;
    display: flex;
    margin: 3px 25px 0px 18px;

    img {
      width: 35px;
    }
  }

  &-tabs {
    flex: 1;
    overflow: hidden;

    a {
      display: block;
      color: @warm-grey-light;
      text-decoration: none;
      padding: 25px 20px 25px 25px;
      border-radius: 50px 0 0 50px;
      font-size: .95em;
      position: relative;
      line-height: 1;

      @media screen and (max-height: 600px) {
        padding: 15px 20px 15px 25px;
      }

      @media screen and (min-height: 600px) {
        padding: 20px 20px 20px 25px;
      }

      @media screen and (min-height: 700px) {
        padding: 25px 20px 25px 25px;
      }

      &::after, &::before {
        content: '';
        position: absolute;
        right: 0;
        height: 24px;
        width: 26px;
        background-color: @black-2;
        visibility: hidden;
        pointer-events: none;
      }

      &::before {
        bottom: 100%;
        box-shadow: 5px 5px 0 5px @bg-light;
        border-radius: ~"0% 100% 100% 0% / 0% 0% 100% 100%";
      }

      &::after {
        top: 100%;
        box-shadow: 5px -5px 0 5px @bg-light;
        border-radius: ~"0% 100% 100% 0% / 0% 100% 0% 100% ";
      }

      span {
        display: flex;
        align-items: center;
      }

      &:not(:first-child) span::before {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% - 15px);
        height: .5px;
        left: 0;
        background: fade(@cold-grey, 30%);
      }

      svg {
        fill: currentColor;
        height: 22px;
        width: 22px;
        margin-right: 12px;
      }

      &.router-link-active {
        background-color: @bg-light;
        color: @black-2;

        &::after, &::before {
          visibility: visible;
        }

        span::before, & + a span::before {
          visibility: hidden;
        }
      }
    }
  }


  .collapse {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: @warm-grey-light;
    margin-left: auto;
    margin-bottom: 16px;
    
    svg {
      width: 20px;
      height: 20px;
      transform: rotate(90deg);
      margin-right: 5px;
      z-index: 1;
    }
  }


  &.collapsed .collapse svg {
    transform: rotate(-90deg);
  }
}
</style>
