<template>
  <div :style="{width: chatWidth + 'px'}" class="call-sidebar">
    <div class="chat-link" :class="{copied}" @click="chatLink">
      <Icon name="link"/>
    </div>
    <div class="call-sidebar__resize" @mousedown="onMouseDown"></div>
    <iframe id="iframe" :src="src" :style="{pointerEvents: resizeData.mouseDown ? 'none': null}"></iframe>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Icon from '../ui/Icon';

export default {
  name: 'ChatSidebar',
  props: ['data'],
  components: {Icon},
  data() {
    return {
      copied: false,
      host: process.env.VUE_APP_BOT_HOST,
      src: process.env.VUE_APP_BOT_HOST,
      telegram_id: null,
      chatId: '',
      resizeData: {
        startX: null,
        startWidth: null,
        mouseDown: false
      }
    }
  },
  methods: {
    ...mapActions(['copyText']),
    chatLink() {
      this.copied = true;
      this.copyText(`${this.host}${this.telegram_id ? (`?chat=${this.telegram_id}`) : ''}`);
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    },
    iframeListener(e) {
      if (e.data.chatId) {
        this.telegram_id = e.data.chatId;
        this.$emit('chat', e.data);
      }
    },
    onMouseDown(e) {
      this.resizeData.mouseDown = true;
      this.startX = e.clientX;
      this.startWidth = this.chatWidth
    },
    onMouseMove(e) {
      if (this.resizeData.mouseDown) {
        let newWidth = this.startWidth + (this.startX - e.clientX);
        if (newWidth >= 100) {
          this.$store.dispatch('setChatWidth', newWidth)
        }
      }
    },
    onMouseUp() {
      this.resizeData.mouseDown = false;
      this.startX = null;
      this.startWidth = null
    },
    openChat(args) {
      if (args.src) {
        this.$vueSet(this, 'src', this.host + '/' + '?chat=' + args.chatId);
      } else {
        const chatFrame = document.querySelector('#iframe');
        if (chatFrame) {
          chatFrame.contentWindow.postMessage({id: Number(args.chatId)}, process.env.VUE_APP_BOT_HOST);
        }
      }
      this.$bus.$emit('switchChat', args.chatId)
    }
  },
  computed: {
    ...mapGetters({chatWidth: 'chatWidth'})
  },
  beforeCreate() {
    this.$nextTick(function () {
      window.addEventListener('message', this.iframeListener, false);
    })
  },
  created() {
    this.$bus.$on('openChat', this.openChat);

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.iframeListener, false);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    this.$bus.$off('openChat', this.openChat);
  }
}
</script>

<style lang="less" scoped>
  .call-sidebar {
    width: 400px;
    height: 100%;
    display: flex;

    &__resize {
      min-width: 3px;
      height: 100%;
      background-color: @green;
      opacity: .4;
      cursor: ew-resize;

      &:hover {
        opacity: 1;
      }
    }
  }

  #iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .chat-link {
    width: 25px;
    position: relative;

    svg {
      margin: 5px 5px 0 0;
    }
    &.copied {
      &::after {
        content: 'СКОПИРОВАНО В БУФЕР';
        position: absolute;
        right: 0;
        top: 0;
        background-color: @green;
        color: white;
        padding: .5em;
      }
    }
  }
</style>
