<template>
  <label :class="['text-input', {table, loading, invalid}, labelStyle]">
    <input v-if="!plainText" v-imask="imask" :disabled="disabled" :name="name" :placeholder="placeholder"
           :title="tooltip"
           :type="type"
           :value="value"
           autocomplete="off"
           @accept="input($event)"
           @blur="blur($event)"
           @complete="input($event)" @focus="onfocus" @input="!imask && input($event)"
           :max="max" :class="inputStyle"
    >
    <span v-if="plainText" @click="onclick">{{ displayValue }}</span>
    <button v-if="$listeners.reset && value" class="text-input__reset" @click="$emit('reset')">
      <Icon name="x-bold"/>
    </button>
    <div v-if="copyButtonVisible" data-tooltip="Скопировать в буфер обмена" data-tooltip-small>
      <button class="copy-button" @click="copyToClipboard">
        <Icon name="copy"/>
      </button>
    </div>
  </label>
</template>

<script>
import {IMaskDirective} from 'vue-imask';
import IMask from 'imask';
import Icon from '../Icon';

export default {
  name: 'TextInput',
  components: {Icon},
  props: {
    value: [String, Number],
    placeholder: String,
    name: String,
    type: {
      type: String,
      default: 'text'
    },
    required: Boolean,
    disabled: Boolean,
    loading: Boolean,
    invalid: Boolean,
    table: Boolean,
    mask: [String, Array, Object],
    //prefix: String,
    separator: String,
    tooltip: String,
    max: Number,
    inputStyle: String,
    labelStyle: String,
    isInteger: Boolean,
    withCopy: Boolean
  },
  data() {
    return {
      focus: false,
      copied: false
    }
  },
  methods: {
    onclick() {
      if (!this.disabled) {
        this.focus = true;
      }
    },
    onfocus() {
      this.focus = true;
      this.$emit('focus');
    },
    input(e) {
      if (this.separator && e.target.value.includes(this.separator)) {
        let temp = e.target.value.split(this.separator);
        e.target.value = temp[temp.length - 1];
      }

      if (this.max && this.type === 'number' && e.target.value > this.max){
        e.target.value = this.max;
      }

      if (this.isInteger){
        e.target.value = e.target.value ? e.target.value.replace(/\D/g,'') : '';
      }

      else if (this.max && e.target.value.length > this.max){
        e.target.value = e.target.value.substr(0, this.max);
      }

      let val = e.target.value;

      if (this.imask) {
        this.$emit(e.type, IMask.createMask({...this.imask, lazy: true}).resolve(val));
        this.$emit('input', IMask.createMask({...this.imask, lazy: true}).resolve(val))
      } else {
        this.$emit('input', val)
      }
    },
    blur(e) {
      this.focus = false;
      let val = e.target.value;
      if (this.separator && val.includes(this.separator)) {
        let temp = val.split(this.separator);
        val = temp[temp.length - 1];
      }
      if (this.imask) {
        this.$emit('blur', IMask.createMask({...this.imask, lazy: true}).resolve(val))
      } else {
        this.$emit('blur', val)
      }
    },
    async copyToClipboard() {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.value);
        this.copied = true;
        setTimeout(() => {
          this.resetCopiedState()
        }, 1000);
      }
    },
    resetCopiedState() {
      this.copied = false;
    }
  },
  computed: {
    plainText() {
      return this.table && this.value && this.value.toString().trim() && !this.focus && !this.invalid
    },
    imask() {
      if (!this.mask) return null;
      else if (this.mask === 'phone') return {mask: '+{7} (000) 000-00-00', lazy: false};
      else if (this.mask === 'positive') return {mask: Number, signed: false};
      return this.mask
    },
    displayValue() {
      if (this.mask && this.value) return IMask.createMask(this.imask).resolve(this.value);
      return this.value
    },
    copyButtonVisible(){
      return this.withCopy && this.value && this.plainText && !this.copied;
    }
  },
  directives: {
    imask: IMaskDirective
  },
  mounted(){
    if (this.inputStyle === 'no-min-width-button') {
      let temp = document.getElementsByClassName(this.inputStyle);
      for (let i = 0; i < temp.length; i++){
        temp[i].style.minWidth = '0';

      }
    }
  }
}
</script>

<style lang="less" scoped>

label {
  display: inline-block;
  flex: 1;
  position: relative;

  input {
    width: 100%;
    margin-right: 10px;
    background-color: @bg-input;
    border: none;
    padding: 10px 18px;
    font-family: inherit;
    font-size: .88em;
    border-radius: 22px;
    color: @cold-grey-dark;
    transition-duration: .2s;

    &::placeholder {
      color: @cold-grey;
      transition-duration: .2s;
    }

    &:not(:disabled) {

      &:hover {
        background-color: fade(@cold-grey, 15%);

      }

      &:focus {
        background-color: fade(@green-light, 20%);
        color: @cold-grey-dark;

        &::placeholder {
          color: fade(@cold-grey-dark, 60%);
        }
      }
    }

    &:disabled {
      background-color: fade(@cold-grey, 30%);
      cursor: no-drop;
      opacity: .5;
      color: fade(@cold-grey, 30%);
    }

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.invalid input {
    color: @red;
  }

  &.table {
    width: 100%;
    font-size: @middle-font;
    margin-left: 5px;
    input {
      background: none;
      border-radius: 0;
      border: none;
      border-bottom: 1px dotted fade(@cold-grey, 65%);
      padding: 6px 8px;
      min-width: 10rem;

      &:hover {
        background-color: fade(@cold-grey-light, 15%);
        border-bottom-style: solid;
      }

      &:focus {
        background-color: fade(@green-light, 20%);
        border-bottom: 1px solid @green;
      }
    }

    span {
      color: @cold-grey-dark;
      width: 100%;
      display: block;
      cursor: text;
    }

    &.invalid input {
      border-color: @red;
    }
  }

  &.loading {
    input {
      padding-right: 30px;
    }

    &::after {
      content: '';
      position: absolute;
      border: 2px solid #67c1bb;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border-top-color: transparent;
      animation: spin infinite linear 1.75s;

      @keyframes spin {
        to {
          transform: translateY(-50%) rotate(360deg);
        }
      }
    }
  }

  .text-input__reset {
    position: absolute;
    background: none;
    border: none;
    background: @cold-grey;
    width: 16px;
    height: 16px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;

    svg {
      width: 8px;
      height: 8px;
      display: block;
      color: @cold-grey-light;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.text-input.short {
    white-space: nowrap;
    max-width: 200px;
    
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 25px);
    }
  }

  &.text-input:has(.copy-button){
    display: flex;
    align-items: center;
  }

  &.text-input .copy-button {
    display: none;
    background: none;
    border: none;
    background: @bg-lighter;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 0px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.09);
    svg {
      width: 10px;
      height: 10px;
      display: block;
      color: @green;
      margin: auto;
    }
  }

  &.text-input:hover .copy-button {
    display: block;
  }
}
</style>
