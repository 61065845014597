<template>
  <CallCard :active="data.active">
    <template #number>{{ data.number }}</template>
    <template #title>Как вас зовут?</template>
    <template #inputs>
      <div>
        <TextInput id="CallerName_full_name" v-model="result.full_name" name="full_name" placeholder="ФИО"/>
        <PhoneInput :value="result.primary_phone_number" @blur="addPhone"/>
        <Button :disabled="(!result.full_name) || similar.state ==='loading'" :nosubmit="false" :type="similar.state==='loading'?'loading':'primary'"
                square @click="getSimilar">
          <Icon name="search"/>
        </Button>
      </div>
      <div v-if="phoneInvalid" class="not-found">
        Введите корректный номер телефона, чтобы он был сохранен.
      </div>
      <div v-if="(similar.ready || similar.state === 'loading') && similar.results.length">
        <InlineTable :columns="tableHeader" :data="similar.results" :loading="similar.state === 'loading'" select-mode="radio"
                     type="clients" @select="selectSimilar"/>
      </div>
      <div v-if="(similar.ready || similar.state === 'loading')">
        <div v-if="!similar.results.length" class="not-found">
          <Icon name="search"/>
          {{ errorMessage }}
        </div>
        <Button :disabled="!result.full_name || similar.state === 'loading'" square @click="sendData">
          Ок
        </Button>
      </div>

    </template>
    <template #outputs>
      <span>{{ displayedValue }}</span>
    </template>
  </CallCard>
</template>

<script>
import CallCard from '../CallCard';
import TextInput from '../../ui/input/TextInput';
import Button from '../../ui/input/Button';
import Icon from '../../ui/Icon';
import InlineTable from '../../ui/InlineTable';
import PhoneInput from '../../ui/input/PhoneInput';
import {mapActions} from 'vuex';

export default {
  name: 'CallerName',
  components: {Button, TextInput, CallCard, Icon, InlineTable, PhoneInput},
  props: ['data'],
  data() {
    return {
      error: false,
      errorMessage: '',
      similar: {
        state: 'idle',
        results: []
      },
      tableHeader: {
        name: 'ФИО',
        birth_date: 'Г.р.',
        phone: 'Телефон',
        special_marks: 'Особенности'
      },
      result: {
        full_name: null,
        id: null,
        phone_number_json: [],
        primary_phone_number: ''
      },
      phoneInvalid: false,
    }
  },
  mounted() {
    this.result.full_name = this.data?.sessionData?.caller?.full_name;
    this.result.primary_phone_number = this.data?.sessionData?.caller?.phone_number;
    if (this.result.primary_phone_number && this.result.primary_phone_number.length > 0) {
      this.addPhone(this.result.primary_phone_number)
    }
  },
  methods: {
    ...mapActions(['getClientByFullName']),
    addPhone(phone) {
      if (phone !== 'INVALID' && phone !== '' && this.result.phone_number_json.filter(a => a.phone_number === phone).length === 0) {
        this.result.phone_number_json.push({phone_number: phone});
      }
      this.phoneInvalid = phone === 'INVALID';
    },
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    async getSimilar() {
      this.similar.state = 'loading';
      this.similar.ready = false;
      this.similar.results = [];

      this.error = false;
      this.similar.results = await this.getClientByFullName(this.result.full_name).catch(e => {
        // TODO: вынести обработку ошибок тоже в store или даже в axios.interceptors
        if (e.response.status === 400 || e.response.status === 800) {
          this.$emit('errors', e.response.data.detail_json);
        }
        this.errorMessage = 'Ошибка: повторите попытку';
        this.error = true;
        this.similar.state = 'not-found';
        this.similar.ready = true;
      });

      for (let each in this.similar.results) {
        this.similar.results[each].key = this.randomId();
      }

      if (this.similar.results.length !== 0) {
        this.similar.state = 'found';
        this.similar.ready = true;
      } else {
        this.similar.state = 'not-found';
        this.similar.ready = true;
        this.errorMessage = 'Упс, человека с таким именем в базе нет';
      }

    },
    selectSimilar(key) {
      if (key) {
        let client = this.similar.results.find(x => x.key === key);
        this.result.similarId = client.id;
      } else {
        this.result.similarId = null;
      }
    },
    sendData() {

      this.$emit('nextStep', {result: this.fullResult, next: 'wantSpecificPerson'});
    },

  },
  computed: {
    displayedValue() {
      let displayName = this.fullResult.client?.full_name;

      if (!displayName) {

        if (this.data.stepResults.callerName?.full_name) {
          displayName = this.data.stepResults.callerName?.full_name
        }
      }

      return displayName
    },
    fullResult() {
      let result = {};

      if (this.result.similarId) {
        result = this.similar.results.find(x => x.id === this.result.similarId)
      } else {
        result = {full_name: this.result.full_name};
      }

      if (result.phone_number_json) {
        result.phone_number_json = [...result.phone_number_json, ...this.result.phone_number_json];
      } else {
        result.phone_number_json = this.result.phone_number_json;
      }

      return result
    }
  }
}
</script>

<style lang="less">
.not-found {
  color: @red;
  font-size: .85em;

  svg {
    height: 1.3em;
    margin-right: .4em;
    vertical-align: bottom;
  }
}
</style>
