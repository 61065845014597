import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import InlineSvg from 'vue-inline-svg';
import axios from 'axios';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import * as VueDeepSet from 'vue-deepset';
import SipPlugin from './plugins/sip';

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import PortalVue from 'portal-vue'
import Notifications from 'vue-notification'
import vSelect from 'vue-select'
import dayjs from 'dayjs';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const refreshAuthLogic = (failedRequest) =>
  store.dispatch('refreshDefault').then(() => {
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + store.getters.access;
    return Promise.resolve();
  });

axios.defaults.withCredentials = true;

Vue.use(VueDeepSet);
Vue.use(SipPlugin, store);
Vue.use(PortalVue);
Vue.use(Notifications);
Vue.component('v-select', vSelect);

const clientOptionsFunctionDefaultIntegrations = {
  dsn: process.env.VUE_APP_DSN_SENTRY,
  integrations: [new Integrations.Vue({
    Vue,
    attachProps: true,
    environment: process.env.NODE_ENV
  })]
};
const client = new Sentry.BrowserClient(
  clientOptionsFunctionDefaultIntegrations
);
Sentry.init(clientOptionsFunctionDefaultIntegrations);
Sentry.getCurrentHub().bindClient(client);

Vue.component('inline-svg', InlineSvg);
Vue.component('VueSlider', VueSlider);

Vue.config.productionTip = false;

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

require('dayjs/locale/ru');
dayjs.locale('ru');

axios.defaults.baseURL = process.env.VUE_APP_API_HOST + '/api/';

Vue.prototype.$bus = new Vue();

Vue.prototype.$api_base = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
});

Vue.prototype.$api = axios.create({
  baseURL: process.env.VUE_APP_API_HOST + '/api/v1/',
});

Vue.prototype.$api_sms = axios.create({
  baseURL: process.env.VUE_APP_SMS_HOST
});

Vue.prototype.$api_telegram = axios.create({
  baseURL: process.env.VUE_APP_TELEGRAM_AUTH_ENDPOINT
});

const Apis = [
  Vue.prototype.$api_base,
  Vue.prototype.$api,
  Vue.prototype.$api_sms
];

createAuthRefreshInterceptor(Vue.prototype.$api_telegram, refreshAuthLogic);

Apis.forEach(api => {
  createAuthRefreshInterceptor(api, refreshAuthLogic);
  api.interceptors.request.use((request) => {
    request.headers['Authorization'] = 'Bearer ' + store.getters.access;
    return request;
  });
  api.interceptors.response.use(
    response => response,
    error => {
      return errorHandler(error);
    }
  );
})

store.dispatch('autoRefresh', 0);
store.dispatch('autoHealthCheck', 0);

const errorHandler = error => {
  if (error.response?.status === 401 && error.response?.data?.code === 'token_not_valid' && store.getters.isAuthenticated){
    store.dispatch('autoRefresh', 0);
  }
  else if ((error.response?.status === 403 || error.response?.status === 401) && store.getters.isAuthenticated){
    let message = 'Обратитесь в техподдержку! У вас не хватает прав:\n'
      + (error.response?.data?.detail + '\n')
      + ('Error ' + error.response?.status)
      + (error.response?.statusText ? ':\n' + error.response?.statusText + '\n': '\n')
      + (error.response?.request?.responseURL);

    alert(message);
  }
  return Promise.reject(error)
};

if (!store.getters.isAuthenticated && location.pathname !== '/login') {
  router.push('/login');
}

Notification.requestPermission();

new Vue({
  router,
  store,
  beforeMount() {
    window.addEventListener('load', this.onLoad);
    window.addEventListener('beforeunload', this.onUnload);
  },
  beforeDestroy() {
    window.removeEventListener('load', this.onLoad);
    window.removeEventListener('beforeunload', this.onUnload);
  },
  methods: {
    onLoad() {
    },
    onUnload() {
      store.commit('call/resetSession');
      store.commit('call/resetRegistredState')
    }
  },

  render: h => h(App)
}).$mount('#app');
