<template>
  <div :class="['tag', {button, outline, short}]" :data-tooltip="short ? text : null" data-tooltip-small>
    <span class="tag-text">{{ text }}</span>
    <button v-if="button" class="tag-remove" @click.stop.prevent="$emit('remove', text)">
      <Icon name="close"/>
    </button>
  </div>
</template>

<script>
import Icon from './Icon';

export default {
  name: 'Tag',
  components: {Icon},
  props: {
    text: [String, Number],
    button: Boolean,
    outline: Boolean,
    short: Boolean
  }
}
</script>

<style lang="less" scoped>
.tag {
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  font-size: .8em;
  color: @cold-grey-dark;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: #FAFAFA;
  margin-bottom: 5px;

  &.button {
    padding: 4px 8px 4px 10px;
  }

  &.outline {
    box-shadow: 0 0 0 1px @cold-grey-light inset;
  }

  &.short {
    span {
      display: block;
      max-width: 5em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EEEFF0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    padding: 0;
    margin-left: 6px;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }

    svg {
      width: 4px;
      height: 4px;
      color: @cold-grey-dark;

      path {
        stroke-width: 2.5;
      }
    }
  }
}
</style>
