<template>
  <CallCard :active="data.active">
    <template #number>{{ data.number }}</template>
    <template #title>Кто вы задержанному?</template>
    <template #inputs>
      <div>
        <TextInput v-model="result.whoToDetained" placeholder="Родственник/друг/другой вариант"
                   value="whoToDetained"
        />
        <Button :disabled="!result.whoToDetained" square @click="sendData">
          Ок
        </Button>
      </div>
    </template>
    <template #outputs>
      <span> {{ displayedValue }} </span>
    </template>
  </CallCard>
</template>

<script>
import CallCard from '../CallCard';
import TextInput from '../../ui/input/TextInput';
import Button from '../../ui/input/Button';

export default {
  name: 'WantSpecificPerson',
  components: {TextInput, CallCard, Button},
  props: ['data'],
  data() {
    return {
      result: {
        whoToDetained: null
      }
    }
  },
  methods: {
    sendData() {
      this.$emit('nextStep', {result: this.result.whoToDetained, next: 'detainedName:notSelf'});
    }
  },
  computed: {
    displayedValue() {
      return this.result.whoToDetained ? this.result.whoToDetained : this.data.stepResults.whoToDetained
    }
  }
}
</script>
