<template>
  <div>
    <div v-if="!locked" class="search">
      Поиск по
      <span class="choice" :class="{ 'active' : searchType === 'detentions' }">
        <input id="detentions" v-model="searchType" type="radio" value="detentions" @change="change">
        <label for="detentions">ФИО</label>
      </span>
      <span class="choice" :class="{ 'active' : searchType === 'police' }">
        <input id="police" v-model="searchType" type="radio" value="police" @change="change">
        <label for="police">отделу</label>
      </span>
      <span class="choice" :class="{ 'active' : searchType === 'autozaki' }">
        <input id="autozaki" v-model="searchType" type="radio" value="autozaki" @change="change">
        <label for="autozaki">Номеру автозака</label>
      </span>
    </div>

    <CardTransition v-if="searchType === 'autozaki' || $route.params.id" :key="autozakSearchId"
                    :session_type="searchType"
                    :states="states"
                    initialPath="autozakId" @locked="locked = $event"></CardTransition>
    <CardTransition v-else-if="searchType === 'police'" :key="policeSearchId" :session_type="searchType"
                      :states="states"
                      initialPath="policeSearch" @locked="locked = $event"></CardTransition>
    <CardTransition v-else-if="searchType === 'detentions'" :key="detentionSearchId" :session_type="searchType"
                    :states="states"
                    initialPath="detentionSearch" @locked="locked = $event"></CardTransition>

  </div>

</template>

<script>
import CardTransition from '../components/call/Card-Transition';
import {mapActions} from 'vuex';

const states = {
  'autozakId': {
    component: 'AutozakId'
  },
  'detentionSearch': {
    component: 'DetentionSearch'
  },
  'policeSearch': {
    component: 'PoliceSearch'
  },
  'clientPack': {
    component: 'ClientPack'
  },
};

export default {
  name: 'Autozaki',
  components: {
    CardTransition,
  },

  data() {
    return {
      states,
      searchType: 'detentions',
      locked: false,
      autozakSearchId: 0,
      detentionSearchId: 1,
      policeSearchId: 2
    }
  },
  created(){
    this.$bus.$on('newSession', this.newSession);
  },
  beforeDestroy() {
    this.$bus.$off('newSession', this.newSession);
  },
  methods: {
    ...mapActions(['setTitle']),
    isActive(type) {
      return type === this.searchType
    },
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    change() {
      switch (this.searchType) {
      case 'autozaki' :
        this.autozakSearchId = this.randomId();
        break;
      case 'detentions' :
        this.detentionSearchId = this.randomId();
        break;
        case 'police' :
          this.policeSearchId = this.randomId();
          break;
      }

    },
    newSession(){
      if (this.$route.name.includes('Autozaki2')) {
        this.$router.push({name: 'Autozaki'});
      }
      this.setTitle({text: 'Поиск'});
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  padding: 1rem 0 0 3rem;
  color: @cold-grey;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-weight: 500;
  font-size: .85em;
  position: sticky;
  top: 0;
  background-image: linear-gradient(to bottom, white 65%, transparent);
  z-index: 99;

  & > .choice {
  margin-left: 5px;
  display: flex;
  align-items: center;

    & > input {
      margin-right: 8px;
    }
  }

  &__selected{
    color: @green;
  }

  input {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    outline: none;
    border: 2px solid @middle-grey;
    min-width: 18px;
    margin: 4px;
  }

  input:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 2px auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: @green;
  }

  input[type="radio"]:checked {
    border-color:@green;
  }
}

.active {
  color: @green;
}
</style>
