<template>
  <Card :active="data.active">
    <template #number>{{ data.number }}</template>
    <template #title>Введите идентификатор автозака</template>
    <template #inputs>
      <div>
        <TextInput v-model="id" placeholder="Id Автозака" is-integer />
        <Button :disabled="!id" square @click="sendData">Ок</Button>
      </div>
      <div v-if="data.noAutozak" class="not-found">
        <Icon name="search"/>
        Упс, автозака с таким идентификатором не существует
      </div>
    </template>
    <template #outputs>
      <span> Автозак № {{ id }} </span>
    </template>
  </Card>
</template>

<script>
import Card from '../ui/Card'
import TextInput from '../ui/input/TextInput';
import Button from '../ui/input/Button';
import Icon from '../ui/Icon';

export default {
  name: 'AutozakId',
  components: {Card, TextInput, Button, Icon},
  props: ['data'],
  data() {
    return {
      id: null
    }
  },
  methods: {
    sendData() {
      this.$emit('nextStep', {next: 'clientPack', result: { id: this.id }});
    }
  }
}
</script>
