<template>
  <label class="textarea">
    <textarea
      :maxlength="maxlength"
      :placeholder="placeholder"
      :rows="rows" :value="value"
      @input="input"
      @blur="blur($event)"
    >
    </textarea>
  </label>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    value: String,
    placeholder: String,
    fitContent: Boolean,
    rows: {
      type: Number,
      default: 4
    },
    maxlength: {
      type: [Number, String],
      default: 268
    }
  },
  methods: {
    input(ev) {
      this.$emit('input', ev.target.value);
      if (this.fitContent) {
        ev.target.style.height = 'auto';
        ev.target.style.height = ev.target.scrollHeight + 'px'
      }
    },

    blur(e) {
      let val = e.target.value;
      this.$emit('blur', val);
    }
  }
}
</script>

<style lang="less" scoped>
.textarea {
  display: block;
  flex: 1;
  height: 100%;

  textarea {
    display: block;
    width: 100%;
    height: 100%;
    margin-right: 10px;
    background-color: @bg-input;
    border: none;
    padding: 14px 18px;
    font-family: inherit;
    font-size: .85em;
    line-height: 1.3;
    border-radius: 22px;
    color: @cold-grey-dark;
    transition: background-color .2s, color .2s;
    resize: none;
    overflow: hidden;

    &::placeholder {
      color: @cold-grey;
      transition-duration: .2s;
    }

    &:not(:disabled) {
      &:hover {
        background-color: fade(@cold-grey, 15%);
      }

      &:focus {
        background-color: fade(@green-light, 20%);
        color: @cold-grey-dark;

        &::placeholder {
          color: fade(@cold-grey-dark, 60%);
        }
      }
    }

    &:disabled {
      background-color: fade(@cold-grey, 30%);
      opacity: .5;
    }
  }
}
</style>
