<template>
  <label class="radio">
    <input :checked="value===modelValue" :disabled="disabled" :name="name" type="radio"
           @change="$emit('input', modelValue)"/>
    <div class="radio-label">
      <span class="radio-text"><slot></slot></span>
    </div>
  </label>
</template>

<script>
// при использовании компонента необходимо передать каждой из кнопок model-value — значение, которое будет использоваться для v-model

export default {
  name: 'Radio',
  props: ['value', 'name', 'modelValue', 'disabled']
}
</script>

<style lang="less" scoped>
.radio {
  display: block;
  width: fit-content;

  input[type=radio] {
    display: none;
  }

  &-label {
    position: relative;
    padding-left: 18px;
    display: block;
    min-width: 18px;
    min-height: 18px;
    color: @cold-grey;

    &::before {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0 0 0 1.5px fade(@cold-grey, 65%) inset;
      border-radius: 50%;
      transition-duration: .2s;
    }

    &::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: @cold-grey;
      opacity: 0;
      transition-duration: .2s;
    }

    input:not(:disabled) + & {
      cursor: pointer;
    }

    input:not(:disabled):not(:checked) + & {
      &:hover::before {
        box-shadow: 0 0 0 1.5px @cold-grey inset;
      }
    }

    input:checked + & {
      color: @green;

      &::before {
        box-shadow: 0 0 0 1.5px currentColor inset;
      }

      &::after {
        opacity: 1;
        background-color: currentColor;
      }
    }

    input:disabled + & {
      opacity: .65;
    }
  }

  &-text:not(:empty) {
    margin-left: 8px;
    font-size: .8em;
    line-height: 18px;
    transition-duration: .2s;
  }
}
</style>
