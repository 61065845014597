<template>
    <CallCard :active="data.active">
      <template #number>{{ data.number }}</template>
      <template #title>Помощь</template>
      <template #inputs>
        <div>
            <SelectNew id="lawyer_request_status"
                 v-model="result.lawyerRequestStatus" 
                 :idAsValue="true"
                 :multiple="false"
                 optionsList="lawyer_request_status" 
                 placeholder="Запрос адвоката"
            />
        
            <Button :disabled="!result.lawyerRequestStatus" square @click="sendData">Ок</Button>
        </div>
      </template>
      <template #outputs>
        <span>{{ displayedValue }}</span>
      </template>
    </CallCard>
  </template>

<script>
import CallCard from '../CallCard';
import Button from '../../ui/input/Button';
import SelectNew from '../../ui/input/SelectNew';

export default {
  name: 'LawyerRequest',
  components: {Button, CallCard, SelectNew},
  props: ['data'],
  data() {
    return {
      result: {
        lawyerRequestStatus: null
      }
    }
  },
  methods: {
    sendData() {
      let existingClientPack = this.data.stepResults['detainedName:haveCalled']?.clientPack;
      let existingClient = this.data.stepResults['detainedName:haveCalled']?.client ?? this.data.stepResults['detainedName']?.client;
      this.result.lawyerRequestStatus.clientPack = existingClientPack;
      this.result.lawyerRequestStatus.client = existingClient;

      let eventData = {
        result: this.result.lawyerRequestStatus,
        next: 'clientPack',
        existingClientPack: existingClientPack,
      };

      this.$emit('nextStep', eventData);
    }
  },
  computed: {
    displayedValue() {
      return (this.result.lawyerRequestStatus?.name ?? this.data.stepResults.lawyerRequestStatus?.name);
    }
  }
}

</script>
<style lang="less" scoped>
#lawyer_request_status {
  flex: 2;
}
</style>