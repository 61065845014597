<template>
  <Modal class="audio" @close="$emit('close')">
    <template #title>Аудиозапись #{{ id }}</template>
    <template>
      <div class="audio-container">
        <audio :src="link_to_mp3" controls>
        </audio>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../ui/Modal';
import {mapActions} from 'vuex';

export default {
  name: 'AudioModal',
  components: {Modal},
  props: {
    type: String,
    url: String,
    id: {String, Number},
  },
  data() {
    return {
      link_to_mp3: null,
    }
  },
  methods: {
    ...mapActions(['getCallAudio']),
    async getAudio(){
      if (this.type === 'call' || this.type === 'sessions') {
        let t = this;
        await this.getCallAudio(this.id).then(
          link => {
            t.link_to_mp3 = link;
          }
        ).catch(e => {
          throw new Error(e)
        });
      }
    }
  },
  mounted() {
    this.getAudio();
  }
}
</script>

<style lang="less" scoped>
.moves /deep/ .modal {
  height: fit-content;
}

.table, .right {
  margin: 10px 0;
}

.button {
  margin: 0 10px;
}

.subtitle {
  color: @cold-grey;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 0.75em;
  margin-bottom: 10px;
  margin-top: 10px;
}

.audio {

  &-container {
    display: flex;
    justify-content: center;
  }
}
</style>
