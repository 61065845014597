const actions = {
  // eslint-disable-next-line no-unused-vars
  async patchByUrl(ctx, payload){
    return await this._vm.$api.patch(`/case-court-sessions/${payload.id}/`, payload);
  },

};

export default {
  actions,
};
