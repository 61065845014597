<template>
  <button :class="[type, {square, small}]" :data-tooltip="tooltip" :disabled="disabled"
          :type="nosubmit?'button':'submit'" @click="$emit('click')">
    <span><slot></slot></span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    disabled: Boolean,
    type: {
      type: String,
      default: 'primary'
    },
    nosubmit: Boolean,
    square: Boolean,
    small: Boolean,
    tooltip: String
  }
}
</script>

<style lang="less" scoped>
button {
  height: 40px;
  padding: 5px 20px;
  text-align: center;
  line-height: 1;
  border-radius: 13px;
  --color: @green;
  background-color: var(--color);
  border: 2px solid var(--color);
  color: white;
  font-family: inherit;
  font-size: .8em;
  cursor: pointer;

  &.square {
    width: 40px;
    min-width: 40px;
    padding: 0;
  }

  &.secondary {
    background-color: @bg-lighter;
    color: var(--color);
    box-shadow: none;
  }

  &.small {
    height: auto;
    padding: 7px 15px;
    border-radius: 6px;
    font-weight: 500;
    border: none;
    box-shadow: 0 0 0 1.5px var(--color);

    &.primary {
      box-shadow: 0 0 0 1.5px var(--color), 0 0 13px rgba(0, 0, 0, 0.1);
    }
  }

  &.cancel {
    --color: @red;
  }

  &.loading {
    span {
      display: none;
    }

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      border: 2px solid transparent;
      border-top-color: white;
      border-bottom-color: white;
      border-radius: 50%;
      margin: auto;
      display: block;
      animation: spin 1s infinite linear;

      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:not(.square) /deep/ svg {
    height: 14px;
    stroke-width: 2px;
    width: auto;
    margin-left: 6px;
  }

  &:not(:last-of-type) {
    margin-right: 20px;
  }

  &:disabled {
    --color: @green-light;
    cursor: not-allowed;
  }
}
</style>
