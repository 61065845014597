<template>
  <div class="loader">
    <Icon name="loader"/>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
  name: 'Loader',
  components: {Icon}
}
</script>

<style lang="less" scoped>
.loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(256, 256, 256, 0.5);
}
</style>
