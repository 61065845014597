<template>
  <CallCard :active="data.active">
    <template #number>{{ data.number }}</template>
    <template #title>Город</template>
    <template #inputs>
      <div>
        <SelectNew v-model="result" :ajaxHandle="getApiData" ajaxUrl="city/?name__icontains=%s"
                   class="flex" displayKey="city" name="city" placeholder="Город"/>
        <Button :disabled="!result.city" square @click="sendData">Ок</Button>
      </div>
    </template>
    <template #outputs>
      Автозак в городе {{ displayedValue }}
    </template>
  </CallCard>
</template>


<script>
import CallCard from '../CallCard';
import SelectNew from '../../ui/input/SelectNew';
import Button from '../../ui/input/Button';

export default {
  name: 'City',
  components: {Button, CallCard, SelectNew},
  props: ['data'],
  data() {
    return {
      result: {
        city: null,
        city_id: null
      }
    }
  },
  methods: {
    getApiData(data) {
      return data.map((item) => ({city: item.name, city_id: item.id}));
    },
    sendData() {
      this.$emit('nextStep', {next: 'newClientPack', result: this.result})
    }
  },
  computed: {
    displayedValue() {
      return this.result.city ?? this.data.stepResults.city.city
    }
  }
}
</script>
