<template>
  <CallCard :active="data.active">
    <template #number>{{ data.number }}</template>
    <template #title>Автозак</template>
    <template #inputs>
      <div class="container">
        <div class="container__event">
          <SelectNew
            id="event" 
            v-model="result.event" 
            :ajaxHandle="getApiData"
            :ajaxUrl="query"
            :value="1" class="flex"
            displayKey="event" 
            name="event" 
            placeholder="Акция"
          />
        </div>
        <div class="container__clients-count">
          <TextInput 
            id="clients_count_reported" 
            v-model="result.clients_count_reported" 
            mask="positive"
            name="clients_count_reported" 
            placeholder="Количество задержанных" 
            type="number" 
            :max="maxClientPackSize"
          />
          <Button :disabled="(!result.clients_count_reported) || getDataSent" square @click="sendData">Ок</Button>
        </div>
      </div>
    </template>
    <template #outputs>
      Автозак "{{ result.event.event }}": {{ result.clients_count_reported }} человек(а)
    </template>
  </CallCard>
</template>

<script>
import CallCard from '../CallCard';
import TextInput from '../../ui/input/TextInput';
import SelectNew from '../../ui/input/SelectNew';
import Button from '../../ui/input/Button';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'NewClientPack',
  components: {Button, TextInput, CallCard, SelectNew},
  props: ['data'],
  data() {
    return {
      maxClientPackSize: 200,
      result: {
        event: {event: null, event_id: null},
        clients_count_reported: null
      },
      city_id: null,
      query: 'event/?name__contains=%s',
    }
  },
  computed: {
    ...mapGetters({
      getDataSent: 'getDataSent',
      default_event: 'default_event',
      events: 'events',
    }),
  },
  mounted() {
    this.city_id = this.data.stepResults.city.city_id;
    this.query = 'event/?name__icontains=%s';

    if (this.default_event) {
      let event = this.events.find(x => x.id === this.default_event);
      if (event) {
        this.$vueSet(this.result.event, 'event', event.name);
        this.$vueSet(this.result.event, 'event_id', event.id);
      }
    }
  },
  methods: {
    ...mapActions(['setDataSent']),
    getApiData(data) {
      return data.map((item) => ({event: item.name, event_id: item.id}));
    },
    sendData() {
      this.setDataSent(true);
      this.$emit('nextStep', {next: 'lawyerRequest', result: {clientPack: this.result}})
    }
  }
}
</script>
<style lang="less" scoped>

.container {
  display: flex;
  flex-wrap: wrap;

  &__event {
    flex: 1;
    min-width: 190px; 
  }

  &__clients-count {
    flex: 1;
    display: flex;
  }
}


</style>
