const actions = {
  async getByUrl(ctx, url){
    return await this._vm.$api.get(url);
  },
  async getViaApiBaseByUrl(ctx, url){
    return await this._vm.$api_base.get(url);
  },
  async getViaApiSmsByUrl(ctx, url){
    return await this._vm.$api_sms.get(url);
  },

  async getViaApiTelegramByUrl(ctx, url){
    return await await this._vm.$api_telegram.get(url);
  },
  async getByUrlWithId(ctx, payload){
    return await this._vm.$api.get(payload.url + '/' + payload.id);
  },
  async getByUrlWithQuery(ctx, payload) {
    return await this._vm.$api.get(payload.url + '?' + payload.query.join('&&'));
  },
  async patchByUrlWithId(ctx, payload) {
    return await this._vm.$api.patch(payload.url + payload.id + '/', payload.data);
  },
  async postByUrl(ctx, payload){
    return await this._vm.$api.post(payload.url, payload.data);
  },
  async postViaApiSmsByUrl(ctx, payload){
    return await this._vm.$api_sms.post(payload.url, payload.data);
  },
};

export default {
  actions,
};
