const actions = {
  async getDetentionFullByFullName(ctx, full_name) {
    const r = await this._vm.$api.get('/detention-full/?client__full_name_similar=' + full_name);
    return r.data?.results || r.data;
  },
  async getDetentionByPoliceStation(ctx, police_station_id) {
    let r = await this._vm.$api.get('/detention-full/?police_station__id=' + police_station_id);
    return r.data?.results || r.data;
  },
  async getClientByFullName(ctx, full_name){
    let r =  await this._vm.$api.get('/client/?full_name_similar=' + full_name);
    return r.data?.results || r.data
  },
  async getLastDetentionByFullName(ctx, full_name){
    let r = await this._vm.$api.get('/client-last-detention/?full_name_similar='+ full_name);
    return r.data?.results || r.data
  }
};

export default {
  actions,
};
