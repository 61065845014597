<template>
  <div class="clientpack" id="clientpack" ref="clientpack">
    <div :class="{'clientpack-summary': showSummary, 'summaryToggle': !showSummary}" ref="clientpackSummary">
      <div @click="toggleSummary">
        <Icon :class="{'summaryToggle': true, 'rotateIcon': showSummary}" name="chevron"/>
      </div>
      <div class="clientpack-summary-box">
        <div class="clientpack-summary-box__column">
          <div class="clientpack-summary-box__column-field">
            <div class="clientpack-summary-box__column-field-title"><Icon name="location"/> Город</div>
            <div class="clientpack-summary-box__column-field-data">{{ city.name || '' }}</div>
          </div>
          <div class="clientpack-summary-box__column-field">
            <div class="clientpack-summary-box__column-field-title"><Icon name="home"/> Отдел</div>
            <div class="clientpack-summary-box__column-field-data">{{ client_pack_police_station.name || '' }}</div>
          </div>
        </div>
        <div class="clientpack-summary-box__column">
          <div class="clientpack-summary-box__column-field">
            <div class="clientpack-summary-box__column-field-title"><Icon name="users"/> Задержано</div>
            <div class="clientpack-summary-box__column-field-data">{{ clients_count_auto || 0 }}</div>
          </div>
          <div class="clientpack-summary-box__column-field">
            <div class="clientpack-summary-box__column-field-title attention"><Icon name="shield-excl"/> Жесть</div>
            <div class="clientpack-summary-box__column-field-data attention">{{ data.max_severity || '—' }}</div>
          </div>
        </div>
        <div class="clientpack-summary-box__column">
          <div  class="clientpack-summary-box__column-field">
            <div class="clientpack-summary-box__column-field-title"><Icon name="status"/> Статус</div>
            <div class="clientpack-summary-box__column-field-data uppercase">{{ client_pack_status }}</div>
          </div>
          <div  class="clientpack-summary-box__column-field">
            <div class="clientpack-summary-box__column-field-title"><Icon name="lawyer"/> Адвокат</div>
            <div class="clientpack-summary-box__column-field-data uppercase">{{ client_pack_lawyer_request_status }}</div>
          </div>
        </div>
        <div class="clientpack-summary-box__column">
          <div class="clientpack-summary-box__column-field">
            <div class="clientpack-summary-box__column-field-title"><Icon name="event"/> Акция</div>
            <div class="clientpack-summary-box__column-field-data">{{ event.event }}</div>
          </div>
          <div class="clientpack-summary-box__column-field">
            <div class="clientpack-summary-box__column-field-title"><Icon name="clock"/> Местное время</div>
            <div class="clientpack-summary-box__column-field-data">{{ localTime }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="clientpack-main">
      <div class="clientpack-table">
        <div class="clientpack-tabs" ref="clientpackTabs">
          <div class="clientpack-body" ref="clientpackBody">

            <div class="table-controls" ref="tableControls">
              <div class="clientpack-actions">
                <div class="clientpack-actions-buttons">
                  <Button @click="downloadData" small :type=buttonType>
                    {{ copied ? 'СКОПИРОВАНО В БУФЕР' :'Выгрузить данные'  }}
                  </Button>
                  <Button @click="addNewClient" small :disabled="getDataSent">
                    Новый человек
                    <Icon name="person-add"/>
                  </Button>
                </div>
              </div>
              <div class="clientpack-tabs">
                <div :class="['clientpack-tab', {active: activeTab === key}]" v-for="(column, key) in tabs"
                     @click="activeTab = key; changedTab = true; enterData();" :key="key">
                  <span>{{ column.title }}</span>
                </div>
              </div>

              <div class="clientpack-filters sticky" v-if="selectedRows.length > 0">
                <Button @click="moveUsClientPack" type="secondary" small>
                  Пересадили
                  <Icon name="avtozak"/>
                </Button>
                <Button @click="deleteClients" type="secondary" small>
                  Удалить из автозака
                  <Icon name="close"/>
                </Button>
              </div>
            </div>
            <Table class="table-wrapper" ref="tableWrapper" :data="data.detentions" :columns="tabs[activeTab].columns"
                   :selectedRows="selectedRows" :timezone="timezone" @change="changeData" @blur="enterData"
                   :errors="errors_list" @select="selectRow"
                   :client_id="client_id"
                   @showPhones="showPhones" @commentRemove="commentRemove" @comment="comment" @openOfficeTab="openOfficeTab" />
          </div>
        </div>
      </div>

      <Loader v-if="loading || getDataSent || updating"/>
      <PhonesModal v-if="shownPhones" :data="shownPhones" @close="showPhones(null)" @phone="phone" edit/>
      <MoveModal v-if="shownMoveModal" :data="moveIds" :currentClientPackId="this.client_pack_id"
                 :clients="moveIdsClients" @close="showMoveModal" @errors="errors" edit/>
    </div>
  </div>
</template>

<script>
import Table from './ClientsTable';
import Icon from '../ui/Icon';
import Button from '../ui/input/Button';
import Loader from '../ui/Loader';
import * as dayjs from 'dayjs';
import throttle from 'lodash/throttle'
import {detailedDiff} from 'deep-object-diff';
import PhonesModal from './PhonesModal';
import MoveModal from './MoveModal';
import {mapGetters, mapActions} from 'vuex';

dayjs.extend(require('dayjs/plugin/utc'));

export default {

  name: 'ClientPack',
  props: ['stepResults', 'resultData', 'telegram', 'errors_list', 'session_type', 'client_id'],
  components: {PhonesModal, MoveModal, Button, Table, Icon, Loader},
  data() {
    return {
      copied: false,
      showSummary: true,
      last_errors: null,
      connection: null,
      host: process.env.VUE_APP_WSS_HOST + '/ws/client-pack-full/',
      bot_host: process.env.VUE_APP_BOT_HOST,
      tab_statuses: {
        10: 'delivery',
        20: 'delivery',
        30: 'delivery',
        40: 'office',
        50: 'office',
        60: 'office',
        70: 'office',
        80: 'office',
        90: 'court',
        100: 'court',
        110: 'court',
        120: 'court',
        130: 'office',
        140: 'help',
      },
      status_priority: {
        10: 12,
        20: 11,
        30: 9,
        40: 10,
        50: 7,
        60: 8,
        70: 6,
        80: 5,
        90: 13,
        100: 14,
        110: 4,
        120: 3,
        130: 2,
        140: 1,
        200: 15,
      },
      tabs: {
        personalData: {
          title: 'Личные данные',
          columns: {
            'client_json.birth_date': {
              title: 'Д.р.', //+
              type: 'datetime',
              beforeToday: true
            },
            'special_marks': {
              title: 'Особенности', //+
              type: 'tags',
              optionsList: 'special_mark',
              bulkEdit: true,
            },
            'client_json.telegram_id': {
              title: 'Ссылка на TG',
              type: 'text',
              prefix: 'bot',
              separator: '?chat=',
              max: 32,
              labelStyle: 'short',
              withCopy: true
            },
            'status': {
              title: 'Статус задержанного', //+
              type: 'select',
              optionsList: 'detention_status',
              bulkEdit: true,
            },
            'city': {
              title: 'Город',
              type: 'selectAjax',
              url: 'city',
              query: '/?name__icontains=%s',
              bulkEdit: true,
              lazyOptions: true
            },
            'event': {
              title: 'Акция',
              type: 'selectAjax',
              url: 'event',
              query: '/?name__icontains=%s',
              bulkEdit: true
            },
          }
        },
        help: {
          title: 'Помощь',
          columns: {
            'status': {
              title: 'Статус задержанного',
              type: 'select',
              optionsList: 'detention_status',
              bulkEdit: true,
            },
            'lawyer_request_status': {
              title: 'Запрос адвоката',
              type: 'select',
              bulkEdit: true,
              optionsList: 'lawyer_request_status',
            },
            'lawyer': {
              title: 'Адвокат',
              type: 'selectAjax',
              url: 'lawyer',
              displayKey: 'full_name',
              query: '/?full_name_similar=%s',
              bulkEdit: true,
            },
            'lawyer_json.organization': {
              title: 'Организация',
              type: 'selectAjax',
              readonly: true,
              url: 'organization',
            },
            'lawyer_status': {
              title: 'Статус адвоката',
              type: 'select',
              optionsList: 'lawyer_status',
              bulkEdit: true,
            },
            'lawyer_json.phone_number_json.0.phone_number': {
              title: 'Телефон юриста',
              type: 'text',
              readonly: true
            },
            'help_type': {
              title: 'Другая помощь',
              type: 'tags',
              optionsList: 'help_type_request',
              bulkEdit: true,
              bulkEditMultiAdd: true
            },
            'help_status': {
              title: 'Статус другой помощи',
              type: 'select',
              optionsList: 'help_status',
              bulkEdit: true,
            },
          }
        },
        delivery: {
          title: 'Логистика',
          columns: {
            'status': {
              title: 'Статус задержанного', //+
              type: 'select',
              optionsList: 'detention_status',
              bulkEdit: true,
            },
            'police_station': {
              title: 'Отдел полиции', //+
              type: 'selectAjax',
              url: 'police-station',
              query: '/?name__icontains=%s',
              displayKey: 'name',
              bulkEdit: true,
              extendedView: true,
              extendedOptionTitle: 'short_name',
              extendedOptionSubTitle: 'full_address',
            },
            'detention_time': {
              title: 'Дата и время задержания', //+
              type: 'datetime',
              format: 'datetime',
              beforeToday: true,
              bulkEdit: true,
            },
            'transit_start_time': {
              title: 'Время, когда поехал автозак', //+
              type: 'datetime',
              format: 'datetime',
              bulkEdit: true,
            },
            'transit_finish_time': {
              title: 'Время, когда приехал автозак', //+
              type: 'datetime',
              format: 'datetime',
              bulkEdit: true,
            },
          
            'dept_entrance_time': {
              title: 'Время доставления', //+
              type: 'datetime',
              format: 'datetime',
              bulkEdit: true,
            },
          }
        },
        office: {
          title: 'В отделе',
          columns: {
            'status': {
              title: 'Статус задержанного', //+
              type: 'select',
              optionsList: 'detention_status',
              bulkEdit: true,
            },
            'police_station': {
              title: 'Отдел полиции', //+
              type: 'selectAjax',
              url: 'police-station',
              query: '/?name__icontains=%s',
              bulkEdit: true,
              extendedView: true,
              extendedOptionTitle: 'short_name',
              extendedOptionSubTitle: 'full_address',
            },
            'lawyer': {
              title: 'Адвокат',
              type: 'selectAjax',
              url: 'lawyer',
              displayKey: 'full_name',
              query: '/?full_name_similar=%s',
              bulkEdit: true,
            },
            'lawyer_status': {
              title: 'Статус адвоката',
              type: 'select',
              optionsList: 'lawyer_status',
              bulkEdit: true,
            },
            'release_time': {
              title: 'Время освобождения', //+
              type: 'datetime',
              format: 'datetime',
              bulkEdit: true,
            },
            'articles': {
              title: 'Статья',
              type: 'tags',
              optionsList: 'article',
              bulkEdit: true,
              bulkEditMultiAdd: true,
              lazyOptions: true
            },
            'overnight_stay_police_station': {
              title: 'Отдел на ночь', //+
              eng_title: 'overnight_stay_police_station',
              type: 'selectAjax',
              url: 'police-station',
              query: '/?name__icontains=%s',
              bulkEdit: true,
              extendedView: true,
              extendedOptionTitle: 'short_name',
              extendedOptionSubTitle: 'full_address',
            },
          }
        },
        
        court: {
          title: 'Суд',
          columns: {
            'status': {
              title: 'Статус задержанного', //+
              type: 'select',
              optionsList: 'detention_status',
              bulkEdit: true,
            },
            'articles': {
              title: 'Статья',
              type: 'tags',
              optionsList: 'article',
              bulkEdit: true,
              bulkEditMultiAdd: true,
              lazyOptions: true
            },
            'court-cases': {
              title: 'Результаты суда',
              type: 'linkList',
              url: '/court/'
            },

          }
        },
        
        forAnalytics: {
          title: 'Для аналитика',
          columns: {
            'client_json.birth_date': {
              title: 'Д.р.', //+
              type: 'datetime',
              beforeToday: true
            },
            'special_marks': {
              title: 'Особенности', //+
              type: 'tags',
              optionsList: 'special_mark',
              bulkEdit: true,
            },
            'client_json.telegram_id': {
              title: 'Ссылка на TG',
              type: 'text',
              prefix: 'bot',
              separator: '?chat=',
              max: 32,
              labelStyle: 'short',
              withCopy: true
            },
            'status': {
              title: 'Статус задержанного', //+
              type: 'select',
              optionsList: 'detention_status',
              bulkEdit: true,
            },
            'city': {
              title: 'Город',
              type: 'selectAjax',
              url: 'city',
              query: '/?name__icontains=%s',
              bulkEdit: true
            },
            'event': {
              title: 'Акция',
              type: 'selectAjax',
              url: 'event',
              query: '/?name__icontains=%s',
              bulkEdit: true
            },
            'police_station': {
              title: 'Отдел полиции', //+
              type: 'selectAjax',
              url: 'police-station',
              query: '/?name__icontains=%s',
              bulkEdit: true,
              extendedView: true,
              extendedOptionTitle: 'short_name',
              extendedOptionSubTitle: 'full_address',
            },
            'lawyer': {
              title: 'Адвокат',
              type: 'selectAjax',
              url: 'lawyer',
              displayKey: 'full_name',
              query: '/?full_name_similar=%s',
              bulkEdit: true,
            },
            'lawyer_status': {
              title: 'Статус адвоката',
              type: 'select',
              optionsList: 'lawyer_status',
              bulkEdit: true,
            },
            'articles': {
              title: 'Статья',
              type: 'tags',
              optionsList: 'article',
              bulkEdit: true,
              bulkEditMultiAdd: true
            },
            'overnight_stay_police_station': {
              title: 'Отдел на ночь', //+
              eng_title: 'overnight_stay_police_station',
              type: 'selectAjax',
              url: 'police-station',
              query: '/?name__icontains=%s',
              bulkEdit: true,
              extendedView: true,
              extendedOptionTitle: 'short_name',
              extendedOptionSubTitle: 'full_address',
            },
            'lawyer_request_status': {
              title: 'Запрос адвоката',
              type: 'select',
              bulkEdit: true,
              optionsList: 'lawyer_request_status',
            },
            'lawyer_json.organization': {
              title: 'Организация',
              type: 'selectAjax',
              readonly: true,
              url: 'organization',
            },
            'lawyer_json.phone_number_json.0.phone_number': {
              title: 'Телефон юриста',
              type: 'text',
              readonly: true
            },
            'help_type': {
              title: 'Другая помощь',
              type: 'tags',
              optionsList: 'help_type_request',
              bulkEdit: true,
              bulkEditMultiAdd: true
            },
            'help_status': {
              title: 'Статус другой помощи',
              type: 'select',
              optionsList: 'help_status',
              bulkEdit: true,
            },
          }
        },
      },
      selectedRows: [],
      data: this.resultData,
      original_data: this.resultData,
      city: {
        name: null,
        city_id: null,
      },
      event: {
        event: null,
        event_id: null,
      },
      clients_count_auto: null,
      last_detention: null,
      client_pack_status_num: null,
      client_pack_status: null,
      client_pack_police_station_id: null,
      client_pack_police_station: {
        name: ''
      },
      client_pack_lawyer_request_status: null,
      client_pack_time: null,
      client_pack_id: null,
      activeTab: 'personalData',
      max_severity: null,
      delayedFn: null,
      shownPhones: null,
      moveIds: [],
      moveIdsClients: [],
      shownMoveModal: null,
      updating: null,
      changedTab: false,
      last_error_phone_number: [],
      dataSentForUpdate: {},
      localTime: null,
      timezone: null,
      loading: false,
      cpResizeObserver: new ResizeObserver(throttle(this.handleResize, 200)),
      breakpoints: {
        tiny: el => el.width <= 550,
        small: el => el.width > 550 && el.width <= 600,
        medium: el => el.width > 600 && el.width <= 700,
        large: el => el.width > 700 && el.width <= 800,
        extralarge: el => el.width > 800,
      } 
    }
  },
  methods: {
    ...mapActions(['setDataSent', 'setTitle',
      'setClientPackIdStore', 'getStation', 'getEvent', 'patchDetentionMove', 'postByUrl', 'postApiClientPack', 'copyText']),
    detentionIndex(i) {
      return this.data.detentions.findIndex(
        function (item) {
          return item.id === i || item.tempId === i
        });
    },
    setSizes() {
      this.setClientBodyHeight();
      window.addEventListener('resize', throttle(this.setClientBodyHeight, 200));
      this.setScrollTop();
    },
    toggleSummary() {
      this.showSummary = !this.showSummary;

      setTimeout(() => {
        this.setSizes();
      }, 250);
    },
    setClientBodyHeight() {
      // делаем фиксированную высоту для таблицы чтобы сохранять горизонтальный скролл
      const bodyHeight = this.$refs.clientpack.offsetHeight - (this.$refs.clientpackSummary.clientHeight + 30);
      this.$refs.clientpackBody.style.height = bodyHeight + 'px';
    },
    handleResize(el){
      const contentRectangle = el[0].contentRect
      for (const breakpoint in this.breakpoints) {
        if (this.breakpoints[breakpoint](contentRectangle)) {
          el[0].target.classList.add(breakpoint)
        } else {
          el[0].target.classList.remove(breakpoint)
        }
      }
    },
    setScrollTop() {
      const topHeight = this.$refs.tableControls.offsetHeight;
      let elements = document.getElementsByClassName('table-sticky');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.top = topHeight + 'px';
      }
    },
    async downloadData() {
      let result = {};

      result.overall = {};
      result.overall.city = this.city.name;
      result.overall.clientpack_id = this.client_pack_id;
      result.overall.detention_time = new Date(this.data.detentions[0].detention_time);
      result.overall.event = this.event.event;
      result.overall.client_count = this.clients_count_auto;
      result.overall.police_station_id = this.client_pack_police_station_id;
      result.overall.status = this.client_pack_status;
      result.overall.severity = this.data.max_severity;

      if (this.client_pack_police_station) {
        result.overall.police_station = `${result.overall.city} | ${this.client_pack_police_station?.name} | ${this.client_pack_police_station?.full_address}`;
      }

      let resulting_str =
`${result.overall.city || 'город не указан'}
Автозак: ${result.overall.clientpack_id}
Дата задержания: ${result.overall.detention_time.toLocaleString()}
Акция: ${result.overall.event || 'не указана'}
Количество задержанных: ${result.overall.client_count}
Статус: ${result.overall.status}
ОВД: ${(result.overall.police_station || 'не указано')}
Уровень жести: ${(result.overall.severity || '-')}`;

      result.details = {};
      for (let each of this.data.detentions) {
        let police_station = each.overnight_stay_police_station ? each.overnight_stay_police_station : each.police_station;
        if (!result.details[police_station]) {
          result.details[police_station] = {name: '', clients: []};
          if (police_station) {
            let police_station_result = await this.getStation(police_station);
            result.details[police_station].name = result.overall.city + ' | ' +
                police_station_result.name + ' | ' + police_station_result.full_address;
            result.details[police_station].short_name = police_station_result.name;
          } else {
            result.details[police_station].name = 'не указано';
          }
        }
        result.details[police_station].clients.push(each);
      }
      for (let each_ovd of Object.keys(result.details)) {
        result.details[each_ovd].ovd_strings = result.details[each_ovd].clients.map(
          x => (x.client_json.full_name || '-')
                + ' | д.р.: '
                + (x.client_json.birth_date ?
                  new Date(Date.parse(x.client_json.birth_date)).toLocaleDateString('ru-RU', {timeZone: 'Europe/Moscow'})
                  : '-')
                + (x.client_json.phone_number_json?.length ?
                  '  | тел: ' + x.client_json.phone_number_json.map(p => p.phone_number).join(', ') :
                  (x.client_json.telegram_id ? ' | телеграм: ' + x.client_json.telegram_id : ' | тел: -')
                )
                + ' | статус: ' + (this.statuses[x.status] || (x.status ? x.status : '-'))
                + ' | статьи: ' + (x.case_json?.length ? x.case_json.map(p => this.article[p.article]?.name).join(', ') : '-')
                + (x.overnight_stay_police_station ? ' | ОВД на ночь: ' + result.details[each_ovd].short_name : '')
                + ' | адвокат: ' + (x.lawyer_json?.full_name || '-')
                + (x.is_underage ? ' | несовершеннолетний' : '')
                + (x.comment_json && x.comment_json.length > 0 && x.comment_json.filter(s => s.severity > 1).length > 0
                  ? ' | ' + x.comment_json.filter(x => x.severity > 1)
                    .map(a => 'Жесть ' + (a.severity ?? '0') + (a.violence.length > 0 ? ', ' +
                        a.violence.map(b => this.violence.find(s => s.id === b)?.name)
                          .join(', ') : '')
                    ).join('; ') : '')
                + (x.special_marks?.length ? ' | ' +
                    x.special_marks.map(p => (this.special_marks[p]?.name)).join(' | ') : '')

                + (x.help_type && x.help_type.length > 0 ?
                  ' | ' + x.help_type.map(p => (this.help_type_request[p]?.name)).join(', ')
                    + (x.help_status ? ' (' + this.help_status.find(s => s.id === x.help_status)?.name + ')' : '')
                  : '')
                  + (x.city !== this.city.city_id ? '| ' + this.getCity.find(c => c.id === x.city)?.name : '')
                + '.'

        );

        resulting_str += '\r\n' + '\r\n' + 'ОВД: ' + result.details[each_ovd].name;
        resulting_str += '\r\n' + '\r\n' + result.details[each_ovd].ovd_strings.join('\r\n');
      }


      if (navigator.clipboard) {
        await navigator.clipboard.writeText(resulting_str);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 1000);
      } else {
        this.copied = true;
        this.copyText(resulting_str);
        setTimeout(() => {
          this.copied = false;
        }, 1000);
      }

    },
    errors(e) {
      this.$emit('errors', e);
    },
    clientPackErrors(e) {
      if (e) {
        this.last_errors = e;
      } else this.last_errors = null;

      if (this.last_errors) {
        for (let each of Object.keys(this.last_errors)) {
          let eachArray = each.split('.');
          if (eachArray.includes('phone_number_json')) {

            let detentionId = this.dataSentForUpdate.detentions[eachArray[1]].id || this.dataSentForUpdate.detentions[eachArray[1]].tempId;

            let detentionIndex = this.detentionIndex(detentionId);
            if (detentionIndex !== -1) {
              this.last_error_phone_number.push(this.data.detentions[detentionIndex][eachArray[2]][eachArray[3]][eachArray[4]][eachArray[5]]);
              this.$vueSet(this.data.detentions[detentionIndex][eachArray[2]], 'phone_error', true);
            }
          }
        }
      }

    },
    phone(type, itemId, phone) {

      let detentionIndex = this.detentionIndex(itemId);

      if (detentionIndex !== -1) {
        switch (type) {
        case 'other_contacts': {
          this.data.detentions[detentionIndex].client_json.other_contacts = phone;
          break;
        }
        case 'remove': {

          if (this.data.detentions[detentionIndex].client_json.phone_number) {
            let jsonPhone = this.data.detentions[detentionIndex].client_json.phone_number_json?.find((item) => item.id === phone.id || item.phone_number === phone.phone_number);
            let arrayIndex = this.data.detentions[detentionIndex].client_json.phone_number.findIndex((i) => i === phone.id || i === jsonPhone?.id);

            if (arrayIndex >= 0) {
              this.data.detentions[detentionIndex].client_json.phone_number.splice(arrayIndex, 1);
            }
          }

          if (this.data.detentions[detentionIndex].client_json.phone_number_json) {
            let jsonIndex = this.data.detentions[detentionIndex].client_json.phone_number_json.findIndex((item) => item.id === phone.id || item.phone_number === phone.phone_number);

            if (jsonIndex >= 0) {
              this.data.detentions[detentionIndex].client_json.phone_number_json.splice(jsonIndex, 1);
            }
          }

          break;
        }
        case 'add': {

          if (!this.data.detentions[detentionIndex].client_json.phone_number_json) {
            this.data.detentions[detentionIndex].client_json.phone_number_json = []
          }
          this.data.detentions[detentionIndex].client_json.phone_number_json.push(phone);
          break;
        }
        case 'edit': {
          if (this.data.detentions[detentionIndex].client_json.phone_number_json) {
            let newItem = this.data.detentions[detentionIndex].client_json.phone_number_json.find((item) => item.id === phone.id || item.phone_number === phone.phone_number);
            if (newItem) {
              newItem.comment = phone.comment;
            }
          }
          break;
        }
        default:
          break;
        }
        let item = this.data.detentions[detentionIndex];

        this.shownPhones = {
          id: item?.id || item?.tempId,
          list: item?.client_json?.phone_number_json ?? [],
          other_contacts: item?.client_json?.other_contacts,
          error: item?.client_json?.phone_error,
          last_error_phone_number: this.last_error_phone_number,
        };

        this.enterData();
      }


    },

    commentRemove(id, item) {
      for (let i of item) {
        let detentionIndex = this.detentionIndex(i);
        if (detentionIndex !== -1) {
          let index = this.data.detentions[detentionIndex].comment_json
            .findIndex(item => item.id === id || item.tempId === id);
          this.data.detentions[detentionIndex].comment.splice(index, 1);
        }
      }
      this.enterData();
    },
    comment(comment) {

      if (comment.detention_id === 'all') {
        comment.detention_id = [];
        for (let i = 0; i < this.data.detentions.length; i++) {
          comment.detention_id.push(this.data.detentions[i].id);
        }
      }
      this.postByUrl({url: 'comment/bulk-detentions/', data: comment}).then(r => {
        if (r.status === 201 && r.data.id) {
          for (let i of comment.detention_id) {
            let detentionIndex = this.detentionIndex(i);

            if (detentionIndex !== -1) {
              this.data.detentions[detentionIndex].comment_json.unshift(r.data);
              this.data.detentions[detentionIndex].comment.unshift(r.data.id);
              this.$vueSet(this.data.detentions[detentionIndex], 'comment_json',
                this.data.detentions[detentionIndex].comment_json);
            }
          }
        }
      }).catch(e => {
        this.disabled = false;
        throw new Error('Error during COMMENT', e);
      })
    },
    selectRow(id) {
      if (this.selectedRows.includes(id)) {
        this.selectedRows = this.selectedRows.filter(function (value) {
          return value !== id
        });
      } else {
        this.selectedRows.push(id);
      }
      setTimeout(() => {
        this.setSizes();
      }, 250);
    },
    deleteClients() {
      let selectedDetentions = [];
      for (let i of this.selectedRows) {
        let detentionIndex = this.detentionIndex(i);
        if (detentionIndex !== -1) {
          let detention = this.data.detentions[detentionIndex];
          selectedDetentions.push(detention);
        }
      }

      let selectedNames = selectedDetentions.map(a => (a.client_json.full_name || (a.client_json.id + ' (без имени)')));

      if (confirm('Вы уверены, что хотите удалить? \r\nУдаляются клиенты:\r\n' + selectedNames.join('\r\n'))) {
        let deleteIdsPatch = [];
        for (let i of this.selectedRows) {
          let detentionIndex = this.detentionIndex(i);
          if (detentionIndex !== -1) {
            let detention = this.data.detentions[detentionIndex];
            deleteIdsPatch.push(detention.id);
            this.data.detentions.splice(detentionIndex, 1);
            this.$vueSet(this, 'clients_count_auto', this.data.detentions.length);
            this.$vueSet(this.data.client_pack, 'clients_count_auto', this.clients_count_auto);
            this.selectRow(i);
          }
        }
        if (deleteIdsPatch.length > 0) {
          let dataForSend = {'id': deleteIdsPatch, 'client_pack': null};
          this.patchDetentionMove(dataForSend)
            .catch(e => {
              if (e.response.status === 400 || e.response.status === 800) {
                this.$emit('errors', e.response.data.detail_json);
              }
              throw new Error('Error during DELETE', e);
            });
        }
      }
    },
    moveUsClientPack() {

      let moveIdsPatch = [];
      for (let i of this.selectedRows) {
        let detentionIndex = this.detentionIndex(i);
        if (detentionIndex !== -1) {
          let detention = this.data.detentions[detentionIndex];
          moveIdsPatch.push(detention.id);
          this.moveIdsClients.push(detention.client_json.id);
        }
      }
      if (moveIdsPatch.length > 0) {
        this.moveIds = moveIdsPatch;
        this.showMoveModal('start');
      }
    },
    setActiveTab() {
      if (!this.changedTab) {
        if (this.client_pack_status_num > 0) {
          if (this.statuses && this.statuses[this.client_pack_status_num]) {
            this.$vueSet(this, 'client_pack_status', this.statuses[this.client_pack_status_num]);
          }

          let choice = this.tab_statuses[this.client_pack_status_num];
          if (choice) {
            this.$vueSet(this, 'activeTab', choice);
          } else {
            this.$vueSet(this, 'activeTab', 'personalData');
          }
        } else {
          this.$vueSet(this, 'activeTab', 'personalData');
        }

        // setTimeout(() => (document.getElementsByClassName('clientpack-tab active')[0].scrollIntoViewIfNeeded()), 1000);
      }
    },
    changeData(detention = null, key = null, val = null) {

      let detentionIndex = this.detentionIndex(detention.id || detention.tempId);

      if (detentionIndex !== -1) {
        if (key === 'articles') {
          let changed_val = val.map(a => ({article: a}));
          this.$vueSet(this.data.detentions[detentionIndex], 'case_json', changed_val);
        } else {
          this.$vueSet(this.data.detentions[detentionIndex], key, val);
        }
      }
    },
    deepEqualArray(array, y) {
      function deepEqual(x, y) {
        return (x && y && typeof x === 'object' && typeof y === 'object') ?
          (Object.keys(x).length === Object.keys(y).length) &&
            Object.keys(x).reduce(function (isEqual, key) {
              return isEqual && deepEqual(x[key], y[key]);
            }, true) : (x === y);
      }

      let answer = false;
      for (let each of array) {
        if (deepEqual(each, y)) answer = true;
      }

      return answer;
    },
    getChangedData() {
      let filteredData = this.data;
      let difference_in_data = detailedDiff(this.original_data
        , filteredData);

      let updated_data = {client_pack: {id: this.data.client_pack.id}, detentions: []};

      //added
      //detentions - просто добавляем в список
      if (Object.keys(difference_in_data.added).includes('detentions')) {
        for (let i of Object.keys(difference_in_data.added['detentions'])) {
          let temp_detention = this.data.detentions[i];

          for (let j of Object.keys(difference_in_data.added['detentions'][i])) {

            if ((temp_detention[j] instanceof Array) && !(difference_in_data.added['detentions'][i][j] instanceof Array)) {
              difference_in_data.added['detentions'][i][j] = Object.values(temp_detention[j]);
            }

            if (j === 'client_json') {

              for (let k of Object.keys(difference_in_data.added['detentions'][i][j])) {
                if ((temp_detention[j][k] instanceof Array) && !(difference_in_data.added['detentions'][i][j][k] instanceof Array)) {
                  difference_in_data.added['detentions'][i][j][k] = Object.values(temp_detention[j][k]);
                }

                if (k === 'phone_number_json') {
                  difference_in_data.added['detentions'][i][j].phone_number = Object.values(temp_detention[j].phone_number || []);
                }
              }
            }
          }

          difference_in_data.added['detentions'][i].id = temp_detention.id;

          if (!Object.keys(difference_in_data.added['detentions'][i]).includes('client_json')) {
            difference_in_data.added['detentions'][i].client_json = {};
          }

          difference_in_data.added['detentions'][i].client_json.id = temp_detention.client_json?.id;

          if (!this.deepEqualArray(updated_data.detentions, difference_in_data.added['detentions'][i])) {
            updated_data.detentions.push(difference_in_data.added['detentions'][i]);
          }
        }
      }

      //другие поля не должны так работать - но на всякий случай - добавляем в список
      for (let i of Object.keys(difference_in_data.added)) {
        if (i !== 'detentions') {
          updated_data[i] = Object.assign({}, updated_data[i], difference_in_data.added[i])
        }
      }

      //deleted - удаление в другом месте или не предусмотрено
      //detentions - удаляются отдельно через move.
      if (Object.keys(difference_in_data.deleted).includes('detentions')) {
        for (let i of Object.keys(difference_in_data.deleted['detentions'])) {
          if (this.data.detentions[i]) {
            let temp_detention = this.data.detentions[i];

            for (let j of Object.keys(difference_in_data.deleted['detentions'][i])) {

              if ((temp_detention[j] instanceof Array) && !(difference_in_data.deleted['detentions'][i][j] instanceof Array)) {
                difference_in_data.deleted['detentions'][i][j] = Object.values(temp_detention[j]);
              }

              if (j === 'client_json') {
                for (let k of Object.keys(difference_in_data.deleted['detentions'][i][j])) {
                  if ((temp_detention[j][k] instanceof Array) && !(difference_in_data.deleted['detentions'][i][j][k] instanceof Array)) {
                    difference_in_data.deleted['detentions'][i][j][k] = Object.values(temp_detention[j][k]);
                  }

                  if (k === 'phone_number_json') {
                    difference_in_data.deleted['detentions'][i][j].phone_number = Object.values(temp_detention[j].phone_number || []);
                  }
                }
              }
            }

            difference_in_data.deleted['detentions'][i].id = temp_detention.id;

            if (!Object.keys(difference_in_data.deleted['detentions'][i]).includes('client_json')) {
              difference_in_data.deleted['detentions'][i].client_json = {};
            }

            difference_in_data.deleted['detentions'][i].client_json.id = temp_detention.client_json?.id;

            if (!this.deepEqualArray(updated_data.detentions, difference_in_data.deleted['detentions'][i])) {
              updated_data.detentions.push(difference_in_data.deleted['detentions'][i]);
            }
          }
        }
      }
      //updated
      //detentions - вставляем ид (неизменившееся)
      if (Object.keys(difference_in_data.updated).includes('detentions')) {
        for (let i of Object.keys(difference_in_data.updated['detentions'])) {
          let temp_detention = this.data.detentions[i];

          for (let j of Object.keys(difference_in_data.updated['detentions'][i])) {

            if ((temp_detention[j] instanceof Array) && !(difference_in_data.updated['detentions'][i][j] instanceof Array)) {
              difference_in_data.updated['detentions'][i][j] = Object.values(temp_detention[j]);
            }

            if (j === 'client_json') {

              for (let k of Object.keys(difference_in_data.updated['detentions'][i][j])) {
                if ((temp_detention[j][k] instanceof Array) && !(difference_in_data.updated['detentions'][i][j][k] instanceof Array)) {
                  difference_in_data.updated['detentions'][i][j][k] = Object.values(temp_detention[j][k]);
                }

                if (k === 'phone_number_json') {
                  difference_in_data.updated['detentions'][i][j].phone_number = Object.values(temp_detention[j].phone_number || []);
                }
              }
            }
          }

          difference_in_data.updated['detentions'][i].id = temp_detention.id;

          if (!Object.keys(difference_in_data.updated['detentions'][i]).includes('client_json')) {
            difference_in_data.updated['detentions'][i].client_json = {};
          }

          difference_in_data.updated['detentions'][i].client_json.id = temp_detention.client_json.id;

          if (!this.deepEqualArray(updated_data.detentions, difference_in_data.updated['detentions'][i])) {
            updated_data.detentions.push(difference_in_data.updated['detentions'][i]);
          }
        }
      }
      //Другие поля - копируем
      for (let i of Object.keys(difference_in_data.updated)) {
        if (i !== 'detentions') {
          updated_data[i] = Object.assign({}, updated_data[i], difference_in_data.updated[i])
        }
      }

      return updated_data;

    },
    enterData(timeout = 0) {
      if (timeout > 0) {
        clearTimeout(this.delayedFn);
        this.delayedFn = setTimeout(() => this.enterData(), timeout);
        this.setDataSent(false);
        return;
      } else if (this.updating) {
        this.setDataSent(false);
        setTimeout(() => (this.updating = false), 30000);
        return;
      } else {
        this.updating = true;
        clearTimeout(this.delayedFn);
      }
      this.loading = true;

      let dataForSend = this.getChangedData();

      if ((dataForSend.detentions.length === 0 && Object.keys(dataForSend.client_pack).length === 1)) {
        this.updating = false;
        this.loading = false;
        this.setDataSent(false);
        return;
      }

      this.dataSentForUpdate = dataForSend;
      this.connection.send(JSON.stringify(dataForSend));
    },
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    addNewClient() {
      let maxClients = 200;
      if (this.clients_count_auto >= maxClients) {
        alert('В автозаке может быть не более ' + maxClients + ' человек!');
        return;
      }
      this.setDataSent(true);

      this.data.detentions.push({
        tempId: this.randomId(),
        event: this.event?.event_id, city: this.city?.city_id,
        client_json: {is_unknown: true, phone_number_json: []}
      });

      this.enterData();
    },
    getClientPackStatusNum() {
      let prioritizedStatuses = Object.fromEntries(
        this.data.detentions.map((x) => [x.status, this.status_priority[x.status]])
      );
      let bestStatus = Object.keys(prioritizedStatuses).reduce((key, v) =>
        prioritizedStatuses[v] < prioritizedStatuses[key] ? v : key
      );
      return bestStatus;
    },
    getPoliceStationId() {
      return this.data.detentions.map((x) => x.police_station).find((x) => x);
    },
    getLawyerRequestStatusId() {
      return this.data.detentions
        .map((x) => x.lawyer_request_status)
        .find((x) => x);
    },
    getEventId() {
      return this.data.detentions.map((x) => x.event).find((x) => x);
    },
    async updateStatus() {
      //Берем город и акцию первого человека из пака у которого есть данные
      this.$vueSet(this, 'client_pack_id', this.data.client_pack?.id);
      window.document.title = 'Автозак ' + this.client_pack_id;
      this.$vueSet(this, 'clients_count_auto', this.data.client_pack?.clients_count_auto || this.data.detentions.length);

      this.$vueSet(this, 'client_pack_police_station_id', this.getPoliceStationId());

      if (this.client_pack_police_station_id) {
        let police_station = await this.getStation(this.client_pack_police_station_id);
        this.$vueSet(this, 'client_pack_police_station', police_station);
      } else {
        this.$vueSet(this, 'client_pack_police_station', {name: null});
      }

      const lawyer_request_status_id = this.getLawyerRequestStatusId();
      if (lawyer_request_status_id) {
        const lawyer_request_status = this.$store.state.dictionaries[
          'lawyer_request_status'
        ].find((x) => x.id === lawyer_request_status_id);
        this.$vueSet(this, 'client_pack_lawyer_request_status', lawyer_request_status?.name);
      } else {
        this.$vueSet(this, 'client_pack_lawyer_request_status', '');
      }

      this.$vueSet(this, 'client_pack_status_num', this.getClientPackStatusNum());

      if (this.statuses && this.statuses[this.client_pack_status_num]) {
        this.$vueSet(this, 'client_pack_status', this.statuses[this.client_pack_status_num]);
      } else {
        this.$vueSet(this, 'client_pack_status', '');
      }

      this.$vueSet(this, 'timezone', this.data?.city?.time_zone);

      this.$vueSet(this, 'client_pack_time', dayjs.utc(this.data.detentions[0]?.dept_entrance_time)?.add(this.timezone, 'hours').format('DD.MM.YYYY HH:mm'));

      this.$vueSet(this.city, 'city_id', this.data.city.id);
      this.$vueSet(this.city, 'name', this.data.city.name);

      this.$vueSet(this.event, 'event_id', this.getEventId());
      if (this.event.event_id) {
        this.getEvent(this.event.event_id)
          .then((r) => this.$vueSet(this.event, 'event', r.name))
          .catch((e) => {
            if (e.response.status === 400 || e.response.status === 800) {
              this.$emit('errors', e.response.data.detail_json);
            }
            throw new Error('Error during GET EVENT', e);
          })
      } else {
        this.$vueSet(this.event, 'event', null)
      }
    },
    showPhones(item) {
      if (item?.client_json) {

        this.shownPhones = {
          id: item?.id || item?.tempId,
          list: item?.client_json?.phone_number_json ?? [],
          other_contacts: item?.client_json?.other_contacts,
          error: item?.client_json?.phone_error,
          last_error_phone_number: this.last_error_phone_number
        }
      } else {
        this.shownPhones = null;
      }
    },
    showMoveModal(item) {
      if (item === 'start') {
        this.shownMoveModal = true;
      } else if (item) {
        for (let i of this.selectedRows) {
          let detentionIndex = this.detentionIndex(i);
          if (detentionIndex !== -1) {
            this.data.detentions.splice(detentionIndex, 1);
            this.$vueSet(this, 'clients_count_auto', this.data.detentions.length);
            this.$vueSet(this.data.client_pack, 'clients_count_auto', this.clients_count_auto);
            this.selectRow(i);
          }
        }
        this.moveIds = null;
        this.moveIdsClients = [];

        this.shownMoveModal = null;
      } else {
        this.shownMoveModal = null;
      }
    },
    updateData(data) {
      let d = JSON.parse(data);
      if (d.client_pack) {
        this.$vueSet(this, 'data', JSON.parse(data));
        this.$vueSet(this, 'original_data', JSON.parse(data));
        void this.updateStatus();
        this.clientPackErrors(null);

        this.setTitle({text: `Автозак ${d.client_pack.id}`, url: `/avtozaki/${d.client_pack.id}`});

        this.loading = false;
      } else if (d.id && !d.detail_json) {
        this.$vueSet(this, 'original_data', this.data);
      } else if (d.detail_json) {

        let clean = JSON.stringify(this.data);
        clean = clean.replace('\"Invalid Date\"', 'null');

        this.$vueSet(this, 'data', JSON.parse(clean));

        this.errors(d.detail_json);
        this.clientPackErrors(d.detail_json);
        this.loading = false;
      }

      if (this.updating) {
        this.updating = false;
        this.setDataSent(false);
      }

      this.setActiveTab();

    },
    socket() {
      if (this.connection) {
        this.connection.close();
      }

      if (this.data.client_pack.id) {
        this.loading = true;
        this.connection = new WebSocket(this.host + this.data.client_pack.id, this.protocol);
        this.setClientPackIdStore(this.data.client_pack.id);

        this.connection.onerror = function (error) {
          this.$emit('errorConnection', error);
          this.errors(error.detail_json);
          this.clientPackErrors(error.detail_json);
          this.loading = false;
        }.bind(this);

        this.connection.onclose = function (event) {
          // eslint-disable-next-line no-console
          console.log('connectionClosed', event);
          this.loading = false;
        };

        this.connection.onmessage = (event) => {
          this.updateData(event.data);
        };

        this.connection.onopen = function (event) {
          // eslint-disable-next-line no-console
          console.log('Successfully connected to the echo websocket server...', event);
        };
      }
    },
    initClientPack(id) {
      this.$emit('clientPackId', id);
      this.socket();

      if (this.stepResults.existingClientPack && this.stepResults.haveCalled) {
        let dataForSend = {
          'client_pack': {'id': id},
          'detentions': this.resultData.detentions,
          add_count: true
        };
        try {
          this.dataSentForUpdate = dataForSend;
          this.waitConnection(this.connection, ()=>{
            this.connection.send(JSON.stringify(dataForSend));
          })
        } catch (ex) {
          throw new Error(ex);
        }
      }
    },
    openOfficeTab() {
      this.$vueSet(this, 'activeTab', 'office');
    },
    waitConnection(connection, callback){
      if(connection.readyState === 0) {
        setTimeout(() => {
          this.waitConnection(connection, callback);
        }, 1000);
      } else if(connection.readyState === 1) {
        callback();
      }
    }
  },
  computed: {
    ...mapGetters({
      getCity: 'city',
      getDataSent: 'getDataSent',
      access: 'access',
      violence: 'violence',
      detention_status: 'detention_status',
      article: 'articleObj',
      help_status: 'help_status',
      help_type_request: 'help_type_requestObj',
      special_marks: 'special_marksObj'
    }),
    buttonType() {
      return this.copied ? 'secondary' : 'primary'
    },
    statuses() {
      return this.detention_status.reduce((a, x) => ({...a, [x.id]: x.name}), {});
    },
    protocol() {
      return ['Bearer', this.access];
    },
  },
  watch: {
    resultData(val, oldVal) {
      if (val === oldVal)
        return;
      this.data = val;
      this.original_data = val;
      this.initClientPack(val.client_pack.id)
    }
  },
  beforeDestroy() {
    if (this.connection) {
      this.connection.close();
    }
    window.removeEventListener('resize', this.setClientBodyHeight);
  },
  mounted() {
    this.setDataSent(false);
    let client = {};
    let clientPack = {};

    if (this.stepResults) {

      for (let step of Object.values(this.stepResults)) {
        client = {...client, ...step?.client};
        clientPack = {...clientPack, ...step?.clientPack}
      }
    }

    let clientPackId = this.$route.params.id ?? this.data?.client_pack?.id ?? clientPack?.id;

    if (clientPackId) {
      this.initClientPack(clientPackId)
    } else {

      let iniData = this.resultData;

      this.postApiClientPack(iniData).then(r => {

        if (r.status === 200) {
          this.$emit('clientPackId', r.data.id);

          this.socket();
        }
      }).catch(e => {
        if (e.response.status === 400 || e.response.status === 800) {
          this.$emit('errors', e.response.data.detail_json);
        }
        throw new Error('Error during UPDATE DATA INITIAL', e);
      })
    }
    setInterval(() => {
      let temp = dayjs.utc().add(this.data?.city?.time_zone, 'hours');
      this.localTime = temp.format('DD.MM.YYYY, HH:mm');
    }, 1000);
    setTimeout(() => {
      this.setSizes();
    }, 2000)
    setTimeout(() => { 
      this.cpResizeObserver.observe(this.$refs.clientpack) 
    }, 1000)
  }
}
</script>

<style lang="less" scoped src="./clientpack.less">

</style>
