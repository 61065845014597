import { render, staticRenderFns } from "./DetentionComments.vue?vue&type=template&id=ae585814&scoped=true"
import script from "./DetentionComments.vue?vue&type=script&lang=js"
export * from "./DetentionComments.vue?vue&type=script&lang=js"
import style0 from "./DetentionComments.vue?vue&type=style&index=0&id=ae585814&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae585814",
  null
  
)

export default component.exports