import store from '../index';

const CALL_AUDIO_URL = '/call-record/';

const actions = {
  async getCallAudio(_, id){
    let link = null;
    await store.dispatch('getByUrl', CALL_AUDIO_URL + id)
      .then(
        r => {
          link = r.data.record_url;
        }
      ).catch(e => {
        throw new Error(e)
      });
    return link;
  }
};

export default {
  actions,
};
