<template>

  <CardTransition :states="states" initialPath="isDetained" session_type="call"></CardTransition>
</template>

<script>
import CardTransition from '../components/call/Card-Transition';
import {mapGetters, mapState} from 'vuex';

const states = {

  'isDetained': {
    component: 'IsDetained'
  },
  'detainedName': {
    component: 'DetainedName',
    detainedPerson: 'self'
  },
  'haveCalled': {
    component: 'HaveCalled'
  },
  'newClientPack': {
    component: 'NewClientPack'
  },
  'detainedName:haveCalled': {
    component: 'DetainedName',
    detainedPerson: 'previousCaller'
  },
  'callerName': {
    component: 'CallerName'
  },
  'wantSpecificPerson': {
    component: 'WantSpecificPerson'
  },
  'detainedName:notSelf': {
    component: 'DetainedName',
    detainedPerson: 'notSelf'
  },
  'clientPack': {
    component: 'ClientPack'
  },
  'whoToDetained': {
    component: 'WhoToDetained',
  },
  'city': {
    component: 'City'
  },
  'lawyerRequest': {
    component: 'LawyerRequest'
  }
};

export default {
  name: 'Call',
  components: {
    CardTransition,
  },
  data() {
    return {
      states,
    }
  }, computed: {
    ...mapGetters({
      hasCall: 'call/hasCall',

    }),
    ...mapState({
      sipRegistred: state => state.call.registered
    }),
  },
}
</script>
