<template>
  <Modal large @close="closeAction">
    <template #title>Комментарии</template>
    <template>
      <div class="comments">
        <div class="comments-new">
          <Textarea v-model="newComment.comment" :rows="5" fit-content
                    maxlength="1000" placeholder="Новый комментарий (обязательное поле)"/>
          <div class="subtitle">Жесть</div>
          <div class="flex jsb ac">

            <div>
              <SelectNew
                  :idAsValue="true" :multiple="true" :searchable="false"
                  :value="newComment.violence" displayKey="name" optionsList="violence" @input="violenceInput"/>
            </div>
            <div v-if="newComment.violence.length !== 0" class="violence">
              <vue-slider v-model="newComment.severity" :max="3" :min="1"/>
              <span>Выберите уровень жести</span>
            </div>
          </div>
          <div class="flex jsb ac flexRight">
            <Checkbox v-if="forAllVisible" v-model="newComment.forAll" class="marginRight">Для всех</Checkbox>
            <Button :disabled="!newComment.comment.length || disabled"
                    :tooltip="!newComment.comment.length ? 'Введите значение в поле комментарий' : null" @click="submitComment">{{actionText}}
            </Button>
          </div>
        </div>
        <div class="comments-list">
          <div v-for="c in orderedComments" :key="c.id" class="comment">
            <div class="comment-info">
              <span>{{ date(c.created_at).format('DD.MM.YY HH:mm') }}</span>
              <span>
                    <span class="comment-edit" @click="commentEdit(c)">
                      <Icon name="edit"/>
                    </span>
                    <span class="comment-remove" @click="commentRemove(c)">
                      <Icon name="close"/>
                    </span>
                  </span>
            </div>
            <p>{{ c.comment }}</p>
            <p v-if="c.severity"><b>Жесть:</b> {{ c.severity }}</p>
            <ul v-if="c.violence.length > 0">
              <li v-for="z in c.violence" :key="z">{{ getViolenceLabel(z) }}</li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../ui/Modal';
import Textarea from '../ui/input/Textarea';
import Checkbox from '../ui/input/Checkbox';
import Button from '../ui/input/Button';
import dayjs from 'dayjs';
import SelectNew from '../ui/input/SelectNew'
import Icon from '../ui/Icon';
import {mapGetters, mapActions} from 'vuex';
import uniq from 'lodash/uniq'
export default {
  name: 'DetentionComments',
  components: {Button, Checkbox, Textarea, Modal, SelectNew, Icon},
  props: ['detentionId', 'comments'],
  data() {
    return {
      newComment: {
        comment: '',
        forAll: false,
        severity: 1,
        violence: [],
        violence_labels: [],
      },
      //comments: [],
      disabled: false,
      actionText: 'Добавить',
      forAllVisible: true,
    }
  },
  methods: {
    ...mapActions(['patchByUrlWithId']),
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    setNewComment() {
      this.newComment = {
        comment: '',
        forAll: false,
        severity: 1,
        violence: [],
        violence_labels: [],
        tempId: this.randomId(),
      }
    },
    commentEdit(comment) {
      this.actionText = 'Изменить';
      this.forAllVisible = false;
      this.newComment = comment;
    },
    commentRemove(comment) {
      this.$emit('commentRemove', comment.id || comment.tempId, [this.detentionId]);
      let temp = this.comments.filter(x => x.id === comment.id);

      if (temp?.length > 0) {
        let index = this.comments.indexOf(temp[0]);
        //FIXME: вынести мутацию comments в store
        // eslint-disable-next-line vue/no-mutating-props
        this.comments.splice(index, 1);
      }
    },
    closeAction() {
      let result = true;

      if (this.newComment.comment !== '' || this.newComment?.violence?.length > 0) {
        result = confirm('Вы уверены? Данные не сохранятся!')
      }

      if (result) {
        this.$emit('close');
      }
    },
    violenceInput(val) {
      this.newComment.violence = val;
    },
    date(raw_date) {
      return dayjs(raw_date)
    },
    submitComment() {
      this.disabled = true;
      this.forAllVisible = true;
      this.actionText = 'Добавить';
      if (this.newComment.violence.length === 0) {
        this.newComment.severity = null;
      }

      if (this.newComment.id) {
        this.patchByUrlWithId({url: 'comment/', id: this.newComment.id, data: this.newComment}).then(r => {
          if (r.status === 200 && r.data.id) {
            let temp = this.comments.find(x => x.id === r.data.id);

            let index;
            if (temp) {
              index = this.comments.indexOf(temp);
            }

            if (index !== -1) {
              this.$vueSet(this.comments, index, r.data);
            }
            this.setNewComment();
            this.disabled = false;
          }
        }).catch(e => {
          this.disabled = false;
          throw new Error('Error during COMMENT', e);
        })
      } else {
        if (!this.newComment.forAll) {
          this.newComment.detention_id = [this.detentionId];
        } else {
          this.newComment.detention_id = 'all';
        }
        this.$emit('comment', this.newComment);

        this.setNewComment();
        this.disabled = false;
      }
    },
    getViolenceLabel(violence){
      return this.violence.find(y => y.id === violence)?.name;
    }
  },
  computed: {
    ...mapGetters({
      violence: 'violence',
    }),

    orderedComments: function () {
      const sortComments = this.comments.slice().sort((a, b) =>  new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      return uniq(sortComments);
    },
  },
  mounted() {
    this.setNewComment();
  }
}
</script>

<style lang="less" scoped>
.comments {
  white-space: initial;
  overflow-wrap: break-word;

  &-new {
    padding-bottom: 15px;

    > :not(:last-child) {
      margin-bottom: 15px;
    }

    .violence {
      //flex: 1;
      width: 100%;
      padding: 0 20px;
      position: relative;

      span {
        position: absolute;
        margin-top: 2px;
        display: block;
        font-size: .8rem;
        color: @cold-grey;
      }

      /deep/ .vue-slider {
        &-rail {
          background: linear-gradient(to right, #17ca5f, #ff4c29);
        }

        &-process {
          display: none;
        }

        &-dot-handle-focus {
          box-shadow: 0.5px 0.5px 2px 1px rgb(0 0 0 / 32%) !important;
        }

        &-dot-tooltip-inner {
          border-color: @green;
          background-color: @green;
        }
      }
    }
  }

  .comment {
    padding: 10px 0 15px;

    &-info {
      color: @cold-grey;
      font-size: .8rem;

      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;

      span {
        display: flex;
        flex-direction: row;
      }
    }

    p, ul {
      color: @cold-grey-dark;
      font-size: .9rem;
      line-height: 1.4;
    }

    &-remove, &-edit {
      width: 12px;
      display: block;
      color: @cold-grey;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;

      /deep/ svg {
        width: 12px;
      }
    }
  }

  > :not(:last-child) {
    border-bottom: 2px solid @cold-grey-light;
  }
}

.vue-slider-dot-handle-focus {
  box-shadow: 0.5px 0.5px 2px 1px rgb(0 0 0 / 32%) !important;
}

.flexRight {
  justify-content: flex-end !important;

  .marginRight {
    margin-right: 20px;
  }
}

.subtitle {
  color: @cold-grey;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 0.75em;
  margin-bottom: 10px;
  margin-top: 10px;
}

.comments-list {
  overflow-y: scroll;
  height: 250px;
}
</style>
