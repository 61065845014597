<template>
  <CallCard :active="data.active">
    <template #number>{{ data.number }}</template>
    <template #title>Вы задержаны?</template>
    <template #inputs>
      <div>
        <Button square @click="sendData(true)">Да</Button>
        <Button class="cancel" square @click="sendData(false)">Нет</Button>
      </div>
    </template>
    <template #outputs>
      <span>{{ displayedValue }}</span>
    </template>
  </CallCard>
</template>

<script>
import CallCard from '../CallCard';
import Button from '../../ui/input/Button';

export default {
  name: 'IsDetained',
  components: {Button, CallCard},
  props: ['data'],
  data() {
    return {
      result: {
        isDetained: null
      }
    }
  },
  methods: {
    sendData(result) {
      this.result.isDetained = result;
      this.$emit('nextStep', {result: this.result.isDetained, next: result ? 'detainedName' : 'callerName'});
    }
  },
  computed: {
    displayedValue() {
      return (this.result.isDetained || this.data.stepResults.isDetained) ? 'Да' : 'Нет'
    }
  }
}
</script>
