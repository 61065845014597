<template>
  <label
    :class="['checkbox', {'checkbox-warn': this.warn}]"
    :data-tooltip="tooltip"
    :data-tooltip-position="tooltipPosition"
    data-tooltip-small
  >
    <input :checked="val" :disabled="disabled" readonly type="checkbox" @change="change" @click="onClick"/>
    <div v-if="icon" class="checkbox-label-icon">
      <Icon v-if="icon" :name="icon"/>
    </div>
    <div v-else class="checkbox-label">
      <span class="checkbox-text"><slot></slot></span>
    </div>
  </label>
</template>

<script>
import Icon from '../Icon';

export default {
  name: 'Checkbox',
  components: {Icon},
  props: {
    value: Boolean,
    disabled: Boolean,
    checked: {},
    icon: String,
    tooltip:  {
      type: String,
      default: undefined
    },
    tooltipPosition: {
      type: String,
      default: undefined
    },
    lmh: Boolean,
    warn: Boolean,
  },
  methods: {
    change(ev) {
      if (this.checked === undefined) {
        this.$emit('input', ev.target.checked)
      }
    },
    onClick(ev) {
      this.$emit('click', ev)
    }
  },
  computed: {
    val() {
      if (this.checked === undefined) return this.value;
      else return this.checked
    }
  }
}
</script>

<style lang="less" scoped>
.checkbox {
  display: block;
  width: fit-content;

  input[type=checkbox] {
    display: none;
  }

  &-label {
    position: relative;
    padding-left: 18px;
    display: block;
    min-width: 18px;
    min-height: 18px;
    color: @cold-grey;

    &::before {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0 0 0 1.5px #D8D9E4 inset;
      border-radius: 4px;
      transition-duration: .2s;
    }

    &::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      mask-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.986328 4.65154L4.50913 9.73099L8.98633 1.73099' stroke='%2367C1BC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: cover;
      background-color: @cold-grey;
      opacity: 0;
      transition-duration: .2s;
    }

    input:not(:disabled) + & {
      cursor: pointer;
    }

    input:not(:disabled):not(:checked) + & {
      &:hover::before {
        box-shadow: 0 0 0 1.5px @cold-grey inset;
      }
    }

    input:checked + & {
      color: @green;

      &::before {
        box-shadow: 0 0 0 1.5px currentColor inset;
      }

      &::after {
        opacity: 1;
        background-color: currentColor;
      }


    }

    input:disabled + & {
      opacity: .65;
    }
  }

  &-label-icon {
    color: #D8D9E4;
    transition-duration: .2s;

    /deep/ svg {
      display: block;
      width: auto;
      height: 22px;
    }

    /deep/ .on {
      display: none;
    }

    input:not(:disabled):not(:checked) + &:hover {
      color: @cold-grey;
    }

    input:checked + & {
      color: @green;

      /deep/ .on {
        display: block;
      }

      /deep/ .off {
        display: none;
      }
    }

    input:not(:disabled) + & {
      cursor: pointer;
    }

    input:disabled + & {
      opacity: 1;
    }

    &:hover(.tooltip) {
      display: inline;
    }

    .tooltip {
      color: @green;
      font-size: .8em;
      display: none;
      position: absolute;
    }
  }

  &-text:not(:empty) {
    margin-left: 8px;
    font-size: .8em;
    line-height: 18px;
    transition-duration: .2s;
  }

  &[readonly] {
    pointer-events: none;
  }

  &-warn {
    .checkbox-label, input:checked + .checkbox-label {
      color: #D6540B;
    }
  }
}
</style>
