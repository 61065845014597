<template>
  <Modal class="moves" @close="$emit('close', false)">
    <template #title>Пересадить</template>
    <template>
      <div class="search">
        <TextInput v-model="clientPack.full_name" placeholder="ФИО (к кому пересадить)" @focus="clearError" />

        <Button :disabled="!clientPack.full_name || similar.state ==='loading'" :nosubmit="false" :type="similar.state==='loading'?'loading':'primary'" class="button"
                square @click="getSimilar">
          <Icon name="search"/>
        </Button>
      </div>

      <div class="search">
        <TextInput v-model="clientPack.id" placeholder="Номер автозака" type="number" @focus="clearError" @input="onInput" />
        <Button :disabled="!clientPack.id" :type="similar.state==='loading'?'loading':'primary'" class="button" square @click="findData">
          <Icon name="search"/>
        </Button>
      </div>

      <Checkbox v-model="isAllowDiffrentCity" class="checkbox">город или акция отличаются</Checkbox>

      <div v-if="similar.ready && similar.state === 'not-found' && errorMessage" class="table">
        <div class="not-found">
          <Icon name="search"/>
          {{ errorMessage }}
        </div>
      </div>


      <div v-if="(similar.ready || similar.state === 'loading') && (similar.results.length || similar.resultsAutozak.length)"
            class="table">
        <InlineTable
          v-if="similar.results.length"
          :columns="tableHeader"
          :data="similar.results"
          :loading="similar.state === 'loading'"
          select-mode="radio"
          class="move-table-size"
          type="clients" @select="selectSimilar"
        />

        <InlineTable
          v-if="similar.resultsAutozak.length"
          :columns="tableAutozak"
          :data="similar.resultsAutozak"
          :loading="similar.state === 'loading'"
          class="move-table-size"
          type="clients"
        />
      </div>

      <div class="right">
        <Button
          v-if="(similar.ready || similar.state === 'loading') && (similar.results.length || similar.resultsAutozak.length) && !error"
          :disabled="similar.state === 'loading' || !clientPack.id"
          @click="sendData"
        >
          Пересадить в автозак {{ clientPack.id }}
          <Icon name="avtozak"/>
        </Button>
      </div>

      <div class="right">
        <Button :disabled="similar.state === 'loading'" type="secondary"
                @click="newClientPack">
          Новый автозак
        </Button>
        <Button :disabled="similar.state === 'loading'" type="secondary"
                @click="newClientPack">
          Не знаю куда
        </Button>
      </div>

    </template>

  </Modal>
</template>

<script>
import Modal from '../ui/Modal';
import Icon from '../ui/Icon';
import TextInput from '../ui/input/TextInput';
import Button from '../ui/input/Button'
import InlineTable from '../ui/InlineTable'
import Checkbox from '../ui/input/Checkbox';
import {mapActions } from 'vuex';

export default {
  name: 'MoveModal',
  components: {TextInput, Icon, Modal, Button, InlineTable, Checkbox},
  props: {
    data: Array,
    edit: Boolean,
    currentClientPackId: Number,
    clients: Array,
  },
  data() {
    return {
      similar: {
        state: 'idle',
        results: [],
        resultsAutozak: [],
        id: null,
      },
      error: false,
      errorMessage: '',
      tableHeader: {
        clientPack: 'Задержание',
        name: 'ФИО',
        birth_date: 'Г.р.',
        phone: 'Телефон',
        special_marks: 'Особенности'
      },
      tableAutozak: {
        city: 'Город',
        date: 'Дата создания'
      },
      clientPack: {
        full_name: null,
        id: null
      },
      isAllowDiffrentCity: false
    }
  },

  methods: {
    ...mapActions(['getLastDetentionByFullName', 'patchDetentionMove', 'getApiClientPackById']),
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    getSimilar() {
      this.clientPack.id = null;
      this.updateState();

      this.getLastDetentionByFullName(this.clientPack.full_name)
        .then((r) => {
          this.error = false;
          let combined = r;

          let results = [];
          for (let i = 0; i < combined.length; i++) {
            combined[i].client_json.clientPackId = combined[i]?.detention_json?.client_pack;
            if (combined[i]?.detention_json) {
              if (combined[i].detention_json?.client_pack) {
                combined[i].client_json.clientPack = (combined[i]?.detention_json?.city_json?.name ?? '-') + ', ' +
                          new Date(Date.parse(combined[i]?.detention_json?.detention_time)).toLocaleDateString('ru-RU', {timeZone: 'Europe/Moscow'});

              }
              if ((combined[i].client_json.clientPackId !== this.currentClientPackId) &&
                        !(this.clients.includes(combined[i].client_json.id))) {
                results.push(combined[i].client_json);
              }
            }
          }

          this.similar.results = results;

          for (let each in this.similar.results) {
            this.similar.results[each].key = this.randomId();
          }

          if (this.similar.results.length !== 0) {
            this.similar.state = 'found';
            this.similar.ready = true;
          } else {
            this.similar.state = 'not-found';
            this.similar.ready = true;
            this.errorMessage = 'Задержание не найдено';
          }
        }
        ).catch(error => {
          this.errorMessage = 'Ошибка: повторите попытку';
          this.error = true;
          this.similar.state = 'not-found';
          this.similar.ready = true;
          throw new Error(error);
        });

    },
    newClientPack() {
      const forceParam = this.isAllowDiffrentCity ? {'force': 1} : {};
      let dataForSend = {'id': this.data, ...forceParam};
      this.patchDetentionMove(dataForSend).then(r => {

        if (r.status === 200) {
          this.$emit('close', true);
          let routeData = this.$router.resolve({name: 'Autozaki2', params: {id: r.data.client_pack_id}});
          window.open(routeData.href, '_blank');
        }
      }).catch(e => {
        this.catchErrorMove(e);
      });

    },
    selectSimilar(key) {
      if (key) {
        let client = this.similar.results.find(x => x.key === key);
        this.similar.id = client?.id;
        this.clientPack.id = client?.clientPackId;
      } else {
        this.similar.Id = null;
        this.clientPack.id = null;
      }
    },
    sendData() {
      if (this.data.length > 0 && this.clientPack.id) {
        const forceParam = this.isAllowDiffrentCity ? {'force': 1} : {};
        let dataForSend = {'id': this.data, 'client_pack': this.clientPack.id, ...forceParam};
        this.patchDetentionMove(dataForSend).then(r => {
          if (r.status === 200) {
            this.$emit('close', true);
            let routeData = this.$router.resolve({name: 'Autozaki2', params: {id: r.data.client_pack_id}});
            window.open(routeData.href, '_blank');

          }
        }).catch(e => {
          this.catchErrorMove(e);
        });
      }
    },

    catchErrorMove(e) {
      if (e.message.includes('400')) {
        this.$notify(({
          type: 'error',
          title: 'Обратите внимание, что город или акция отличаются',
          text: 'Если необходимо, выберите пункт "город или акция отличаются"'
        }));
      } else {
        this.$notify(({
          type: 'error',
          title: 'Произошла ошибка. Попробуйте ещё раз',
        }));
      }
    },

    async findData() {
      if (this.data.length > 0 && this.clientPack.id) {
        this.clientPack.full_name = null;
        this.updateState();

        try {
          const { data } = await this.getApiClientPackById(this.clientPack.id);

          if (data && data.city && data.client_pack) {
            const clientPack = {
              city: data.city.name,
              date: new Date(Date.parse(data.client_pack.created_at)).toLocaleDateString('ru-RU', {timeZone: 'Europe/Moscow'})
            }

            this.similar.resultsAutozak.push(clientPack);
          }

          this.similar.state = 'found';
          this.similar.ready = true;
        } catch(e) {
          this.similar.state = 'not-found';
          this.similar.ready = true;
          this.errorMessage = 'Автозак не найден';
        }
      }
    },
    onInput() {
      this.similar.resultsAutozak = [];

      if (this.clientPack.full_name) {
        this.clientPack.full_name = null;
        this.similar.results = [];
      }
    },
    updateState() {
      this.similar.state = 'loading';
      this.similar.ready = false;
      this.similar.results = [];
      this.similar.resultsAutozak = [];
    },
    clearError() {
      this.errorMessage = '';
    }
  },
}
</script>

<style lang="less" scoped>
.moves /deep/ .modal {
  height: fit-content;
}

.table, .right {
  margin: 10px 0;
}

.checkbox {
  margin-bottom: 24px;
}

.move-table-size {
  height: 300px;
  overflow: scroll;
}

.button {
  margin: 0 10px;
}

.circle-btn {
  background: none;
  border: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .09);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @green;
  position: relative;
  cursor: pointer;

  svg {
    height: 14px;
    width: auto;
  }
}

.search {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
