<template>
    <Modal class="violence" @close="$emit('close')">
        <template #title>Жесть</template>
        <template>
            <div class="comments-list">
                <div v-for="c in orderedComments" :key="c.id" class="comment">
                    <div class="comment-info">
                        <span>{{ date(c.created_at).format('DD.MM.YY HH:mm') }}</span>
                    </div>
                    <p>{{ c.comment }}</p>
                    <p v-if="c.severity"><b>Жесть:</b> {{ c.severity }}</p>
                    <ul v-if="c.violence.length > 0">
                        <li v-for="z in c.violence" :key="z">{{ getViolenceLabel(z) }}</li>
                    </ul>
                    <hr>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import Modal from '../ui/Modal';
import {mapGetters} from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'ViolenceCommentsModal',
  components:  {Modal},
  props: {
    data: Object,
  },
  data() {
    return {
      comments: [],
    }
  },
  computed: {
    ...mapGetters({
      violence: 'violence',
    }),
    orderedComments: function () {
      return this.comments.slice()
        .sort((a, b) =>  b.severity - a.severity);
    },
  },
  mounted(){
    this.comments = this.data?.detention_set.map(x => x.comment).flat();
    this.comments = this.comments.filter(x => x.severity > 1);
  },
  methods: {
    date(raw_date) {
      return dayjs(raw_date)
    },
    getViolenceLabel(violence){
      return this.violence.find(y => y.id === violence)?.name;
    }
  }
}
</script>

<style>
    .comments-list {
        overflow-y: scroll;
        height: 350px;
    }
</style>
