<template>
  <div class="modal-wrapper">
    <div class="modal-backdrop" @click="$emit('close')"></div>
    <div :class="['modal', {large}]">
      <div class="modal-header">
        <span><slot name="title"></slot></span>
        <button @click="$emit('close')">
          <Icon name="close"/>
        </button>
      </div>

      <div class="modal-content">
        <slot class="modal-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon';

export default {
  name: 'Modal',
  props: {
    large: Boolean
  },
  components: {Icon}
}
</script>

<style lang="less" scoped>
.modal {
  color: @black;
  z-index: 99;
  overflow: auto;

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
  }

  &-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(184, 185, 197, 0.35);
  }

  & {
    width: 450px;
    height: 450px;
    background-color: @bg-lighter;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    overflow: visible;

    &.large {
      width: calc(100% - 80px);
      max-width: 800px;
      height: calc(100% - 80px);

    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px;
    border-radius: 20px;
    color: @cold-grey;
    text-transform: uppercase;
    letter-spacing: .02em;
    font-weight: 500;
    font-size: .85em;
    //position: sticky; TODO: check that this fix works well. The issue - modal header keeps moving when deleting comments.
    top: 0;
    background-image: linear-gradient(to bottom, white 65%, transparent);
    z-index: 99;

    button {
      display: block;
      background: none;
      border: none;
      color: currentColor;
      padding: 5px;
      cursor: pointer;
      width: 20px;

      svg {
        display: block;
      }
    }
  }

  &-content {
    flex: 1;
    padding: 0 30px;
  }
}
</style>
