<template>
  <div class="wrapper">
    <div class="search_bar">

      <div class="city_choice radio">
        <div 
            v-for="option in city_choice_options" 
            :key="option.id"
            :class="[{'radio__selected': option.id == search_data.city}]"
          >
          <input :id="option.id" v-model="search_data.city" required type="radio" :value="option.id">
          <label :for="option.id">{{option.label}}</label>
        </div>
      </div >

      <SelectNew 
          id="event" 
          v-model="search_data.event" 
          :idAsValue="true" 
          class="event_choice search_field"
          optionsList="event"
          placeholder="Акция"
          :appendToBody="false"
          tooltipPosition="bottom"
        />

      <SelectNew 
          id="police_station" 
          v-model="search_data.police_station" 
          :idAsValue="true"
          :appendToBody="false"
          optionsList="police-station"
          placeholder="Отдел полиции"
          class="police_station_choice search_field"
          :extendedView=true
          :extendedOptionSubTitle="'full_address'"
          :extendedOptionTitle="'short_name'"
          ajaxSearch="/?name__icontains=%s"
          ajaxUrl="police-station"
          displayKey="name"
          tooltipPosition="bottom"
      />             


      <div class="scope_choice radio">
        <div v-for="option in scope_choice_options" 
          :key="option.id"
          :class="[{'radio__selected': option.id == search_data.city}]">
          <input :id="option.id" v-model="search_data.scope" required type="radio" :value="option.id">
          <label :for="option.id">{{option.label}}</label>
        </div>
      </div>

      <Button
          @click="searchSimilar"
          :nosubmit="false" 
          :type="search_data.state==='loading'?'loading':'primary'" 
          :disabled="search_data.state==='loading'" 
          :data-tooltip="'Поиск'" 
          data-tooltip-position="bottom"
          square
        >
        <Icon name="search"/>
      </Button>

      <Button 
          @click="downloadData" 
          :type="buttonType" 
          :data-tooltip="'Выгрузить данные'" 
          data-tooltip-position="bottom"
          square 
        >
        <!-- {{ copied ? 'СКОПИРОВАНО В БУФЕР' :'Выгрузить данные'  }} -->
        <Icon name="download"/>
      </Button>
    </div>


    <div v-if="full_data && full_data.length" class="table">
      <Table v-for="(tab, index) in tabs" :key="index" :columns="tab.columns"
             :data="full_data" :selectedRows="selectedRows" @change="changeData"
             @select="toggleItem" :copiedRow="copiedRow"
             @sort="sortBy"
             @scrolledToBottom="loadNext"
             :sortBy="sortingOption"
      />
    </div>
    <div v-else-if="search_data.state  === 'not-found'" class="larger not-found">Данные не найдены. Уточните запрос.
    </div>
    <div v-else class="larger not-found">Уточните условия поиска.</div>

    <Loader v-if="search_data.state === 'loading'"/>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import Table from '../components/ui/EditableTable';
import SelectNew from '../components/ui/input/SelectNew';
import Icon from '../components/ui/Icon';
import Button from '../components/ui/input/Button';
import Loader from '../components/ui/Loader';

export default {
  name: 'JurHelpClientPack',
  components: {SelectNew, Table, Button, Icon, Loader},
  data() {
    return {
      copied: false,
      copiedRow: [],
      today: false,
      host_read: '/client-pack-for-lawyers/',
      host_read_today: '/recent-client-pack-for-lawyers/',
      host_write: '/client-pack-for-lawyers/bulk-lawyers/',
      host_write_status: '/client-pack-for-lawyers/bulk-lawyer-status/',
      search_data: {
        city: 'all',
        event: null,
        lawyer: null,
        state: 'idle',
        ready: true,
        police_station: null,
        scope: 'today'
      },
      full_data: [],
      selectedRows: [],
      tabs: {
        jurist: {
          title: 'Юристы (Автозак)',
          columns: {
            'id': {
              title: 'ID автозака',
              type: 'link',
              readonly: true,
            },
            'clients_count_auto': {
              title: 'person',
              type: 'text',
              readonly: true,
              titleType: 'Icon',
              titleStyle: {
                'width': '15px',
                'height': '15px',
              },
              inputStyle: 'no-min-width'
            },
            'max_severity': {
              title: 'shield-excl',
              type: 'text',
              readonly: true,
              titleType: 'Icon',
              titleStyle: {
                'color': 'red',
                'width': '15px',
                'height': '15px',
              },
              clickEvent: 'comments',
              inputStyle: 'no-min-width-button'
            },
            'detention_time': {
              title: 'Время задержания',
              type: 'datetime',
              readonly: true,
              sortable: true,
              format: 'datetime'
            },
            'lawyer': {
              title: 'Адвокат',
              type: 'selectAjax',
              url: 'lawyer',
              displayKey: 'full_name',
              query: '/?full_name_similar=%s',
              bulkEdit: true,
            },
            'lawyer_request_status': {
              title: 'Запрос адвоката',
              type: 'select',
              bulkEdit: true,
              optionsList: 'lawyer_request_status',
            },
            'lawyer_status': {
              title: 'Статус адвоката',
              type: 'select',
              bulkEdit: true,
              optionsList: 'lawyer_status',
              attention: 'lawyer_status_consistency'
            },
            'city': {
              title: 'Город',
              type: 'selectAjax',
              url: 'city',
              readonly: true,
            },
            'police_station': {
              title: 'Отдел полиции', //+
              type: 'selectAjax',
              url: 'police-station',
              readonly: true,
            },
            'event': {
              title: 'Акция',
              type: 'selectAjax',
              url: 'event',
              readonly: true,
            },
            'last_modified_by_json.username': {
              title: 'Оператор',
              type: 'text',
              readonly: true,
            }
          }
        }
      },
      city_choice_options:[
        { 
          id: 'all',
          label: 'Все'
        },  
        { 
          id: 'moscow',
          label: 'Москва'
        },
        { 
          id: 'spb',
          label: 'Санкт-Петербург'
        },
        {
          id: 'regions',
          label: 'Регионы'
        }
      ],
      scope_choice_options:[
        {
          id: 'active',
          label: 'Активные'
        },
        {
          id: 'today',
          label: 'За сегодня'
        }
      ],
      page: 1,
      hasNextPage: false,
      sortingOption: 'detention_time,asc'
    }
  },
  async mounted() {
    this.setTitle({text: 'Юристы'});
    await this.searchSimilar();
  },

  computed: {
    ...mapGetters({
      city: 'city',
      policeStation: 'policeStation',
      violence: 'violence',
      special_marks: 'special_marks',
    }),
    buttonType() {
      return this.copied ? 'secondary' : 'primary'
    },
  },
  methods: {
    ...mapActions(['setTitle', 'getByUrlWithQuery', 'patchByUrlWithId', 'getCity', 'getApiClientPackById', 'copyText']),
    async downloadData(index = null) {
      if (index === null && (this.selectedRows.length === 0 || this.selectedRows.length > 3)){
        alert('Пожалуйста, выберите от 1 до 3 автозаков!');
        return;
      }
      let data = index !== null
        ? [this.full_data[index]]
        : this.full_data.filter(x => this.selectedRows.includes(x.id));
      this.search_data.state = 'loading';
      let result = [];

      for (let i = 0; i < data.length; i++) {
        let temp = {overall: {}, details: []};

        temp.overall.id = data[i].id;
        temp.overall.city = this.city.find(x => x.id === data[i].city);
        temp.overall.police_station = this.policeStation(data[i].police_station);

        let request = await this.getApiClientPackById(data[i].id);

        let client_pack = request.data?.result || request.data;
        for (let j = 0; j < client_pack.detentions.length; j++) {
          let detention = client_pack.detentions[j];

          detention.row = (detention.client_json.full_name ? detention.client_json.full_name : '-');

          detention.row += (detention.client_json.phone_number_json && detention.client_json.phone_number_json.length > 0 ?
            '  | тел: ' + detention.client_json.phone_number_json.map(p => p.phone_number).join(', ') :
            (detention.client_json.telegram_id ? ' | телеграм: ' + detention.client_json.telegram_id : ' | тел: -')
          );

          detention.row += ' | д.р.: ' + (detention.client_json.birth_date ?
            new Date(Date.parse(detention.client_json.birth_date)).toLocaleDateString('ru-RU', {timeZone: 'Europe/Moscow'})
            : '-');

          detention.row += (detention.special_marks && detention.special_marks.length > 0 ? ' | ' +
              detention.special_marks.map(p => (this.special_marks.find(s => s.id === p)?.name)).join(' | ') : '');

          detention.row += (detention.comment_json && detention.comment_json.length > 0 && detention.comment_json.filter(s => s.severity > 1).length > 0
            ? ' | ' + detention.comment_json.filter(x => x.severity > 1)
              .map(a => 'Жесть ' + (a.severity ?? '0') + (a.violence.length > 0 ? ', ' +
                  a.violence.map(b => this.violence.find(s => s.id === b)?.name)
                    .join(', ') : '')
              ).join('; ') : '');

          if (detention.city !== temp.overall.city.id) {
            detention.row += '| ' + this.city.find(x => x.id === detention.city)?.name;
          }

          temp.details.push(detention);
        }

        temp.row = (temp.overall.id ? temp.overall.id : '-')
            + ' | ' + (temp.overall.city ? temp.overall.city.name : '-')
            + ' | ' + (temp.overall.police_station ? temp.overall.police_station.short_name
                + ' (' + temp.overall.police_station.full_address + ')' : '-');

        temp.row += '\r\n' + '\r\n';

        temp.row += temp.details.map(x => x.row).join('\r\n');
        result.push(temp);
      }
      result.row = result.map(x => x.row).join('\r\n\r\n\r\n');

      this.copyText(result.row);
      if(index !== null){
        this.copiedRow.push(index);
        setTimeout(() => {
          this.copiedRow = [];
          this.refreshWithDebounce(this);
        }, 1000);
      } else {
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 1000);
      }
      this.search_data = Object.assign({}, this.search_data, {state: 'found'});
    },
    cityId(name) {
      let city = this.city.find(x => x.name === name);
      if (city) return city.id;
    },
    relatedCityById(id) {
      let city = this.city.find(x => x.id === id);
      return city?.related_city;
    },
    async searchSimilar() {
      this.search_data.state = 'loading';
      this.search_data.ready = false;
      this.page = 1;
      this.full_data = [];
      try {
        
        let res = await this.getPageData();
        this.$vueSet(this, 'full_data', res?.results);

        this.hasNextPage = res?.next ?? false;
        this.search_data = Object.assign({}, this.search_data, {ready: true});
        
        let searchState = this.full_data.length !== 0 ? 'found' : 'not-found';
        this.search_data = Object.assign({}, this.search_data, {state: searchState});
      }
      catch(e) {

        if (e.response?.status === 400 || e.response?.status === 800) {
          this.errors(e.response.data.detail_json);
        }
        this.search_data = Object.assign({}, this.search_data, {state: 'not-found'});
      }
      finally {
        this.search_data = Object.assign({}, this.search_data, {ready: true});
      }
    },
    async appendNextPage(){
      if(!this.hasNextPage) {
        return;
      }

      let nextPage = this.page + 1;
      this.search_data.state = 'loading';
      this.search_data.ready = false;
      try {

        let nextPageResponse = await this.getPageData(nextPage);
        this.hasNextPage = nextPageResponse?.next ?? false;
        let nextPageData = nextPageResponse?.results;
        
        this.page++;
        this.full_data.push(...nextPageData)
        
      }
      finally {
        this.search_data.state = 'found';
        this.search_data.ready = true;
      }
      
    },
    async getPageData(page) {
      let query = [];
      let moscowCityId = this.cityId('Москва');
      let spbCityId = this.cityId('Санкт-Петербург');

      if (this.search_data.city) {
        let cityId;
        switch (this.search_data.city) {
        case 'moscow':
          cityId = moscowCityId;
          break;
        case 'spb':
          cityId = spbCityId;
          break;
        case 'regions':
          query.push(`exclude_city=${moscowCityId},${spbCityId}`);
          break;
        case 'all':
          break;
        }

        if (cityId) {
          let subquery = 'detention__city__related_city__id=' + cityId;
          query.push(subquery);
        }
      }

      if (this.search_data.event) {
        let subquery = 'detention__event__id=' + (this.search_data.event?.id || this.search_data.event);
        query.push(subquery);
      }

      if (this.search_data.lawyer){
        let subquery = 'detention__lawyer__id=' + this.search_data.lawyer.id;
        query.push(subquery);
      }

      if (this.search_data.police_station){
        let subquery = 'detention__police_station__id=' + this.search_data.police_station.id;
        query.push(subquery);
      }

      if(this.search_data.scope === 'today'){
        let subquery = 'detention_time_interval=' + (24 * 60 * 60);
        query.push(subquery);
      }

      if(page){
        let subquery = 'page=' + page;
        query.push(subquery);
      }

      if(this.sortingOption){
        let sortingOptionSplitted = this.sortingOption.split(',');
        if(sortingOptionSplitted[0] === 'detention_time'){
          let subquery = 'order=' + (sortingOptionSplitted[1] === 'asc' ? 'detention_time' : '-detention_time');
          query.push(subquery);
        }
      }

      let getDataResponse = await this.getByUrlWithQuery({url: this.host_read, query: query});
      let res = getDataResponse.data;

      return res;
    },
    changeData(row = null, key = null, val = null) {

      let itemIndex = this.full_data.findIndex(
        function (item) {
          return item.id === row.id;
        }
      );
      this.$vueSet(this.full_data[itemIndex], key, val);
      this.enterData(itemIndex, key);
    },

    async enterData(index, key) {
      let t = this;
      if (this.full_data[index]) {
        let data = this.full_data[index];
        if ((key === 'lawyer_request_status') ||
             (key === 'lawyer_status')
        ){

          let lawyer_request_status = data.lawyer_request_status;
          let lawyer_status = data.lawyer_status;
          let data_for_send = {};
          if (lawyer_request_status !== undefined && lawyer_request_status !== null) {
            data_for_send.lawyer_request_status = lawyer_request_status;
          }
          if (lawyer_status !== undefined && lawyer_status !== null) {
            data_for_send.lawyer_status = lawyer_status;
          }
          this.patchByUrlWithId({url: this.host_write_status, id: data.id
            , data: data_for_send})
            .then(
              () => {
                t.refreshWithDebounce(t);
              }
            );
        }
        else if (key === 'lawyer') {
          let lawyer_id = data.lawyer?.id || data.lawyer;
          this.patchByUrlWithId({url: this.host_write, id: data.id,  data: {lawyer_id}})
            .then(
              () => {
                this.downloadData(index);
              }
            );
        }
      }
    },
    refreshWithDebounce: debounce((vm) => {
      vm.searchSimilar();
    }, 250),
    loadNextWithDebounce: debounce((vm) => {
      vm.appendNextPage();
    }, 1000, {'leading': true, 'trailing': false}),
    toggleItem(id) {
      if (this.selectedRows.includes(id)) {
        this.selectedRows = this.selectedRows.filter(function (value) {
          return value !== id
        });
      } else {
        this.selectedRows.push(id);
      }
    },
    sortBy(key){
      this.sortingOption = key;
      this.searchSimilar();
    },
    loadNext(){
      this.loadNextWithDebounce(this);
    }
  }
}
</script>

<style lang="less" scoped>
.search_field {
  display: flex;
  align-items: center;
  margin: 0 10px;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 99;
  flex-grow: 1;

  /deep/ input.vs__search {
    font-size: 1em!important;
  }

  /deep/ .vs__dropdown-toggle {
    background-color: white;
  }

  /deep/ [data-tooltip]::before{
    font-size: 1em;
    margin-top: 12px;
  }
}

.today_choice {
  display: flex;
  align-items: center;
  margin: 0 10px;
  color: @green-dark;
  letter-spacing: .02em;
  font-weight: 400;
  position: sticky;
  top: 0;
  z-index: 99;
  font-size: 1.25em;
}

.city_choice {
  justify-content: space-between;
  margin-left: 25px;
  max-width: 450px;
  color: @middle-grey;

  &__selected{
    color: @green;
  }

  input {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    outline: none;
    border: 2px solid @middle-grey;
    min-width: 18px;
    margin: 4px;
  }

  input:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 2px auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: @green;
  }

  input[type="radio"]:checked {
    border-color:@green;
  }
}

.city_choice > div {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.event_choice {
  flex-grow: 1;
}

.search_bar {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  font-size: 0.75em;
  padding: 0px 10px;

  button {
    margin: auto 5px;
    height: 36px;
    border-radius: 6px;
    font-size: 1em;
  }

  button:not(.square){
    padding: 5px 10px;
  }

  button.square {
    padding: none;
    min-width: 36px;
    width: 36px;
    height: 36px;
    svg {
      width: 17px;
      height: 17px;
    }
  }

  .radio {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    max-width: 450px;
    color: @middle-grey;
    text-transform: uppercase;

    &__selected{
      color: @green;
    }

    input {   
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      outline: none;
      border: 2px solid @middle-grey;
      min-width: 18px;
      margin: 4px;
    }

    input:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin: 2px auto;
      border-radius: 50%;
    }

    input[type="radio"]:checked:before {
      background: @green;
    }
      
    input[type="radio"]:checked {
      border-color:@green;
    }

    & > div {
      display: flex;
      align-items: center;
    }
  }

  .event_choice {
    flex-grow: 1;
  }

  .police_station_choice {
    flex-grow: 1;
  }

  .scope_choice {
    padding: 0 5px;
  }

  .city_choice {
    padding: 0 5px;
  }
}

.table {
  overflow: auto;
}

.wrapper {
  position: relative;
  margin-top: 18px;
}

</style>