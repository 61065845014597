<template>
  <CallCard :active="data.active">
    <template #number>{{ data.number }}</template>
    <template #title>Из вашего автозака уже звонили?</template>
    <template #inputs>
      <div>
        <Button square @click="sendData(true)">Да</Button>
        <Button square type="cancel" @click="sendData(false)">Нет</Button>
      </div>
    </template>
    <template #outputs>
      <span>{{ displayedValue }}</span>
    </template>

  </CallCard>
</template>

<script>
import CallCard from '../CallCard';
import Button from '../../ui/input/Button';

export default {
  name: 'HaveCalled',
  components: {Button, CallCard},
  props: ['data'],
  data() {
    return {
      result: {
        haveCalled: null
      }
    }
  },
  methods: {
    sendData(result) {
      this.result.haveCalled = result;
      this.$emit('nextStep', {next: result ? 'detainedName:haveCalled' : 'city', result: this.result.haveCalled});
    }
  },
  computed: {
    displayedValue() {
      return (this.result.haveCalled || this.data.stepResults.haveCalled) ? 'Да' : 'Нет'
    }
  }
}
</script>
