<template>
  <aside :class="['call-sidebar', {collapsed: collapseCall}]">
    <div class="call-sidebar__toggle" @click="toggleCollapseCall"><span v-if="!collapseCall">Скрыть</span>
      <Icon name="chevron-small"/>
    </div>
    <CallSidebarSession :collapsed="collapseCall" :data="data" @listenAudio="listenAudio" @sendSMS="sendSMS"/>
  </aside>
</template>

<script>
import CallSidebarSession from './CallSidebarSession';
import Icon from '../ui/Icon';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'CallSidebar',
  components: {Icon, CallSidebarSession},
  props: ['data'],
  data() {
    return {
      open: true
    }
  },
  methods: {
    ...mapActions(['toggleCollapseCall']),
    newSession() {
      this.$router.push({query: {newSession: true}});
      this.$router.go();
    },
    sendSMS() {
      this.$emit('sendSMS');
    },
    listenAudio() {
      this.$emit('listenAudio');
    }
  },
  computed: {
    ...mapGetters({
      hasCall: 'call/hasCall',
      collapseCall: 'collapseCall'
    }),
  }
}
</script>

<style lang="less" scoped>
.call-sidebar {
  width: 342px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

  &.collapsed {
    width: 100px;
  }

  .sessions {
    &-toggle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: @cold-grey;
      padding: 10px 15px;
      font-size: .9em;
    }
  }

  &__toggle {
    color: @cold-grey;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 15px;
    font-size: .9rem;
    cursor: pointer;

    svg {
      transform: rotate(-90deg);
      width: 12px;
      height: 12px;
      margin-left: 10px;

      .collapsed & {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
