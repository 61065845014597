<template>
  <div class="table inline-table">
    <table>
      <thead>
      <tr>
        <th v-if="selectMode === 'radio'">
          <button v-if="selected" class="reset-selection" data-tooltip="Отменить выбор" data-tooltip-small
                  @click="cancelSelectSimilar">
            <Icon name="close"/>
          </button>
        </th>
        <th v-for="(title, field)  in columns" :key="field">{{ title }}</th>
        <th v-if="selectMode === 'checkbox'"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in formatData" :key="item.key">
        <td v-if="selectMode === 'radio'" class="center">
          <Radio :name="_uid+'_radio'" :model-value="item.key" v-model="selected"/>
        </td>
        <td v-for="(title, field) in columns" :class="[{nowrap: field === 'phone'}]" :key="field">
          <div v-if="field === 'special_marks'">
            <Tag v-for="tag in item[field]" :text="tag.toString()" :key="tag" outline/>
          </div>
          <div v-else-if="field === 'phone' && item[field]" v-for="ph in item[field].split(', ')" :key="ph">
            {{ ph }}
          </div>
          <div v-else-if="selectMode === 'obzvon' && field === 'take' && !item['responsible_user']"><Button @click="$emit('take', item.key)">Беру</Button></div>
          <div v-else-if="selectMode === 'obzvon' && field === 'take' && item['responsible_user_id']=== user_id"><Button @click="$emit('giveup', item.key)" type="secondary">Отдать</Button></div>
          <div v-else-if="field === 'uncertain_state'"><Checkbox disabled :value="item[field]"/></div>
          <div v-else-if="((selectMode === 'radio' || selectMode === 'sessions') && (field === 'clientPackId'))
                               || (selectMode === 'obzvon' && field === 'key')
                               || (selectMode === 'court' && field === 'client_pack')"><a @click="$emit('reroute', item[field])">{{ item[field] }}</a></div>
            <div v-else-if="field === 'phone_or_tg' && sessionMode === 'chat' && item['clientPackId']">
                <a @click="$emit('rerouteChat', item['clientPackId'], item[field])">{{ item[field] }}</a>
            </div>
            <div v-else-if="(field === 'cases')">
            <LinkList :value="item[field]" type="/court/" />
          </div>
          <div v-else-if="field === 'audiolink'">
            <a @click="CallAudioModalShow = item[field]">{{item[field]}}</a>
          </div>
          <span v-else-if="field === highlight && item['highlight']" class="red">
                {{ item[field] }}
              </span>
          <span v-else>{{ item[field] }}</span>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="table-loading" v-if="loading"></div>
    <div v-if="selectMode === 'sessions'">
      <AudioModal v-if="CallAudioModalShow !== null" :id="CallAudioModalShow" type="sessions" url="/call-record/"
                  @close="CallAudioModalShow=null" />
    </div>
  </div>
</template>

<script>
import Radio from './input/Radio';
import Tag from './Tag';
import Icon from './Icon';
import Button from './input/Button';
import Checkbox from './input/Checkbox';
import LinkList  from './LinkList';
import AudioModal from '../call/AudioModal';

import {mapGetters} from 'vuex';

//Heads up: here I use now key instead of id because in the table we have multiple ids and clientpackids.
export default {
  name: 'InlineTable',
  components: {Icon, Tag, Radio, Button, Checkbox, LinkList, AudioModal},
  props: ['columns', 'data', 'selectMode', 'type', 'loading', 'highlight', 'sessionMode'],
  data() {
    return {
      selected: null,
      CallAudioModalShow: null,
    }
  },
  methods: {

    cancelSelectSimilar() {
      this.selected = null;
      this.$emit('select', null);
    }
  },
  computed: {
    ...mapGetters({
      user_id: 'user_id',
    }),
    formatData() {
      if (this.type === 'clients') {
        return this.data.map(el => {
          el.name = el.full_name;
          el.phone = el.phone_number_json?.map(a => (a.phone_number)).join('; ');
          return el
        })
      } else return this.data
    }
  },
  watch: {
    selected(val) {
      this.$emit('select', val)

    }
  }
}
</script>

<style lang="less" scoped>
.table {
  flex: 1;
  background-color: fade(@bg-light, 65%);
  border-radius: 20px;
  position: relative;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 180px);
  height: 100%;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background: #f1f4f4;
      position: sticky;
      top: 0;
      z-index: 5;
    }
    th, td {
      position: relative;

      &:not(:first-child)::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 12px;
        width: 1px;
        height: calc(100% - 24px);
        background-color: fade(@cold-grey, 50%);
      }
    }

    th {
      font-size: .7em;
      color: @cold-grey;
      padding: 18px 10px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: start;

      .reset-selection {
        border: none;
        background: none;
        padding: 0 2px;
        color: inherit;
        width: 16px;
        margin: auto;
        display: block;
        cursor: pointer;

        svg {
          display: block;
        }
      }
    }

    td {
      padding: 15px 10px;
      color: @cold-grey-dark;
      font-size: .85em;
      line-height: 1.5;
      vertical-align: top;
      border-top: 1px solid fade(@cold-grey, 50%);

      &.red {
        color: @red;
      }

      &.nowrap {
        white-space: nowrap;
      }

      &.center {
        text-align: center;

        & > * {
          margin: auto;
        }
      }
    }
  }

  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    z-index: 4;
    background-color: rgba(255, 255, 255, 0.50);
  }
}
</style>
