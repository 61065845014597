const actions = {
  // eslint-disable-next-line no-unused-vars
  async postApiSession({state}, session){
    return await this._vm.$api.post('session/', session);
  },

  // eslint-disable-next-line no-unused-vars
  async patchApiSession({state}, payload){
    return await this._vm.$api.patch(`session/${payload.id}/`, payload.data);
  },
  async getSessions(){
    let r = await this._vm.$api.get('/session');
    return r.data;
  },
  async getLastChatSession(_, chatId){
    try {
      let response = await this._vm.$api.get(`session/latest-by-chat/${chatId}/`);
      if(response.status === 200){
        return {  
          sessionId: response.data.id,
          clientPackId: response.data?.client_pack?.id,
          clientId: response.data?.client?.id
        }
      }
    } catch {
      // no session found 
    }
    return null
  }
};

export default {
  actions,
};
