const state = {
  dataSent: false,
  client_id: null,
  client_pack_id: null,
  telegram_id: null,
  healthOk: true,
  internetDown: false,
};

const getters = {
  getInternetDownStatus: (state) => state.internetDown,
  getPulse: (state) => state.healthOk,
  getDataSent: (state) => state.dataSent,
  getClientId: (state) => state.client_id,
  getClientPackId: (state) => state.client_pack_id,
  getTelegramId: (state) => state.telegram_id
};

const actions = {
  async toggleInternetDown({commit}, status) {
    commit('toggleInternetDown', status);
  },
  async toggleHealthOk({commit}, status){
    commit('toggleHealthOk', status);
  },
  async setDataSent({commit}, status) {
    commit('toggleDataSent', status);
  },
  async setClientId({commit}, id) {
    commit('mutateClientId', id);
  },
  async refreshClientId({commit}) {
    commit('deleteClientId');
  },
  async setClientPackIdStore({commit}, id) {
    commit('mutateClientPackId', id);
  },
  async refreshClientPackId({commit}) {
    commit('deleteClientPackId');
  },
  async setTelegramId({commit}, id) {
    commit('mutateTelegramId', id);
  },
  async refreshTelegramId({commit}) {
    commit('deleteTelegramId');
  },
};

const mutations = {
  toggleInternetDown(state, status) {
    state.internetDown = status;
  },
  toggleHealthOk(state, status){
    state.healthOk = status;
  },
  toggleDataSent(state, status) {
    state.dataSent = status;
  },
  mutateClientId(state, id) {
    state.client_id = id;
  },
  mutateClientPackId(state, id) {
    state.client_pack_id = id;
  },
  mutateTelegramId(state, id) {
    state.telegram_id = id;
  },
  deleteClientId(state) {
    state.client_id = null;
  },
  deleteClientPackId(state) {
    state.client_pack_id = null;
  },
  deleteTelegramId(state) {
    state.telegram_id = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
