<template>
  <div id="app" v-if="isAuthenticated || !($route.meta.requiresAuth)">
    <MenuSidebar/>
    <main>
      <Header/>
      <div class="view-wrapper">
        <router-view v-if="hasPulse&&!isInternetDown" class="view"/>
        <div v-else-if="isInternetDown" class="no-calls red">
          Пожалуйста, проверьте ваше соединение с интернетом!<br>
          После этого дождитесь, когда это сообщение исчезнет, или перезагрузите страницу.
        </div>
        <div class="no-calls red" v-else>
          Сервер временно перегружен.<br>
          Пожалуйста, не закрывайте страницу и попробуйте ввести данные, когда это сообщение исчезнет, или перезагрузите страницу позже.<br>
          Приносим извинения за временные неудобства!</div>
      </div>
    </main>
    <IncomingCall/>
    <TransferCall v-if="transferring"/>
    <RootPortals/>
    <notifications position="center" classes="notification"/>
  </div>
  <div v-else class="no-calls red">
    Вы не авторизованы! Перезагрузите страницу.
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import MenuSidebar from './components/app/MenuSidebar';
import Header from './components/app/Header';
import RootPortals from './components/app/RootPortals';
import IncomingCall from '@/components/call/IncomingCall';
import TransferCall from '@/components/call/TransferCall';

export default {
  components: {IncomingCall, TransferCall, RootPortals, Header, MenuSidebar},
  name: 'App',
  computed: {
    ...mapGetters({
      hasPulse: 'getPulse',
      isInternetDown: 'getInternetDownStatus',
    }),
    // incomingCall() {
    //   return this.$store.state.call.phoneState === 'incoming'
    // },
    // outgoingCall() {
    //   return this.$store.state.call.phoneState === 'calling'
    // },
    transferring() {
      return this.$store.state.call.transferCall === true
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    }
  },
  created() {
    this.$store.commit('setTitle', {text: null, url: null});
  },
  destroyed() {
    this.$store.commit('setTitle', {text: null, url: null});
  },
  beforeMount() {
    if (this.isAuthenticated) this.$store.dispatch('getDicts', this.$api);
  },
  watch: {
    isAuthenticated(newVal) {
      if (newVal) this.$store.dispatch('getDicts', this.$api)
    }
  },
}
</script>

<style lang="less">
@import (css) url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap');

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: @black-2;
  color: @black;
  font-family: 'Inter', sans-serif;
}

*, *::after, *::before {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

#app {
  height: 100%;
  display: flex;
  padding: 10px 10px 10px 5px;
}

main {
  flex: 1;
  background-color: @bg-light;
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .view {
    flex: 1;
    overflow: auto;

    &-wrapper {
      display: flex;
      flex: 1;
      max-height: calc(100% - 60px);
    }
  }
}

.notification {
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 0.9em;
  color: #ffffff;

  background: #44A4FC;
  border-left: 5px solid #187FE7;

  &.success {
    background: #67C1BC;
    border-left-color: #149F97;
  }

  &.warn {
    background: #EE9A6A;
    border-left-color: #D6540B;
  }

  &.error {
    background: #E54D42;
    border-left-color: #B82E24;
  }
}

.no-calls {
  text-align: center;
  font-size: 1.8em;
  color: @green;
  font-weight: 500;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.red {
  color: @red;
}

/*  button, span {
  &:hover .tooltip {
    display: inline;
  }

  .tooltip {
    color: @green;
    font-size: .8em;
    display: none;
    position: absolute;
  }
}*/
</style>

