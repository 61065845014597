<template>
  <div class="client-actions">
    <button :class="{'noComment': !commentExist, 'badComment': badComment}" class="circle-btn"
            data-tooltip="Комментарии"
            @click="showComments = true"
    >
      <Icon name="comment"/>
    </button>
    <button :disabled="!client.client_json.telegram_id" class="circle-btn"
            data-tooltip="Telegram чат" @click="openChat({chatId: client.client_json.telegram_id})">
      <Icon name="telegram"/>
    </button>
    <button :class="{'noPhone' : !phonesExist, 'errorPhone' : errorPhone}"
            :data-tooltip='errorPhone ?
            `Телефон не прошел валидацию. Исправьте номер или перенесите его в "Другие контакты"` : `Номера телефонов`' class="circle-btn"
            @click="$emit('showPhones')">
      <Icon name="outbound-call"/>
    </button>

    <DetentionComments v-if="showComments" :detentionId="client.id" :comments="client.comment_json"
                       @commentRemove="commentRemove" @close="showComments = false"
                       @comment = "comment"
    />
  </div>
</template>

<script>
import Icon from '../ui/Icon';
import DetentionComments from './DetentionComments';
import {mapActions, mapMutations} from 'vuex';

export default {
  name: 'ClientActionsColumn',
  components: {DetentionComments, Icon},
  props: ['client'],
  data() {
    return {
      showDetails: false,
      showComments: false
    }
  },
  methods: {
    ...mapActions(['setTelegramId']),
    ...mapMutations(['cleanupChatSessions']),
    comment(comment){
      this.$emit('comment', comment);
    },
    commentRemove(id, item) {
      this.$emit('commentRemove', id, item);
    },
    openChat(chatId) {
      if (chatId) {
        this.setTelegramId(chatId.chatId);
        if(this.$route.name.indexOf('Chat') === -1) {
          this.cleanupChatSessions(chatId.chatId)
          this.$router.push({name: 'Chat', params: {query: {id: this.client.client_pack, chatId: chatId.chatId}}});
        }
        this.$bus.$emit('openChat', chatId);
      }
    },
  },
  computed: {
    phonesExist() {
      return this.client?.client_json?.phone_number?.length > 0 || this.client?.client_json?.phone_number_json?.length > 0 || this.client?.client_json?.other_contacts;
    },
    errorPhone() {
      return this.client?.client_json?.phone_error === true;
    },
    commentExist() {
      return this.client?.comment?.length > 0;
    },
    badComment() {
      return this.client?.comment_json?.filter(a => a?.severity >= 1)?.length > 0;
    }
  }
}
</script>

<style lang="less" scoped>
.client-actions {
  display: flex;
  align-items: center;
  gap: 10px;

  .circle-btn {
    background: none;
    border: none;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .09);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @green-dark;
    position: relative;
    cursor: pointer;

    svg {
      height: 14px;
      width: auto;
    }

    &-counter {
      position: absolute;
      display: flex;
      font-size: 0.66rem;
      font-weight: 600;
      background: @green;
      color: white;
      border-radius: 7px;
      min-width: 14px;
      height: 14px;
      justify-content: center;
      align-items: center;
      left: calc(100% - 10px);
      top: -5px;
      padding: 3px;
    }

    &:disabled {
      color: @cold-grey;
      cursor: not-allowed;
    }
  }

  .noPhone, .noComment {
    color: @cold-grey;
  }

  .errorPhone, .badComment {
    color: @red;
  }
}

</style>
