import store from '../index';

const actions = {
  // eslint-disable-next-line no-unused-vars
  async postApiClientPack({state}, data){
    return await this._vm.$api.post('client-pack-full/', data);
  },
  async getApiClientPackById(_, id){
    return await store.dispatch('getByUrl', 'client-pack-full/' + id);
  },
  async patchDetentionMove(_, data){
    return await this._vm.$api.patch('detention/move/', data);
  },

};

export default {
  actions,
};
