<template>
  <CallCard :active="data.active">
    <template #number>{{ data.number }}</template>
    <template #title>Вы хотите что-то спросить/рассказать о конкретном задержанном?</template>
    <template #inputs>
      <div>
        <Button square @click="sendData(true);">Да</Button>
        <Button square type="cancel" @click="sendData(false);">Нет</Button>
      </div>
    </template>
    <template #outputs>
      <span>{{ displayedValue }}</span>
    </template>
  </CallCard>
</template>

<script>
import CallCard from '../CallCard';
import Button from '../../ui/input/Button';

export default {
  name: 'WantSpecificPerson',
  components: {Button, CallCard},
  props: ['data'],
  data() {
    return {
      result: {
        wantSpecificPerson: null
      }
    }
  },
  methods: {
    sendData(result) {
      this.result.wantSpecificPerson = result;
      this.$emit('nextStep', {
        result: this.result.wantSpecificPerson,
        next: this.result.wantSpecificPerson ? 'whoToDetained' : 'noKorostel'
      });
    }
  },
  computed: {
    displayedValue() {
      return (this.result.wantSpecificPerson || this.data.stepResults.wantSpecificPerson) ? 'Да' : 'Нет'
    }
  }
}
</script>
