import { render, staticRenderFns } from "./RootPortals.vue?vue&type=template&id=aa8ec464&scoped=true"
import script from "./RootPortals.vue?vue&type=script&lang=js"
export * from "./RootPortals.vue?vue&type=script&lang=js"
import style0 from "./RootPortals.vue?vue&type=style&index=0&id=aa8ec464&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa8ec464",
  null
  
)

export default component.exports