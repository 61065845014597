const INTERVAL = 1000*60*5;

const state = {
  violence: [],
  violenceObj: {},
  special_mark: [],
  special_markObj: {},
  article: [],
  help_type_request: [],
  help_type_requestObj: {},
  city: [],
  event: [],
  help_type_provision: [],
  detention_status: [],
  help_status: [],
  lawyer_request_status: [],
  lawyer_status: [],
  lawyer_type: [],
  work_mode: [],
  court_session_first: [],
  court_session_appeal: [],
  expireTime: 0
};

const getters = {
  detention_status: (state) => state.detention_status,
  default_event: (state) => state.work_mode.length > 0 ? state.work_mode[0].default_event : null,
  violence: state => state.violence,
  violenceObj: state => state.violenceObj,
  special_marks: state => state.special_mark,
  special_marksObj: state => state.special_markObj,
  article: state => state.article,
  articleObj: state => state.articleObj,
  help_type_request: state => state.help_type_request,
  help_type_requestObj: state => state.help_type_requestObj,
  city: state => state.city,
  cityObj: state => state.cityObj,
  events: state => state.event,
  help_type_provision: state => state.help_type_provision,
  help_status: state => state.help_status,
  lawyer_request_status: state => state.lawyer_request_status,
  lawyer_status: state => state.lawyer_status,
  lawyer_type: state => state.lawyer_type,
  work_mode: state => state.work_mode,
};

function getObjData(data) {
  const resData = data?.results || data;
  if(!Array.isArray(resData)) {
    return resData;
  }
  return resData.reduce((ac, v) => {
    ac[v.id] = v;
    return ac;
  }, {});
}

const actions = {
  getDicts({commit, state}, api)  {
    const dn = Date.now();

    function getDictionary(name) {
      api.get(`${name}/`).then(r => {
        const varName = name.replaceAll('-', '_');
        commit('setDicts', {[varName]: r.data?.results || r.data})
        commit('setDicts', {[`${varName}Obj`]: getObjData(r.data?.results || r.data)})
      });
    }

    if (state.violence.length === 0 || dn > state.expireTime) {
      getDictionary('violence');
    }
    if (state.special_mark.length === 0 || dn > state.expireTime) getDictionary('special-mark');
    if (state.article.length === 0 || dn > state.expireTime) getDictionary('article');
    if (state.help_type_request.length === 0 || dn > state.expireTime) getDictionary('help-type-request');
    if (state.city.length === 0 || dn > state.expireTime) getDictionary('city');
    if (state.event.length === 0 || dn > state.expireTime) getDictionary('event');
    if (state.help_type_provision.length === 0 || dn > state.expireTime) api.get('help-type-provision/')
      .then(r => commit('setDicts',
        {
          help_type_provision: r.data
            .filter(a => ['адвокат', 'юрист', 'защитник'].includes(a.name.toLowerCase()))
            .map(a => a.id)
        }));

    if (state.detention_status.length === 0 || dn > state.expireTime) api.get('detention/detention-status-enum/').then(r => {
      let dict = r.data?.results || r.data;
      let dict2 = [];
      for (let each of Object.keys(dict)) {
        dict2.push({id: parseInt(each), name: dict[each]});
      }

      commit('setDicts', {
        detention_status: dict2
      });
    });

    if (state.help_status.length === 0 || dn > state.expireTime) api.get('detention/help-status-enum/')
      .then(r => {
        let dict = r.data?.results || r.data;
        let dict2 = [];

        for (let each of Object.keys(dict)) {
          dict2.push({id: parseInt(each), name: dict[each]});
        }

        commit('setDicts', {
          help_status: dict2
        });
      });

    if (state.lawyer_request_status.length === 0 || dn > state.expireTime) api.get('detention/lawyer-request-status-enum/')
      .then(r => {
        let dict = r.data;
        let dict2 = [];

        for (let each of Object.keys(dict)) {
          dict2.push({id: parseInt(each), name: dict[each]});
        }

        commit('setDicts', {
          lawyer_request_status: dict2
        });
      });
    if (state.lawyer_status.length === 0 || dn > state.expireTime) api.get('detention/lawyer-status-enum/')
      .then(r => {
        let dict = r.data;
        let dict2 = [];

        for (let each of Object.keys(dict)) {
          dict2.push({id: parseInt(each), name: dict[each]});
        }

        commit('setDicts', {
          lawyer_status: dict2
        })
      });
    if (state.lawyer_type.length === 0 || dn > state.expireTime) api.get('lawyer/lawyer-type-enum/')
      .then(r => commit('setDicts', {lawyer_type: r.data}));

    if (state.work_mode.length === 0 || dn > state.expireTime) api.get('work-mode/')
      .then(r => commit('setDicts', {work_mode: r.data}));

    if (state.court_session_first.length === 0 || dn > state.expireTime) api.get('case-court-sessions/court-session-enums/')
      .then(r => {
        const court_session_first = []
        const court_session_appeal = []
        r.data.type_to_result_mapping[10].forEach((item) => {
          court_session_first.push({id: parseInt(item), name: r.data.result_values[item]})
        })
        r.data.type_to_result_mapping[20].forEach((item) => {
          court_session_appeal.push({id: parseInt(item), name: r.data.result_values[item]})
        })
        commit('setDicts',  {
          court_session_first
        })
        commit('setDicts',  {
          court_session_appeal
        })
      });

    if(dn > state.expireTime) {
      commit('setExpireTime', dn + INTERVAL);
    }
  }
};

const mutations = {
  setDicts(state, data) {
    for (let key in data) {
      state[key] = data[key]
    }
  },
  setExpireTime(state, time) {
    state.expireTime = time
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
