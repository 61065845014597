<template>
  <portal-target class="portal-dropdowns" name="dropdowns"/>
</template>

<script>
export default {
  name: 'RootPortals'
}
</script>

<style lang="less" scoped>
/deep/ .dropdown-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
</style>
