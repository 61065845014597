const state = {
  collapseMenu: false,
  collapseCall: true,
  title: {text: null, url: null},
  chatWidth: 400
};

const getters = {
  collapseMenu: (state) => state.collapseMenu,
  collapseCall: (state) => state.collapseCall,
  title: (state) => state.title,
  chatWidth: (state) => state.chatWidth
}

const actions = {
  toggleCollapseMenu({commit, state}) {
    commit('setCollapseMenu', !state.collapseMenu)
  },
  toggleCollapseCall({commit, state}) {
    commit('setCollapseCall', !state.collapseCall)
  },
  setTitle({commit}, {text, url = null}) {
    commit('setTitle', {text, url})
  },
  setChatWidth({commit}, width) {
    commit('setChatWidth', width)
  },
  copyText (_, text) {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }
};

const mutations = {
  setCollapseMenu(state, data) {
    state.collapseMenu = data
  },
  setCollapseCall(state, data) {
    state.collapseCall = data
  },
  setTitle(state, data) {
    state.title = data
  },
  setChatWidth(state, data) {
    state.chatWidth = data
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
