<template>
  <VueTelInput
      v-model="phone_number"
      validCharactersOnly
      mode="international"
      :class="['vti', {valid: phone_obj.valid, invalid: !phone_obj.valid && phone_number }]" 
      :defaultCountry="settings.defaultCountry"
      :disabled="disabled"
      :dropdownOptions="settings.dropdownOptions"
      :enabledCountryCode="true"
      :inputOptions="settings.inputOptions"
      @blur="blur" @focus="onfocus" @input="input"
      @enter="pressEnter"
  >
    <template v-slot:arrow-icon>
      <span/>
    </template>
  </VueTelInput>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  name: 'PhoneInput',
  components: {VueTelInput},
  props: {
    value: [String, Number],
    placeholder: {
      type: String,
      default: 'Номер телефона'
    },
    name: {
      type:String,
      default:'phone-number'
    },
    type: {
      type: String,
      default: 'text'
    },
    required: Boolean,
    disabled: Boolean,
    loading: Boolean,
    invalid: Boolean,
    table: Boolean
  },
  data() {
    return {
      settings: {
        dropdownOptions: {
          disabled: true,
          showFlags: true,
        },
        enabledFlags: true,
        inputOptions: {
          required: false,
          placeholder: this.placeholder,
          name: this.name,
        },
        defaultCountry: 'RU',
      },
      phone_number: this.value,
      phone_obj: {},
      focus: false,
    }
  },
  watch: {
    value(val) {
      this.phone_number = val
    },
  },
  methods: {
    onfocus() {
      this.focus = true;
      this.$emit('focus');
    },
    blur() {
      if (this.phone_obj.valid) {
        this.$emit('blur', this.phone_obj.number);
      } else {
        if (this.phone_obj.number) {
          this.$emit('blur', 'INVALID');
          this.$emit('phoneInvalid', this.phone_obj.number);
        } else {
          this.$emit('blur', '');
        }
      }
      this.focus = false;
    },
    input(inp, obj) {
      if (inp[0] === '8' || inp[0] === 8) {
        this.phone_number = inp.slice(1, -1);
      }

      this.$vueSet(this, 'phone_obj', obj);

      if (this.phone_obj.valid) {
        this.$emit('input', this.phone_obj.number, 'VALID');
      } else {
        this.$emit('input', this.phone_obj.number, 'INVALID');
      }
    },
    pressEnter() {
      this.$emit('pressEnter');
    }
  },
}
</script>

<style lang="less">
.vti {
  background-color: @bg-input;
  padding: 0;
  border-radius: 22px;
  transition-duration: .2s;
  border-color: transparent;

  &:focus-within {
    box-shadow: none;
    border-color: transparent;
    background-color: fade(@green-light, 20%);
    color: @cold-grey-dark;
  }

  &__dropdown {
    background: none !important;
    padding: 0 0 0 12px;
  }

  &__input {
    background: none;
    font-family: inherit;
    font-size: .88em;
    color: @cold-grey-dark;
    padding: 10px 18px 10px 7px;

    &::placeholder {
      color: @cold-grey;
      transition-duration: .2s;
    }
  }
}

.invalid {
  border-color: @red;
}

.valid {
  border-color: @green;
}
</style>
