import { render, staticRenderFns } from "./JurHelpClientPack.vue?vue&type=template&id=2fb46cff&scoped=true"
import script from "./JurHelpClientPack.vue?vue&type=script&lang=js"
export * from "./JurHelpClientPack.vue?vue&type=script&lang=js"
import style0 from "./JurHelpClientPack.vue?vue&type=style&index=0&id=2fb46cff&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fb46cff",
  null
  
)

export default component.exports