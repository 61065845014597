const state = {
  cities: {}
};

let loading = [];
const actions = {
  async getCity(ctx, id) {

    let city = null;
    if (!ctx.state.cities[id] && !loading.includes(id)) {
      loading.push(id);
      city = await this._vm.$api.get('city/' + id + '/').catch(e => {
        throw new Error(e)
      }).finally(() => {
        let idIndex = loading.indexOf(id);
        if (idIndex !== -1) {
          loading.splice(idIndex, 1);
        }
      });
      ctx.commit('setCity', city?.data);
      return city?.data;
    } else if (loading.includes(id)) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(ctx.dispatch('getCity', id));
        }, 500);
      });
    } else {
      city = ctx.state.cities[id]
    }
    return city
  }
};

const mutations = {
  setCity(state, data) {
    state.cities[data.id] = data
  }
};

export default {
  state,
  actions,
  mutations
};
