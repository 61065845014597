import Vue from 'vue'
import VueRouter from 'vue-router'
import Call from '../views/Call.vue';
import Chat from '../views/Chat.vue';
import FreeEntry from '../views/FreeEntry.vue';
import Sessions from '../views/Sessions.vue';
import SipTest from '../views/SipTest';
import Login from '../Login.vue';
import store from '../store';
import Autozaki from '../views/Autozaki.vue';
import Obzvon from '../views/Obzvon.vue';
import Court from '../views/Court';
import JurHelp from '../views/JurHelp';
import JurHelpClientPack from '../views/JurHelpClientPack';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: Call
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {title: 'Логин'}
  },
  {
    path: '/call',
    name: 'Call',
    component: Call,
    meta: {requiresAuth: true, title: 'Звонок'}
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    meta: {requiresAuth: true, title: 'Чат'},
    props: true
  },
  {
    path: '/chat/:id/:chatId',
    name: 'Chat2',
    component: Chat,
    meta: {requiresAuth: true, title: 'Чат'},
    props: true
  },
  {
    path: '/freeEntry',
    name: 'FreeEntry',
    component: FreeEntry,
    meta: {requiresAuth: true, title: 'Свободная анкета'}
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: Sessions,
    meta: {requiresAuth: true, title: 'Сессии'}
  },
  {
    path: '/jurhelp',
    name: 'JurHelp',
    component: JurHelp,
    meta: {requiresAuth: true, title: 'Юридическая помощь'}
  },
  {
    path: '/jurhelpclientpack',
    name: 'JurHelpClientPack',
    component: JurHelpClientPack,
    meta: {requiresAuth: true, title: 'Юридическая помощь'}
  },
  {
    path: '/sip-test',
    name: 'SipTest',
    component: SipTest,
    meta: {requiresAuth: true, title: 'Звонилка'}
  },
  {
    path: '/avtozaki',
    name: 'Autozaki',
    component: Autozaki,
    meta: {requiresAuth: true, title: 'Поиск автозака'}
  },
  {
    path: '/avtozaki/:id',
    name: 'Autozaki2',
    component: Autozaki,
    meta: {requiresAuth: true, title: 'Автозак'}
  },
  {
    path: '/obzvon',
    name: 'Obzvon',
    component: Obzvon,
    meta: {requiresAuth: true, title: 'Обзвон'}
  },
  {
    path: '/court',
    name: 'Court',
    component: Court,
    meta: {requiresAuth: true, title: 'Результаты суда'}
  },
  {
    path: '/court/:id',
    name: 'Court2',
    component: Court,
    meta: {requiresAuth: true, title: 'Результаты суда'}
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.commit('setTitle', {text: null, url: null});
  document.title = to.meta.title;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next({name: 'Login'});
    }
  } else {
    next()
  }
});

export default router
