<template>
  <div ref="container" class="transition">
    <div v-if="!hasCall && sipRegistred && session_type==='call'" class="no-calls">
      Пока никто не звонит. Все отлично.
    </div>
    <div v-else-if="!hasCall && !sipRegistred && session_type==='call'" class="no-calls red">
      Не забудь выйти на линию.
    </div>
    <div v-else-if="noKorostel" class="no-calls red">
      Коростель тут больше не поможет...
    </div>

    <transition-group v-if="!locked && (session_type!=='call' || (sipRegistred && hasCall))" class="cards" name="card"
                      tag="div">
      <component :is="states[item].component" v-for="(item, index) in path.slice().reverse()"
           :key="item" :data="{number: path.length-index, active: index === 0, state: item, stepData: states[item], sessionData: result, stepResults : stepResults,
                        noAutozak: noAutozak
                    }"
           @edit="resetToStep(path.length-index-1)" 
           @nextStep="nextStep" />
    </transition-group>

    <transition name="card" tag="div">
      <ClientPack v-if="locked && (session_type!=='call' || (sipRegistred && hasCall)) && path[path.length - 1] === 'clientPack'"
                  :errors_list="errors_list"
                  :resultData="result.data"
                  :session_type="session_type" :stepResults="stepResults" :telegram="result.telegram"
                  :client_id="client_id"
                  class="cards" @clientPackId="setClientPackId" @errorConnection="errorConnection"
                  @errors="errors"
                  @openChat="openChat"/>
    </transition>


    <SmsModal v-if="SmsModalShow" :data="sidebarData" @close="SmsModalShow=false"/>
    <AudioModal v-if="CallAudioModalShow && session_type==='call'" :id="sidebarData.session.name" :type="session_type"
                @close="CallAudioModalShow=false"/>

    <div v-if="hasCall" class="sidebar-wrapper">
      <CallSidebar :data="sidebarData" @listenAudio="listenAudio" @sendSMS="sendSMS"/>
    </div>
    <div v-show="!hasCall" class="sidebar-wrapper">
      <ChatSidebar v-if="session_type==='chat'" @chat="chatUpdate"/>
    </div>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import IsDetained from './steps/IsDetained';
import HaveCalled from './steps/HaveCalled';
import WantSpecificPerson from './steps/WantSpecificPerson';
import DetainedName from './steps/DetainedName';
import NewClientPack from './steps/NewClientPack';
import CallerName from './steps/CallerName';
import ClientPack from '../clientpack/ClientPack';
import WhoToDetained from './steps/WhoToDetained';
import City from './steps/City';
import LawyerRequest from './steps/LawyerRequest.vue';
import CallSidebar from './CallSidebar';
import ChatSidebar from './ChatSidebar';
import DetentionSearch from '../autozaki/DetentionSearch';
import SmsModal from './SmsModal';
import AudioModal from './AudioModal';
import AutozakId from '../autozaki/AutozakId';
import PoliceSearch from '../autozaki/PoliceSearch';
import {mapActions, mapGetters, mapState} from 'vuex';
import dayjs from 'dayjs';
import Loader from '../ui/Loader';
import debounce from 'lodash/debounce';

export default {
  name: 'Card-Transition',
  props: ['session_type', 'states', 'initialPath'],
  components: {
    ClientPack,
    CallerName,
    WantSpecificPerson,
    HaveCalled,
    IsDetained,
    DetainedName,
    NewClientPack,
    WhoToDetained,
    City,
    LawyerRequest,
    CallSidebar,
    ChatSidebar,
    AutozakId,
    DetentionSearch,
    PoliceSearch,
    SmsModal,
    AudioModal,
    Loader
  },
  data() {
    return {
      now: new Date(),
      result: this.getInitialResult(),
      stepResults: {},
      locked: false,
      openChatId: null,
      SessionId: null,
      errors_list: {},
      path: [this.initialPath],
      noAutozak: false,
      SmsModalShow: false,
      CallAudioModalShow: false,
      noKorostel: false,
      loading: false
    }
  },
  mounted() {

    if (this.$route.params.id) {
      this.locked = true;
      this.$emit('locked', this.locked);
      this.path[this.path.length - 1] = 'clientPack';
      this.setClientPackIdStore(this.$route.params.id);
    }

    if (this.session_type === 'chat') {
      this.initChatSession()
    }

    let entries = null;

    switch (this.session_type) {
    case 'call':
      entries = this.getCallSession(this.user, this.callSession?.call_id);
      this.setTitle({text: 'Текущий звонок'});
      if (!entries) {
        this.result.caller.phone_number = this.callSession?.phone_number;
        this.result.caller.full_name = this.callSession?.full_name
      }
      break;
    case 'chat':
      entries = this.telegram_id ? this.getChatSessionById(this.telegram_id) : this.getChatSession(this.user);
      this.setTitle({text: 'Текущий чат'});
      break;
    case 'freeEntry':
      entries = this.getFreeEntrySession(this.user);
      this.setTitle({text: 'Текущая анкета'});
      break;
    case 'autozaki':
      entries = this.getAutozakiSession(this.user);
      if (this.$route.params.id) {
        this.setTitle({text: `Автозак ${this.$route.params.id}`, url: `/avtozaki/${this.$route.params.id}`})
      } else {
        this.setTitle({text: 'Поиск'})
      }
      break;
    case 'detentions':
      this.setTitle({text: 'Поиск'});
      break;
    case 'police':
      this.setTitle({text: 'Поиск'});
      break;
    default:
      throw new Error('wrong session type')
    }

    if (this.$route.fullPath.match('newSession')) {
      let routeName = this.$route.name;
      if (routeName.substring(routeName.length - 1) === '2') {
        routeName = routeName.substring(0, routeName.length - 1)
      }
      let routeData = this.$router.resolve({name: routeName});
      this.saveCurrentSession();
      window.open(routeData.href, '_self');
    } else if (entries && !this.$route.params.id) {
      this.restoreSession(entries)
    }

  },
  beforeDestroy() {
    this.saveCurrentSession();

    this.$bus.$off('newSession', this.newSession);
    this.$bus.$off('newCall', this.newCall);
    this.$bus.$off('switchChat', this.openChat)

  },
  created() {
    let self = this;
    setInterval(function () {
      self.now = Date.now()
    }, 1000);
    this.$bus.$on('newSession', this.newSession);
    this.$bus.$on('newCall', this.newCall);
    this.$bus.$on('switchChat', this.openChat)
  },
  methods: {
    sendSMS() {
      this.SmsModalShow = true;
    },
    listenAudio() {
      this.CallAudioModalShow = true;
    },
    errorConnection(event) {
      if (this.session_type === 'autozaki') {
        this.locked = false;
        this.path = [this.initialPath];
        this.noAutozak = true;
        this.$emit('locked', this.locked);
      }
      throw new Error(event);
    },
    setClientPackId(clientPackId) {
      if (!this.result.data.client_pack) {
        this.result.data.client_pack = {};
      }
      this.$vueSet(this.result.data.client_pack, 'id', clientPackId);
      this.setClientPackIdStore(clientPackId);
    },
    errors(errors) {
      if(errors) {
        const error_groups = Object.entries(errors)
          .flatMap(item => item[1].map(error => ({ key:item[0], value:error })))
          .reduce((result, item) => ({ ...result, [item.value]: [...(result[item.value] || []), item.key ] }), {})
          
        for (let each of Object.keys(error_groups)) {
          this.errors_list[each] = error_groups[each];
          alert(`${each}: (${this.errors_list[each].join()})`);
        }
      }
      this.setDataSent(false);
    },
    ...mapActions(['saveSession', 'eraseSession', 'updateSession', 'setDataSent', 'setTitle'
      , 'setClientId', 'setClientPackIdStore', 'setTelegramId'
      , 'refreshTelegramId', 'refreshClientId', 'refreshClientPackId',
      'postApiSession', 'patchApiSession', 'getApiClientPackById', 'postApiClientPack',
      'getLastChatSession', 'postByUrl'
    ]),
    ...mapActions({ getClientPackByLatestSession: 'call/getClientPackByLatestSession'}),
    saveCurrentSession(close = false) {

      if (this.path.slice(-1)[0] === this.initialPath) {
        // throw new Error('no changes in session');
      }

      if (this.session_type === 'chat') {
        this.trySaveOrUpdateChatSession(close);
      } else if (this.session_type === 'freeEntry') {
        this.trySaveOrUpdateFreeEntrySession(close);
        let newSession = this.getSessionObject();
        this.saveSession(newSession);
      } else {
        let newSession = this.getSessionObject();
        this.saveSession(newSession);
      }
    },
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    openChat(chatId) {
      this.$vueSet(this, 'openChatId', chatId);
    },
    async chatUpdate(data){
      await this.chatUpdateWithDebounce(this, data);
    },
    async handleChatUpdate(data) {
      if (data.chatId) {
        if (!this.$route.params.id && this.telegram_id != data.chatId) {
          await this.trySaveOrUpdateChatSession();
          await this.tryRestoreChatSession(data.chatId)
        }
        this.$vueSet(this.result.telegram, 'id', data.chatId);
        await this.setTelegramId(data.chatId);
      }
    },
    chatUpdateWithDebounce: debounce(async (vm, data) => {
      vm.handleChatUpdate(data);
    }, 500),
    async updateClientPack(data, update, updateExistingPack = false) {
      let client = {};
      let haveCalled = {};
      let clientPack = {};
      let caller = {};
      let callerWhoToDetained = null;
      let clientId = null;


      if (data.detainedName?.client) {
        client = JSON.parse(JSON.stringify(data.detainedName?.client));
      } else if(data['detainedName:notSelf']?.client) {
        client = JSON.parse(JSON.stringify(data['detainedName:notSelf'].client));
        if(client) {
          client.clientPack = JSON.parse(JSON.stringify(data['detainedName:notSelf'])).clientPack.id
        }
      }
      
      clientId = client?.id;
      // if (clientId){
      //   }
      await this.setClientId(clientId);

      if (client && this.result.telegram?.id) {
        client.telegram_id = this.detainedTelegramId;
      }

      if (data.callerName) {
        caller = data.callerName;
        caller.telegram_id = this.informantTelegramId
      }

      if (data.whoToDetained) {
        callerWhoToDetained = data.whoToDetained;
      }

      haveCalled = data['detainedName:haveCalled']?.client;

      clientPack = data.newClientPack?.clientPack;

      this.result.data.detentions = [];

      if (client && this.result.data.client_pack.id && this.result.data.client_pack.id === client.clientPackId) {
        this.result.data.detentions.push({id: client.detentionId, client_json: client, lawyer_request_status: data.lawyerRequest?.id})
      } else if (client) {
        this.result.data.detentions.push({client_json: client, lawyer_request_status: data.lawyerRequest?.id})
      }

      if (haveCalled && this.result.data.client_pack.id && this.result.data.client_pack.id === haveCalled.clientPackId) {
        this.result.data.detentions.push({id: haveCalled.detentionId, client_json: haveCalled})
      } else if (haveCalled) {
        this.result.data.detentions.push({client_json: haveCalled})
      }

      for (let each in this.result.data.detentions) {
        if (this.result.data.detentions[each].city !== data.city?.city_id) {
          this.result.data.detentions[each].city = data.city?.city_id;
        }
        if (this.result.data.detentions[each].event !== clientPack?.event?.event_id) {
          this.result.data.detentions[each].event = clientPack?.event?.event_id;
        }

      }

      if (update || updateExistingPack) {

        for (let i = this.result.data.detentions.length; i < clientPack?.clients_count_reported; i++) {
          this.result.data.detentions.push({
            event: clientPack?.event?.event_id, city: data.city?.city_id,
            client_json: {is_unknown: true, phone_number_json: []}
          });
        }

        let r = await this.postApiClientPack(this.result.data).catch(e => {

          if (e.response.status === 400 || e.response.status === 800) {
            this.errors(e.response?.data?.detail_json || e);
          }
          throw new Error(e)
          // return false;
        });

        if (r.status === 200) {
          let res = await this.getApiClientPackById(r.data.id).catch(e => {

            if (e.response.status === 400 || e.response.status === 800) {
              this.errors(e.response?.data?.detail_json || e);
            }
            throw new Error(e)
            // return false;
          });

          if (res.status === 200) {
            this.$vueSet(this.result, 'data', res.data);
            if (this.result.data.detentions.length > 0) {
              client = this.result.data.detentions.find(d => d.client_json.full_name == client.full_name);

              if (client?.client) {
                clientId = client.client;
              }
            }
          } else return false;
        } else {
          return false;
        }
        // if (clientId) {
        // }
        await this.setClientId(clientId);
        await this.setClientPackIdStore(this.result.data.client_pack.id);
        //caller matrix
        if (!(Object.keys(caller).length === 0)) {
          if (!caller.id) {
            let res_caller = await this.postByUrl({url: 'client/', data: caller}).catch(e => {

              if (e.response.status === 400 || e.response.status === 800) {
                this.errors(e.response?.data?.detail_json || e);
              }
              throw new Error(e)
            });
            if (res_caller.status === 201) {
              caller = res_caller.data;
            }
          }

          if (!clientId && client && client.clientPack) {
            let res_clientpack = await this.getApiClientPackById(client.clientPack).catch(e => {
              if (e.response.status === 400 || e.response.status === 800) {
                this.errors(e.response?.data?.detail_json || e);
              }
              throw new Error(e)
            });
            if (res_clientpack.status === 200) {

              this.$vueSet(this.result, 'data', res_clientpack.data);

              clientId = this.result.data.detentions.find(a => a.client_json.full_name === client.full_name)?.client_json.id;
            }
          }

          if (!clientId) {
            let clientSaved = JSON.parse(JSON.stringify(this.stepResults['detainedName:notSelf'].client))
            let detentions = JSON.parse(JSON.stringify(this.result.data.detentions));
            clientId = detentions.find(a => a.client_json.full_name.toLowerCase() === clientSaved.full_name.toLowerCase())?.client;

          }
          if (clientId && caller.id) {
            let matchInformant = {};
            matchInformant.client = clientId;
            matchInformant.informant = caller.id;
            matchInformant.comment = callerWhoToDetained;

            await this.postByUrl({url: 'informant/', data: matchInformant}).catch(e => {

              if (e.response.status === 400 || e.response.status === 800) {
                this.errors(e.response?.data?.detail_json || e);
              } else if (e.response.status === 500) {
                this.errors({'matchInformantError': ['Error when creating an connection between caller and detained']})
              }
              return false;
            });
          } else {
            throw new Error('Запись в таблицу не внесена т.к. отсутствует ИД');
          }
        }
      }
      return true;
    },

    async nextStep(data) {
      this.loading = true;
      this.noAutozak = false;
      let allowChange = true;

      this.$set(this.stepResults, this.path[this.path.length - 1], data.result);

      if (data.existingClientPack) {
        this.stepResults.existingClientPack = true;
        this.setClientPackId(data.result.clientPack?.id);
      }

      console.log(this.session_type)
      if ((data.newClientPack || this.result.data.client_pack) && !['autozaki',  'detentions', 'police'].includes(this.session_type) ) {

        allowChange = await this.updateClientPack(this.stepResults
          , data.next === 'clientPack' && !data.existingClientPack
          , data.existingClientPack
        );
      } else if (this.session_type === 'autozaki') {
        this.result.data = {client_pack: {id: data.result?.id}};
      }

      if (data.haveCalled) {
        this.stepResults.haveCalled = true;
      }

      if (allowChange) {
        this.$set(this, 'path', [...this.path, data.next]);
        if (data.next === 'clientPack') {
          this.locked = true;
          this.$emit('locked', this.locked);
        } else if (data.next === 'noKorostel') {
          this.locked = true;
          this.noKorostel = true;
          this.$emit('locked', this.locked);
        }

        if (this.session_type === 'freeEntry') {
          this.trySaveOrUpdateFreeEntrySession();
        } else if (this.session_type === 'chat') {
          await this.trySaveOrUpdateChatSession()
        }

      } else {
        throw new Error('change is not allowed (update returned false)')
      }

      await this.setDataSent(false);
      this.loading = false;
    },
    resetToStep(step) {
      let newResults = {...this.stepResults};
      for (let i = step; i < this.path.length; i++) {
        delete newResults[this.path[i]]
      }
      this.$set(this, 'stepResults', newResults);
      this.$set(this, 'path', this.path.slice(0, step + 1));

      this.setDataSent(false);
      return step
    },
    getInitialResult() {
      return {
        dirty: false,
        id: null,
        caller: {
          first_name: '',
          patronymic: '',
          last_name: '',
          full_name: '',
          birth_date: null,
          phone_number_json: [],
        },
        telegram: {id: null},
        data: {client_pack: {}, detentions: []},

        user: {},
        contact: {
          phone_number: null
        }
      }
    },
    trySaveOrUpdateFreeEntrySession(close = false) {
      if (!this.SessionId) {
        this.saveFreeEntrySession();
      } else {
        this.updateFreeEntrySession(this.SessionId, close);
      }
    },
    async trySaveOrUpdateChatSession(close = false) {
      if (!this.result.telegram?.id)
        return;
      if(!this.SessionId){
        await this.saveChatSession();
      }
      try {
        let currentChatSession = this.getChatSessionById(this.result.telegram?.id);
        if (currentChatSession == null) {
          let newSession = this.getSessionObject(this.SessionId);
          await this.saveSession(newSession);
        } else if (currentChatSession) {
          let newSession = this.getSessionObject(currentChatSession.id);
          await this.updateSession(newSession);
          if (this.SessionId) {
            this.updateChatSession(this.SessionId, close);
          }
        }
      } catch (ex) {
        throw new Error(ex);
      }
    },
    async saveChatSession() {
      let sessionData = {
        session_type: 'chat',
        is_active: true,
        start_time: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
        user: this.user_id,
        client: this.client_id,
        client_pack: this.client_pack_id,
        telegram_id: this.telegram_id ?? this.result.telegram?.id,
      };

      try {
        let postSessionResponse = await this.postApiSession(sessionData);
        if(postSessionResponse.status === 201){
          this.SessionId = postSessionResponse.data.id
        }
      } catch (e) {
        throw new Error(`Unable to save chat session. ${e}`);
      }
    },
    saveFreeEntrySession() {
      let sessionData = {
        session_type: 'free_form',
        is_active: true,
        start_time: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
        user: this.user_id,
        client: this.client_id,
        client_pack: this.client_pack_id,
      };

      this.postApiSession(sessionData)
        .then(r => {
          if (r.status === 201) {
            this.SessionId = r.data.id;
          }
        })
        .catch(e => {
          throw new Error('Unable to save free entry session', e)
        })
    },
    updateChatSession(id = this.SessionId, close = false) {
      if (!id)
        return;

      let end_time;

      if (close) {
        end_time = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');
      }

      let sessionPatchData = {
        id: id,
        session_type: 'chat',
        is_active: !close,
        end_time: end_time,
        user: this.user_id,
        client: this.client_id,
        client_pack: this.client_pack_id,
        telegram_id: this.telegram_id ?? this.result.telegram?.id,
      };

      this.patchApiSession({id: id, data: sessionPatchData})
        .catch(e => {
          throw new Error('Unable to patch chat session', e);
        })
    },
    updateFreeEntrySession(id = this.SessionId, close = false) {
      if (!id)
        return;

      let end_time;

      if (close) {
        end_time = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');
      }

      let sessionPatchData = {
        id: id,
        session_type: 'free_form',
        is_active: !close,
        end_time: end_time,
        user: this.user_id,
        client: this.client_id,
        client_pack: this.client_pack_id,
      };

      this.patchApiSession({id: id, data: sessionPatchData})
        .catch(e => {
          throw new Error('Unable to patch free entry session', e);
        })
    },
    async tryRestoreChatSession(chatId) {
      try {
        let nextChatSession = this.getChatSessionById(chatId);
        if (nextChatSession) {
          this.restoreSession(nextChatSession, false);
          return
        }

        let lastChatSession = await this.getLastChatSession(chatId);
        if(lastChatSession) {
          this.SessionId = lastChatSession.sessionId;

          if(lastChatSession.clientPackId){
            this.initChatSession(lastChatSession.clientPackId);          
          } else {
            this.initSession(this.SessionId);
            this.setTitle({text: 'Текущий чат'});
          }
          await this.setClientId(lastChatSession.clientId);
          return
        }
      } catch (ex) {
        throw new Error(ex);
      }
      this.initSession()
    },
    initSession(sessionId = null) {
      this.noKorostel = false;
      this.locked = false;
      this.$emit('locked', this.locked);

      this.refreshClientPackId();
      this.refreshClientId();
      this.refreshTelegramId();

      this.path = [this.initialPath];
      this.stepResults = {};

      this.SessionId = sessionId;

      this.result = this.getInitialResult();
    },
    async restoreSession(session, switchToChat = true) {
      this.$vueSet(this, 'result', JSON.parse(session.result));
      this.$vueSet(this, 'stepResults', JSON.parse(session.stepResults));
      this.$vueSet(this, 'path', JSON.parse(session.path));
      this.$vueSet(this, 'locked', JSON.parse(session.locked));
      this.$emit('locked', this.locked);

      if (this.locked && this.path[this.path.length - 1] === 'noKorostel') {
        this.noKorostel = true;
        this.locked = true;
        this.$emit('locked', this.locked);
      }

      this.$vueSet(this, 'SessionId', session.id);

      this.setClientPackIdStore(this.result?.data?.client_pack?.id);

      const clientIdForSet = session.client_id ?? this.result?.data?.detentions
        .find(x => x.client_json.telegram_id === this.result?.telegram?.id)?.client_json?.id;
      // if (clientIdForSet) {
      // }
      await this.setClientId(clientIdForSet);
      this.setTelegramId(this.result?.telegram?.id);

      if (this.session_type === 'chat' && this.result?.telegram?.id && switchToChat) {
        setTimeout(() => (this.openChat(this.result?.telegram?.id)), 1000);
      }
    },
    getSessionObject(sessionId = this.SessionId) {
      return {
        user: this.user,
        session: this.session_type,
        id: sessionId,
        now: new Date(),
        result: JSON.stringify(this.result),
        stepResults: JSON.stringify(this.stepResults),
        path: JSON.stringify(this.path),
        locked: JSON.stringify(this.locked),
        call_id: this.callSession?.call_id,
        telegram_id: this.telegram_id ?? this.result.telegram?.id,
        client_id: this.client_id
      }
    },
    async newSession() {
      let telegramId = this.telegram_id;
      this.saveCurrentSession(true);
      this.initSession();
      if(this.session_type === 'chat' && telegramId){
        this.$vueSet(this.result.telegram, 'id', telegramId);
        await this.setTelegramId(telegramId);
      }
    },
    async newCall() {
      await this.initCallSession();
      this.result.caller.phone_number = this.callSession.phone_number;
      this.result.caller.full_name = this.callSession.full_name;
    },
    initChatSession(clientPackId = null) {
      clientPackId = clientPackId ?? this.$route.params.id ?? this.client_pack_id;
      if (clientPackId) {
        this.stepResults = {};
        this.result = this.getInitialResult();
        this.setClientPackId(clientPackId);
        this.locked = true;
        this.path[this.path.length - 1] = 'clientPack';
        this.$emit('locked', this.locked);
      }
    },
    async initCallSession() {

      this.loading = true
      let latestSessionClientPackId = await this.getClientPackByLatestSession();
      this.loading = false;

      if(!this.callSession.client_pack_id && !latestSessionClientPackId ){
        this.newSession();
        return;
      } 

      let clientPackId = this.callSession.client_pack_id ?? latestSessionClientPackId
      if(clientPackId) {
        this.setClientPackIdStore(clientPackId);
        this.setClientPackId(this.client_pack_id);
        this.locked = true
        this.path[this.path.length - 1] = 'clientPack';
        this.$emit('locked', this.locked);
      }
    }
  },
  computed: {
    ...mapGetters({
      getChatSession: 'getChatSession',
      getChatSessionById: 'getChatSessionById',
      getCallSession: 'getCallSession',
      getAutozakiSession: 'getAutozakiSession',
      getFreeEntrySession: 'getFreeEntrySession',
      user: 'stateUser',
      hasCall: 'call/hasCall',
      user_id: 'user_id',
      client_id: 'getClientId',
      client_pack_id: 'getClientPackId',
      telegram_id: 'getTelegramId',
    }),
    ...mapState({
      phoneState: state => state.call.phoneState,
      callSession: state => state.call.session,
      transferCall: state => state.call.transferCall,
      sipRegistred: state => state.call.registered
    }),
    clientPack() {
      return {}
    },
    sidebarData() {
      return {
        session: {
          name: this.callSession.call_id,
          time: this.callSession.call_started ? new Date(this.callSession.call_started).toLocaleString('ru-RU', {
            hour: 'numeric',
            minute: 'numeric'
          }) : null,
        },
        time: new Date((this.callSession.call_finished ? new Date(this.callSession.call_finished) : this.now) - new Date(this.callSession.call_started)).toLocaleString('ru-RU', {
          minute: 'numeric',
          second: 'numeric'
        }),
        phone: this.callSession.phone_number,
        person: this.callSession.full_name,
      }
    },
    detainedTelegramId(){
      return (this.stepResults?.isDetained == true ? this.result.telegram?.id  : null) ?? ''
    },
    informantTelegramId(){
      return (this.stepResults?.isDetained == false ? this.result.telegram?.id  : null) ?? ''
    }
  },

}
</script>

<style lang="less">
.transition {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;

  .cards {
    width: 100%;
    height: auto;
    overflow: scroll;
  }

  .sidebar-wrapper {
    position: sticky;
    top: 0;
    margin-left: auto;
  }
}

.no-calls {
  text-align: center;
  font-size: 1.8em;
  color: @green;
  font-weight: 500;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.red {
  color: @red;
}
</style>
