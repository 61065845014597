<template>
  <table>
    <thead class="table-sticky">
    <tr class="frozenRow">
      <th class="header__clientInfo sticky">
        <div v-if="selectedRows && data" class="toggleAll">
          <Checkbox :value="selectedRows.length === data.length" @input="toggleAll"/>
        </div>
        <div style="margin-left: auto; width: 100%">Задержанный</div>
      </th>
      <th></th>
      <th v-for="(column, key) in columns" :key="key">
        <span v-if="!column.hideTitle" @click="sort(key)">{{ column.title }}
        </span>
      </th>
    </tr>
    </thead >
    <tbody>
    <tr v-for="(item, index) in data" :key="item.id || item.tempId"
        :class="{
           ['selected']: selectedItems.some(i => i.id === item.id),
           ['highlighted']: item.client === client_id
         }">
      <td class="small sticky">
        <ClientInfoColumn :client="item" :rowNumber="index + 1" :selected="selectedItems.some(i => i.id === item.id)"
                          @change="change"
                          @select="selectRow"
        />
      </td>
      <td class="small">
        <ClientActionsColumn :client="item" @commentRemove="commentRemove" @comment="comment"
                             @showPhones="$emit('showPhones', item)"/>
      </td>
      <td
          v-for="(field, key) in columns"
          :key="key"
          :ref="isBulkEditBlocking(item, key) ? 'blocking' : 'normal'" :class="{
           ['small']: field.type === 'checkbox',
           [`bulkEdit__blocking`]: isBulkEditBlocking(item, key),
           [`bulkEdit__reference_${ bulkEditBlocked ? 'blocked' : 'editable' }`]: isBulkEditReference(item, key),
           [`bulkEdit__target_${ bulkEditBlocked ? 'blocked' : 'editable' }`]: isBulkEditTarget(item, key),
         }"
          v-on:click.capture="onClick(item, key, $event)"
          v-on:mousedown.capture="onClick(item, key, $event)"
      >
        <div v-if="bulkEditWidgetActive(item, key, field)" class="cell__widget">
          <button :data-tooltip="'Добавить всем'" class="circle-btn" data-tooltip-small @click="bulkEditWidgetAdd">
            <Icon name="close"/>
          </button>
        </div>

        <TextInput v-if="field.type === 'text'" :class="field.class"
                   :disabled="field.readonly" :mask="field.mask"
                   :separator="field.separator" :value="getPrefixValue(key, item, field)" :max="field.max"
                   table @blur="change(item, key, $event)"
                   :inputStyle="field.inputStyle"
                   :labelStyle="field.labelStyle"
                   :withCopy="field.withCopy"
        />
        <TextInput v-if="field.type === 'firstValue'" :disabled="field.readonly"
                   :mask="field.mask"
                   :value="getFirstValueFromArray(key, item)" table
                   @blur="change(item, key, $event)"/>

        <SelectNew
            v-if="(field.type === 'multiselect' || field.type === 'tags') && !(isBulkEditReference(item, key) && bulkEdit && bulkEdit.mode === 'add')"
            :disabled="field.readonly || (isBulkEditReference(item, key) && bulkEdit && bulkEdit.mode === 'add')"
            :idAsValue="true"
            :multiple="true"
            :name="`${key}_${item.id}`"
            :optionsList="field.optionsList"
            :lazyOptions="field.lazyOptions"
            :value="getValue(key, item) || []" table @blur="change(item, key, $event)"
            :appendToBody="false"
            :tableView="true"
            @input="setValue(key, $event, item)"/>

        <SelectNew v-if="isBulkEditReference(item, key) && bulkEdit.mode === 'add'"
                   :idAsValue="true"
                   :multiple="true"
                   :name="`${key}_${item.id}_secondary`"
                   :openByDefault="true"
                   :optionsList="field.optionsList"
                   :appendToBody="false"
                   :tableView="true"
                   :value="bulkEdit.multiAddValues" table @blur="multiAdd(key)" @input="bulkEdit.multiAddValues = $event"/>

        <DateTimeInput v-if="field.type === 'datetime'" :before-today="field.beforeToday"
                       :class="checkError(item, key) ? 'error' : ''" :disabled="field.readonly"
                       :format="field.format"
                       :timezone="getTimezone(item)" :value="getValue(key, item)"
                       table
                       @blur="change(item, key, $event)"/>

        <Checkbox v-if="field.type === 'checkbox'" :disabled="field.readonly"
                  :icon="field.icon"
                  :tooltip="field.tooltip" :value="getValue(key, item)" @input="change(item, key, $event)"/>
        <SelectNew v-if="field.type === 'selectAjax'" :disabled="field_disabled(key, field, item)"
                   :displayKey="field.displayKey" :extendedOptionSubTitle="field.extendedOptionSubTitle"
                   :extendedOptionTitle="field.extendedOptionTitle" :extendedView="field.extendedView" :idAsValue="true"
                   :name="`${key}_${item.id}`" :optionsList="field.url"
                   :placeholder="field.title" :value="getValue(key, item)"
                   v-bind:ajaxSearch="getQuery(field, item)"
                   v-bind:ajaxUrl="field.url"
                   :lazyOptions="field.lazyOptions"
                   :appendToBody="false"
                   :tableView="true"
                   @input="change(item, key, $event)"
        />
        <SelectNew v-if="field.type === 'select'" :disabled="field.readonly"
                   :idAsValue="true" :name="`${key}_${item.id}`"
                   :options="field.options" :optionsList="field.optionsList" :lazyOptions="field.lazyOptions"
                   :value="getValue(key, item)"
                   :appendToBody="false"
                   :tableView="true"
                   @input="change(item, key, $event)"
        />
        <LinkList v-if="field.type === 'linkList'" :type="field.url" :value="getArray(key, item)"/>
      </td>
    </tr>
    </tbody>

  </table>
</template>

<script>
import TextInput from '../ui/input/TextInput';
import Checkbox from '../ui/input/Checkbox';
import SelectNew from '../ui/input/SelectNew';
import DateTimeInput from '../ui/input/DateTimeInput';
import ClientActionsColumn from './ClientActionsColumn';
import LinkList from '../ui/LinkList';
import Icon from '../ui/Icon';
import ClientInfoColumn from './ClientInfoColumn';
import {mapGetters, mapActions} from 'vuex';
import uniqBy from 'lodash/uniqBy'

export default {
  name: 'Table',
  components: {
    ClientActionsColumn,
    ClientInfoColumn,
    TextInput,
    Checkbox,
    SelectNew,
    DateTimeInput,
    LinkList,
    Icon
  },
  props: ['columns', 'data', 'selectMode', 'errors', 'selectedRows', 'timezone', 'client_id'],
  data() {
    return {
      sortBy: null,
      sortOrder: 'asc',
      delayedFn: null,
      blurredFrom: null,
      id: this.randomId(),
      bulkEdit: {
        active: false,
        column: null,
        referenceItem: null,
        mode: 'init',
        multiAddValues: []
      },
      currentKey: ''
    }
  },
  watch: {
    selectedItems(val) {
      this.bulkEdit.active = val.length > 1;

      if (!this.bulkEdit?.active || (this.bulkEdit?.referenceItem && !val.some(i => i.id === this.bulkEdit?.referenceItem.id))) {
        this.resetBulkEdit()
      }
    },

    data(newValue, oldValue) {
      if (newValue && newValue.length && !(oldValue && oldValue.length)) {
        this.checkPoliceStation(true);
      } else if (this.currentKey === 'police_station') {
        this.checkPoliceStation(false);
      }
    }
  },
  computed: {
    ...mapGetters(['cityObj']),
    selectedItems() {
      if (!this.data || !this.selectedRows) {
        return []
      } else {
        return this.data.filter(i => this.selectedRows.some(r => i.id === r))
      }
    },
    bulkEditBlocked() {
      return this.bulkEditBlockingItems?.length > 0
    },
    bulkEditReferenceValue() {
      if (this.bulkEdit?.referenceItem && this.bulkEdit?.column)
        return this.getValue(this.bulkEdit.column, this.bulkEdit.referenceItem);

      return null
    },
    bulkEditBlockingItems() {
      return this.bulkEditWrongValueItems.concat(this.bulkEditDisabledItems)
    },
    bulkEditWrongValueItems() {
      if (this.bulkEdit.column && this.selectedItems?.length > 0) {
        let referenceValue = this.bulkEditReferenceValue;
        return this.selectedItems.filter(i => !this.areEqual(this.getValue(this.bulkEdit.column, i), referenceValue))
      }
      return []
    },
    bulkEditDisabledItems() {
      if (this.bulkEdit.column && this.selectedItems?.length > 0) {
        return this.selectedItems.filter(i => this.field_disabled(this.bulkEdit.column, this.columns[this.bulkEdit.column], i))
      }
      return []
    }
  },

  methods: {
    ...mapActions({
      getCity: 'getCity'
    }),
    getQuery(field, item) {
      if (field?.url === 'police-station' || field?.url === 'detention-center') {
        let cityId = this.getValue('city', item);
        if (cityId) {
          let cities = this.$store.state.dictionaries['city'].filter(x => x.id === cityId);
          if (cities.length > 0) {
            let city = cities[0];
            if (city?.related_city){
              return field.query + '&&city__related_city__id=' + city?.related_city;
            }
            else {
              return field.query;
            }
          }
        }
      }
      return field.query;
    },
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    getTimezone(item) {
      let cityId = this.getValue('city', item);
      if (cityId) {
        let city =  this.cityObj[cityId];
        if (city?.length > 0) {
          return city[0].time_zone
        }
        if(!city) {
          city = this.getCity(cityId);
        }
      }
      return this.timezone;
    },
    field_disabled(key, field, item) {

      if (field.readonly) return true;
      else if (field.eng_title === 'overnight_stay_police_station' &&
          !(this.getValue(key, item) || this.getValue('status', item) === 70 || this.getValue('status', item) === 80)) { //here hardcode for 'На ночь'
        return true
      }
      return false
    },
    getPrefixValue(key, item, field) {
      if (!field.prefix) {
        return this.getValue(key, item);
      } else {
        let value = this.getValue(key, item);
        if (value) {
          let prefix = '';
          if (field.prefix === 'bot') {
            prefix = process.env.VUE_APP_BOT_HOST;
          }
          value = prefix + '/' + field.separator + value;
        }
        return value;
      }
    },
    getValue(key, root = this.data) {

      key = key.replace(/\[(\d+)]/, '.$1');

      if (key === 'articles' && root['case_json']) {
        return root.case_json.map(a => a.article).filter(a => a);
      }

      return key.split('.').reduce((o, i) => o ? o[i] : o, root);
    },
    setValue(key, value, root = this.data) {

      if (key === 'articles') {
        root.case_json = value?.map(x => ({article: x}));
        return;
      }

      key = key.replace(/\[(\d+)]/, '.$1');
      let keys = key.split('.');
      let rootKeys = keys.slice(0, keys.length - 1);
      let leaf = rootKeys.reduce((o, i) => o ? o[i] : o, root);
      
      if(!value){
        leaf[keys[keys.length - 1]] = value
        return
      }

      if (value.id >= 0) {
        leaf[keys[keys.length - 1]] = value.id;
      } else if (value.length > 0 && value[0].id >= 0) {
        leaf[keys[keys.length - 1]] = value.map(a => a.id);
      } else {
        leaf[keys[keys.length - 1]] = value;
      }
    },
    addIfNotExistsValue(key, valueArray, root = this.data) {
      try {
        let destArray = this.getValue(key, root);
        valueArray.forEach(value => {
          if (destArray.indexOf(value) === -1)
            destArray.push(value)
        }
        );
        this.setValue(key, destArray, root)
      } catch (e) {
        throw new Error(e);
      }

    },
    selectRow(id) {
      this.$emit('select', id);
    },
    multiAdd(key) {
      this.selectedItems.forEach(i => this.addIfNotExistsValue(key, this.bulkEdit.multiAddValues, i));
      this.bulkEdit.multiAddValues = [];
      this.resetBulkEdit();
      this.$emit('blur');
    },
    change(item, key, event) {
      if (event?.id >= 0) {
        this.$emit('change', item, key, event.id);
      } else if (event?.length > 0 && event[0].id >= 0) {
        let ev = event.map(a => a.id);
        this.$emit('change', item, key, ev);
      } else {
        this.$emit('change', item, key, event);
      }

      if (this.bulkEdit?.active && this.selectedItems?.length > 0 && key === this.bulkEdit.column) {
        let referenceValue = this.bulkEditReferenceValue;
        this.selectedItems
          .filter(i => i.id !== this.bulkEdit.referenceItem.id)
          .forEach(i => this.setValue(key, referenceValue, i));
        this.resetBulkEdit()
      }
      this.$emit('blur');
      this.currentKey = key;
    },
    blur() {
      this.$emit('blur');
    },
    comment(comment){
      this.$emit('comment', comment);
    },
    commentRemove(id, item) {
      this.$emit('commentRemove', id, item);
    },
    getArray(key, root = this.data) {
      if (key === 'court-cases') {
        key = 'case_json';
        return key.split('.').reduce((o, i) => o ? o[i] : o, root) || [];
      }
      return key.split('.').reduce((o, i) => (o ? o[i] : o) || '', root) || [];
    },
    getFirstValueFromArray(key, root = this.data) {

      let t = key.split('.').reduce((o, i) => (o ? o[i] : o) || '', root) || [];

      let str = '';
      for (let i = 0; i < 1; i++) {
        str += t[i]?.phone_number;
      }
      return str;
    },
    checkError(item, key) {

      return Object.keys(this.errors).includes('detentions.' + item.id + '.' + key);
    },
    onClick(item, key, e) {
      // если для столбца не включено массовое редактирование - не перехватывает событие
      if (this.columns[key].bulkEdit !== true)
        return;

      // не перехватываем события внутри виджета
      if (this.isChildOf(e.target, 'cell__widget')) {
        return;
      }

      if (this.bulkEdit?.active) {

        if (this.bulkEdit?.mode === 'add')
          return;

        if (this.bulkEdit?.mode === 'selection'
            && item.id === this.bulkEdit.referenceItem?.id
            && key === this.bulkEdit.column) {

          if (this.bulkEditBlocked) {
            this.preventAll(e);
            this.$refs.blocking[0].scrollIntoView({behavior: 'smooth'});
            if (e.type === 'click')
              this.showBulkeditBlockedAlert()
          }
        } else {

          this.preventAll(e);

          if (e.type !== 'click')
            return;

          if (e.ctrlKey && key === this.bulkEdit.column) {
            this.$emit('select', item.id);
            return
          }

          document.activeElement.blur();

          if (this.selectedItems.some(i => i.id === item.id)) {
            this.bulkEdit.referenceItem = item;
            this.bulkEdit.column = key;
            this.bulkEdit.mode = 'selection';
            this.showBulkeditBlockedAlert()
          }
        }
      }
    },
    isBulkEditReference(item, key) {
      return this.bulkEdit
          && (this.bulkEdit?.mode === 'selection' || this.bulkEdit?.mode === 'add')
          && this.bulkEdit.referenceItem
          && this.bulkEdit.referenceItem.id === item.id
          && this.bulkEdit.column === key
    },
    isBulkEditTarget(item, key) {
      return !this.isBulkEditReference(item, key)
          && this.bulkEdit?.mode === 'selection'
          && this.bulkEdit?.column === key
          && this.selectedItems?.some(i => i.id === item.id)
    },
    isBulkEditBlocking(item, key) {
      return !this.isBulkEditReference(item, key)
          && this.bulkEdit?.mode === 'selection'
          && this.bulkEdit?.column === key
          && this.bulkEditBlockingItems.some(i => i.id === item.id)
    },
    toggleAll(val) {
      if (val) {
        let notSelected = this.data.filter(i => this.selectedItems.every(si => si.id !== i.id));
        notSelected.forEach(i => this.$emit('select', i.id))
      } else {
        let selected = this.data.filter(i => this.selectedItems.some(si => si.id === i.id));
        selected.forEach(i => this.$emit('select', i.id))
      }
    },
    resetBulkEdit() {
      this.bulkEdit.mode = 'init';
      this.bulkEdit.referenceItem = null;
      this.bulkEdit.column = ''
    },
    preventAll(e) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault()
    },
    showBulkeditBlockedAlert() {
      let wrongValueItemIndices = this.bulkEditWrongValueItems.map(item => this.data.findIndex(i => item.id === i.id) + 1);
      let disabledItemIndices = this.bulkEditDisabledItems.map(item => this.data.findIndex(i => item.id === i.id) + 1);

      if (disabledItemIndices.length > 0) {
        this.$notify(({
          type: 'warn',
          title: 'Массовое редактирование невозможно:',
          text: `Значения в строках ${disabledItemIndices.join(' ')} нельзя редактировать`
        }))
      } 
      if (wrongValueItemIndices.length > 0) {
        this.$notify(({
          type: 'warn',
          title: 'Массовое редактирование невозможно:',
          text: `Значение в строке ${wrongValueItemIndices.join(' ')} отличается от остальных`
        }))
      }
    },
    areEqual(first, second) {
      if (first == null && second == null) {
        return true
      }

      if (this.isDate(first) && this.isDate(second)) {
        let minutes = 1000 * 60;
        let firstTimeMinutes = Math.floor(new Date(first).getTime() / minutes);
        let secondTimeMinutes = Math.floor(new Date(second).getTime() / minutes);
        return Math.abs(firstTimeMinutes - secondTimeMinutes) < 60
      } else if (Array.isArray(first) && Array.isArray(second)) {
        return this.areArraysEqual(first, second)
      } else {
        return first === second
      }
    },
    areArraysEqual(inFirst, inSecond) {

      let first = [...inFirst].sort();
      let second = [...inSecond].sort();

      // if the other array is a falsy value, return
      if (!first || !second)
        return !first === !second;

      // compare lengths - can save a lot of time
      if (first.length !== second.length)
        return false;

      for (let i = 0, l = first.length; i < l; i++) {
        // Check if we have nested arrays
        if (first[i] instanceof Array && second[i] instanceof Array) {
          // recurse into the nested arrays
          if (!first[i].equals(second[i]))
            return false;
        } else if (first[i] !== second[i]) {
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false;
        }
      }
      return true;
    },
    isDate(date) {
      return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date)) && date.length > 4
    },
    isChildOf(el, className) {
      return (el && (el.className === className || (el.parentElement && this.isChildOf(el.parentElement, className)))) === true
    },
    bulkEditWidgetActive(item, key, field) {
      return this.bulkEditBlocked && this.isBulkEditReference(item, key) && (field.type === 'multiselect' || field.type === 'tags') && field.bulkEditMultiAdd === true
    },
    bulkEditWidgetAdd() {
      this.bulkEdit.mode !== 'add' ? this.bulkEdit.mode = 'add' : this.bulkEdit.mode = 'selection'
    },

    checkPoliceStation(isFirstLoad) {
      if (this.data) {
        const onlyPoliceStation = this.data.filter(item => item.police_station);
        const uniqPoliceStation = uniqBy(onlyPoliceStation, 'police_station');
        
        if (uniqPoliceStation.length > 1) {
          this.$notify(({
            type: 'error',
            title: 'Внимание!',
            text: isFirstLoad ? 'Здесь есть задержания с разными отделами полиции' : 'Здесь есть разные отделы полиции. Может быть, надо пересадить людей?',
            duration: 3000
          }))

          if (isFirstLoad) {
            this.$emit('openOfficeTab');
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.table-sticky {
  position: sticky;
  //top: 117px;
  background: white;
  z-index: 9999;
  text-align: left;
}
.bulkEdit {

  @reference-opacity: 100;
  @target-opacity: 40;

  @blocked-color: #EE9A6A; //#EF9E3E;
  @editable-color: #67C1BC;
  @blocking-color: red; //#A880E9;

  .boxShadow(@color, @opacity, @blur: 5px, @spread: 0px, @inset: true ) {
    box-shadow: if(@inset, inset 0px 0px @blur @spread fade(@color, @opacity), 0px 0px @blur @spread fade(@color, @opacity));
  }

  &__target {
    &_editable {
      .boxShadow(@editable-color, @target-opacity)
    }
  }

  &__reference {
    &_editable, &_blocked {
      .boxShadow(@editable-color, @reference-opacity, @inset: false)
    }
  }

  &__blocking {
    .boxShadow(@blocking-color, @target-opacity, 2px, 1px, false);
  }

}

table {
  border-spacing: 0;
  width: 100%;

  th {
    color: @middle-grey;
    font-size: @middle-font;
    font-weight: 600;
    padding: 18px 20px;
    white-space: nowrap;

    &.header__clientInfo {
      display: flex;
      flex-direction: row;
      text-align: center;
      .toggleAll {
        padding-left: 13px
      }
    }
  }

  th, td {
    position: relative;

    &.center {
      text-align: center;
    }

    &.sticky {
      position: sticky;
      left: 0;
      background-color: inherit;
      z-index: 1001;
    }
  }

  tr {
    --background: @bg-lighter;
    background-color: var(--background);
    height: 43px;
  }

  tr:nth-of-type(2n) {
    --background: #FAFAFA;
  }

  tbody tr:hover, tr.selected {
    --background: #F0F6F6;
  }

  td {
    padding: 2px 2px;
    vertical-align: middle;

    &.small {
      padding: 2px 2px;
      vertical-align: middle;
      &:first-child {
        padding-left: 12px;
      }
    }

    &.number {
      font-size: .9em;
      color: @cold-grey;
    }

    &.nowrap {
      white-space: nowrap;
    }

    .cell__widget {
      display: flex;
      flex-direction: column;
      padding: 3px;
      position: absolute;
      top: 1px;
      right: -32px;
      border: solid 0;
      z-index: 1000;
      background-color: white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .09);

      button:not(:last-child) {
        margin-bottom: 5px;
      }

      button svg {
        transform: rotate(45deg);
      }
    }
  }

  /deep/ .client-info-name {
    /deep/ span {
      font-size: .8em;
      color: @green;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  .error {
    border: 2px solid red;
  }

  tbody tr.highlighted {
   background-color: #B8E0DE;
 }
}

.circle-btn {
  background: none;
  border: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .09);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @green;
  position: relative;
  cursor: pointer;
  background-color: #67C1BC;

  svg {
    height: 10px;
    width: auto;
    color: white;
  }
}

.frozen {
  position: fixed;
  top: 85px;
  z-index: 999999;
}
</style>
