<template>
  <div class="wrapper">
    <div class="tab">
      <span class="choice" :class="{ 'active' : tabChoice === 'call' }">
        <input id="call" v-model="tabChoice" type="radio" value="call" @change="changeTab">
        <label for="call">Звонки</label>
      </span>
      <span class="choice" :class="{ 'active' : tabChoice === 'chat' }">
        <input id="chat" v-model="tabChoice" type="radio" value="chat" @change="changeTab">
        <label for="chat">Чат</label>
      </span>
      <span class="choice" :class="{ 'active' : tabChoice === 'free_form' }">
        <input id="free_form" v-model="tabChoice" type="radio" value="free_form" @change="changeTab">
        <label for="free_form">Свободная анкета</label>
      </span>
      <span class="choice" :class="{ 'active' : tabChoice === 'all_calls' }">
        <input id="all_calls" v-model="tabChoice" type="radio" value="all_calls" @change="changeTab">
        <label for="all_calls">Все звонки</label>
      </span>
    </div>
    <Loader v-if="loading" />
    <InlineTable :columns="tableHeader" :data="results" highlight="session_type_ru" selectMode="sessions" :sessionMode="tabChoice"
      @reroute="reroute" @rerouteChat="rerouteChat"/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import InlineTable from '../components/ui/InlineTable';
import Loader from '../components/ui/Loader';

export default {
  name: 'sessions',
  components: {InlineTable, Loader},
  data() {
    return {
      tabChoice: 'call',
      tableHeader: {
        session_type_ru: 'Тип сессии',
        operator: 'Оператор',
        start_time: 'Начало сессии',
        end_time: 'Конец сессии',
        full_name: 'ФИО',
        phone_or_tg: 'Номер телефона / ТГ ид',
        audiolink: 'Ссылка на аудиозапись',
        clientPackId: 'Ссылка на автозак',
        police_department: 'ОВД',
        severity: 'Уровень жести'
      },
      results: [],
      data: {},
      loading: false
    }
  },

  mounted() {
    this.setTitle({text: 'Сессии'});
    this.getList();
  },

  methods: {
    ...mapActions(['setTitle', 'getSessions']),
    changeTab() {
      this.results = this.data[this.tabChoice];
    },
    formatTime(time) {
      if (!time) return '';
      let nd = new Date(time);
      return nd.toLocaleString();
    },
    reroute(id) {
      let routeData = this.$router.resolve({name: 'Autozaki2', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    rerouteChat(id, chatId) {
      let routeData = this.$router.resolve({name: 'Chat2', params: {id: id, chatId: chatId}});
      window.open(routeData.href, '_blank');
    },
    async getList() {
      this.loading = true;

      try {
        const currentData = await this.getSessions();

        this.data = Object.keys(currentData).reduce((acc, key) => {
          acc[key] = currentData[key].map(row => {
            return {
              session_type_ru: this.getSessionType(row.session_type),
              start_time: this.formatTime(row.start_time),
              end_time: this.formatTime(row.end_time),
              operator: row.user?.username,
              full_name: row.client?.full_name,
              phone_or_tg: row.call?.phone_number?.phone_number ?? row.telegram_id,
              audiolink: row.call?.id,
              clientPackId: row.client_pack?.id,
              police_department: row.client_pack?.police_department,
              highlight: row.session_closing_type === 2
            }
          });

          return acc;
        }, {});

        this.changeTab();
        this.loading = false;
      } catch (e) {
        this.$notify(({
          type: 'error',
          title: 'Произошла ошибка',
          text: 'Попробуйте еще раз'
        }));
        this.loading = false;
      }
    },

    getSessionType(type) {
      switch (type) {
      case 'call':
        return 'Звонок';
      case 'chat':
        return 'Чат';
      case 'free_form':
        return 'Свободная Анкета';
      default:
        return type;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  position: relative;
}

.tab {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  color: @cold-grey;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-weight: 500;
  font-size: .85em;
  position: sticky;
  top: 0;
  background-image: linear-gradient(to bottom, white 65%, transparent);
  z-index: 99;

  & > .choice {
  margin-left: 5px;
  display: flex;
  align-items: center;

    & > input {
      margin-right: 8px;
    }
  }

  &__selected{
    color: @green;
  }

  input {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    outline: none;
    border: 2px solid @middle-grey;
    min-width: 18px;
    margin: 4px;
  }

  input:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 2px auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: @green;
  }

  input[type="radio"]:checked {
    border-color:@green;
  }
}

.active {
  color: @green;
}
</style>
