<template>
  <div :class="['call-card', 'block', {active}]">
    <div class="call-card-number">
      <slot name="number">0</slot>
    </div>
    <div class="call-card-info">
      <div class="call-card-title">
        <slot name="title">title</slot>
      </div>
      <div v-show="active" class="call-card-inputs">
        <form class="call-card-form" @submit.prevent>
          <slot name="inputs"></slot>
        </form>
      </div>
      <div v-if="!active" class="call-card-outputs">
        <slot name="outputs"></slot>
        <div class="edit-icon" @click="editCard">
          <Icon name="edit"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../ui/Icon';

export default {
  name: 'CallCard',
  props: ['active'],
  methods: {
    editCard() {
      this.$parent.$emit('edit')
    }
  },
  components: {Icon}
}
</script>

<style lang="less">
.call-card {
  display: flex;
  margin: 30px 40px;
  max-width: 800px;

  &.block {
    padding: 0;
  }

  &-number {
    display: flex;
    justify-content: center;
    width: 56px;
    position: relative;
    color: @cold-grey;
    padding: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    border-radius: 20px 0 0 20px;

    .active & {
      color: @cold-grey-dark;
    }

    .active & {
      padding-left: 30px;
      background-image: linear-gradient(to right, @green, @green 10px, white 10px, white);
    }
  }

  &-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    border-left: 1.5px solid @warm-grey-light;
    padding: 20px 30px;
    max-width: calc(100% - 56px);

    .call-card-title {
      color: @cold-grey;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 10px;

      .active & {
        font-weight: 500;
        color: @cold-grey-dark;
      }
    }

    .call-card-inputs {
      display: flex;
      flex-direction: row;

      .call-card-form {
        width: 100%;

        & > div {
          margin-top: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;

          &.right {
            justify-content: flex-end;
          }

          &.space-between {
            justify-content: space-between;
          }

          & > *:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }

    .call-card-outputs {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: @cold-grey-dark;

      .edit-icon {
        width: 24px;
        height: 24px;
        background-color: fade(@cold-grey-light, 35%);
        border-radius: 50%;
        margin-left: 8px;
        color: @green;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 14px;
          height: 14px;
        }
      }

      .right {
        min-width: 60%;
        text-align: right;
      }
    }

    .call-card-post {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-result {
        text-align: left;
        width: 50%;

        &-error {
          color: @red;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          justify-content: center;
        }
      }

      &-action {
        width: 50%;
        text-align: right;
      }
    }
  }

  .textarea ~ * {
    align-self: flex-end;
  }
}

</style>
