import axios from 'axios';
import Vue from 'vue';
import router from '../../router/';
import store from '../../store'

const AUTH_TIMEOUT = process.env.VUE_APP_AUTH_TIMEOUT;
const HC_TIMEOUT = process.env.VUE_APP_HC_TIMEOUT;

function parseJwt(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function getTimeout(timeout) {
  let result = timeout;
  if (store.getters.access) {
    let jwt = parseJwt(store.getters.access);
    let exp = (jwt.exp * 1000) - Date.now();
    result = Math.round(exp * 0.8);
    result = Math.max(result, 0);
  }
  return result
}

const state = {
  user: null,
  refresh: null,
  access: null,
  full_name: null,
  user_id: null,
  loginType: null,
  photo: null,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  stateUser: (state) => state.user,
  refresh: (state) => state.refresh,
  access: (state) => state.access,
  full_name: (state) => state.full_name,
  user_id: (state) => state.user_id,
  getloginType: (state) => state.loginType,
  getPhoto: (state) => state.photo,
};

let refreshCounter = 0;
const actions = {
  async logIn({commit, dispatch}, user) {
    await axios.post('token/', user).then(
      response => {
        let data = response.data;

        commit('setLoginTokens', data);
        commit('setLoginType', 'default');
        dispatch('autoRefresh');
        dispatch('autoHealthCheck' , 0);
      }
    );
  },
  async logInTelegram({commit, dispatch}, user) {
    user.code = user.id;
    await Vue.prototype.$api_telegram.post('/', user, {
      crossDomain: true,
      headers: {
        'TELEGRAM-ID': user.id
      },
    }
    ).then(
      response => {
        let data = response.data;

        commit('setPhoto', user);
        commit('setLoginTokens', data);
        commit('setLoginType', 'telegram');
        dispatch('autoRefresh');
        dispatch('autoHealthCheck', 0);
      }
    );
  },
  async refreshDefault({commit, dispatch}) {
    if (this.getters.isAuthenticated) {
      // eslint-disable-next-line no-console
      await axios.post('token/refresh', {'refresh': this.getters.refresh})
        .then(
          response => {
            refreshCounter = 0;
            if (response.data['access']) {
              commit('setRefreshTokens', response.data);
              dispatch('autoRefresh');
            } else {
              dispatch('autoRefresh', getTimeout(AUTH_TIMEOUT));
            }
          },
          error => {
            if (error?.response?.status === 401) {
              dispatch('logOut');
            } else {
              if (++refreshCounter >= 5) {
                alert('Ошибка авторизации');
                refreshCounter = 0;
                dispatch('logOut');
                throw new Error(error)
              } else {
                dispatch('autoRefresh', getTimeout(AUTH_TIMEOUT));
              }
            }
          }
        ).catch(
          () => {
            dispatch('logOut');
          }
        )
      ;
    }
    else {
      dispatch('logOut');
    }
  },
  async healthCheck({dispatch}) {
    if (this.getters.isAuthenticated) {
      await Vue.prototype.$api_base.get('health-check/').then(
        response => {
          this.dispatch('toggleInternetDown', false);
          if (response.data.toLowerCase() !== 'ok') {
            this.dispatch('toggleHealthOk', false);
          }
          else {
            this.dispatch('toggleHealthOk', true);
          }
        }
      ).catch(error => {
        if (!error.response) {
          this.dispatch('toggleInternetDown', true);
        }  else {
          this.dispatch('toggleInternetDown', false);
        }
        this.dispatch('toggleHealthOk', false);
      });
      dispatch('autoHealthCheck');
    }
  },
  async logOut({commit}) {
    commit('logout');
    commit('setTitle', {text: null, url: null});

    if (location.pathname !== '/login') {
      await router.push('/login');
    }
  },
  async autoHealthCheck({dispatch}, timeout = HC_TIMEOUT) {
    setTimeout(() => (dispatch('healthCheck')), timeout);
  },
  async autoRefresh({dispatch}, timeout = AUTH_TIMEOUT) {
    dispatch('resetOldSessions');
    setTimeout(() => (dispatch('refreshDefault')), getTimeout(timeout));
  },
};

const mutations = {
  setLoginType(state, type) {
    state.loginType = type;
  },

  setLoginTokens(state, response) {
    state.user = response['username'] || response['user_name'];
    state.full_name = response['user_full_name'] &&
    response['user_full_name'] !== '' ? response['user_full_name'] : response['username'];
    state.user_id = response['id'] ? response['id'] : response['user_id'];
    state.refresh = response['refresh'];
    state.access = response['access'];
  },
  setRefreshTokens(state, response) {
    state.access = response['access'] || state.access;
    state.refresh = response['refresh'] || state.refresh;
  },
  logout(state) {
    state.user = null;
    state.full_name = null;
    state.user_id = null;
    state.access = null;
    state.refresh = null;
    state.photo = null;
    delete Vue.prototype.$api.defaults.headers.common['Authorization'];
    delete Vue.prototype.$api_sms.defaults.headers.common['Authorization'];
  },

  setPhoto(state, user) {
    state.photo = user['photo_url'];
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
