<template>
  <CallCard :active="data.active">
    <template #number>{{ data.number }}</template>
    <template #title>{{ data.stepData.detainedPerson === 'self' ? 'Как вас зовут?' : 'Как зовут задержанного?' }}
    </template>
    <template #inputs>
      <div>
        <TextInput id="DetainedName_full_name" v-model="result.full_name" :disabled="result.noInfoOnDetained"
                   name="full_name" placeholder="ФИО"/>

        <PhoneInput :disabled="result.noInfoOnDetained" :value="result.primary_phone_number" @blur="savePrimaryPhoneNumber"/>


        <Button :disabled="(!result.full_name) || result.noInfoOnDetained || similar.state ==='loading'" :nosubmit="false" :type="similar.state==='loading'?'loading':'primary'"
                square @click="getSimilar">
          <Icon name="search"/>
        </Button>
      </div>
      <div v-if="phoneInvalid" class="not-found">
        Введите корректный номер телефона, чтобы он был сохранен.
      </div>
      <div>
        <Checkbox v-model="result.noInfoOnDetained">Нет информации</Checkbox>
        <span class="call-card-title subtitle">Дата рождения:</span>
        <DateTimeInput :before-today="true" :disabled="result.noInfoOnDetained"
                       :value="result.birth_date" class="date-input" tooltip="Введите дату рождения"
                       @blur="addBirthDate"/>
      </div>

      <div v-if="result.noInfoOnDetained && !error" class="right">
        <Button @click="newClientPack">Новый автозак</Button>
      </div>

      <div v-if="similar.ready && similar.state === 'not-found' && !result.noInfoOnDetained" class="space-between">
        <div class="not-found">
          <Icon name="search"/>
          {{ errorMessage }}
        </div>

        <Button v-if="!error" @click="newClientPack">Новый автозак</Button>
      </div>

      <div v-if="(similar.ready || similar.state === 'loading') && similar.results.length && !result.noInfoOnDetained">
        <InlineTable :columns="tableHeader" :data="similar.results" :loading="similar.state === 'loading'" select-mode="radio"
                     type="clients" @reroute="reroute" @select="selectSimilar"/>
      </div>

      <div v-if="(similar.ready || similar.state === 'loading') && similar.results.length && !result.noInfoOnDetained && !error"
           class="right">
        <Button v-if="data.stepData.detainedPerson !== 'self'" :disabled="similar.state === 'loading'"
                @click="newClientPack">
          Новый автозак
        </Button>
        <Button v-else :disabled="similar.state === 'loading'"
                @click="newClientPack">
          Новое задержание
        </Button>

        <Button :disabled="similar.state === 'loading' || !result.clientPackId" @click="existingClientPack">Перейти к
          автозаку
        </Button>
      </div>
    </template>
    <template #outputs>
      {{ result.noInfoOnDetained ? 'Нет информации' : displayFullName }}
    </template>
  </CallCard>
</template>

<script>
import CallCard from '../CallCard';
import TextInput from '../../ui/input/TextInput';
import Checkbox from '../../ui/input/Checkbox';
import Icon from '../../ui/Icon';
import Button from '../../ui/input/Button';
import InlineTable from '../../ui/InlineTable';
import PhoneInput from '../../ui/input/PhoneInput';
import DateTimeInput from '../../ui/input/DateTimeInput';
import {mapActions} from 'vuex';

export default {
  name: 'DetainedName',
  components: {InlineTable, TextInput, CallCard, Checkbox, Icon, Button, PhoneInput, DateTimeInput},
  props: ['data'],
  data() {
    return {
      similar: {
        state: 'idle',
        results: []
      },
      error: false,
      errorMessage: '',
      tableHeader: {
        clientPackId: 'Номер автозака',
        clientPackText: 'Задержание',
        name: 'ФИО',
        birth_date: 'Г.р.',
        phone: 'Телефон',
      },
      result: {
        id: null,
        full_name: null,
        phone_number_json: [],
        primary_phone_number: '',
        birth_date: null,

        noInfoOnDetained: false,
        similarId: null,
        clientPackId: null,
        detentionId: null,
      },
      resultsLimit: 15,
      phoneInvalid: false
    }
  },
  mounted() {
    if (this.data.stepData.detainedPerson === 'self') {
      this.result.full_name = this.data?.sessionData?.caller?.full_name;
      this.result.birth_date = this.data?.sessionData?.caller?.birth_date;
      this.result.primary_phone_number = this.data?.sessionData?.caller?.phone_number;
      if (this.result.primary_phone_number && this.result.primary_phone_number.length > 0) {
        this.addPhone(this.result.primary_phone_number)
      }
    }
  },
  methods: {
    ...mapActions(['getLastDetentionByFullName']),
    reroute(id) {
      let routeData = this.$router.resolve({name: 'Autozaki2', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    addBirthDate(birth_date) {
      this.result.birth_date = birth_date;
    },
    savePrimaryPhoneNumber(phone_number) {
      this.result.primary_phone_number = phone_number;
    },
    addPhone(phone) {
      if (phone !== 'INVALID' && phone !== '' && this.result.phone_number_json.filter(a => a.phone_number === phone).length === 0) {
        this.result.phone_number_json.push({phone_number: phone});
      }
      this.phoneInvalid = phone === 'INVALID';
    },
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    async getSimilar() {
      this.similar.state = 'loading';
      this.similar.ready = false;
      this.similar.results = [];

      let combined = await this.getLastDetentionByFullName(this.result.full_name).catch(e => {
        // TODO: вынести обработку ошибок тоже в store или даже в axios.interceptors
        if (e.response.status === 400 || e.response.status === 800) {
          this.$emit('errors', e.response.data.detail_json);
        }
        this.errorMessage = 'Ошибка: повторите попытку';
        this.error = true;
        this.similar.state = 'not-found';
        this.similar.ready = true;
      });
      this.error = false;

      let results = [];
      combined.sort(function (a, b) {
        return (!a.detention_json?.id) ? 1 : (!b.detention_json?.id ? -1 : (a.detention_json?.id - b.detention_json?.id))
      });
      const limitedCombined = combined.slice(0, this.resultsLimit);

      for (let i = 0; i < limitedCombined.length; i++) {
        limitedCombined[i].client_json.detentionId = limitedCombined[i]?.detention_json?.id;
        limitedCombined[i].client_json.clientPackId = limitedCombined[i]?.detention_json?.client_pack;
        if (limitedCombined[i]?.detention_json) {
          if (limitedCombined[i].detention_json?.client_pack) {
            limitedCombined[i].client_json.clientPackText = (limitedCombined[i]?.detention_json?.city_json?.name ?? '-') + ', ' +
                      new Date(Date.parse(limitedCombined[i]?.detention_json?.detention_time)).toLocaleDateString('ru-RU', {timeZone: 'Europe/Moscow'});


          }
        }
        results.push(limitedCombined[i].client_json);
      }

      this.similar.results = results;

      for (let each in this.similar.results) {
        this.similar.results[each].key = this.randomId();
      }

      if (this.similar.results.length !== 0) {
        this.similar.state = 'found';
        this.similar.ready = true;
      } else {
        this.similar.state = 'not-found';
        this.similar.ready = true;
        this.errorMessage = 'Упс, человека с таким именем в базе нет';
      }
    },
    newClientPack() {
      if (this.result.primary_phone_number && this.result.primary_phone_number.length > 0) {
        this.addPhone(this.result.primary_phone_number)
      }
      this.$emit('nextStep', {
        next: this.data.stepData.detainedPerson === 'self' ? 'haveCalled' : 'city',
        result: this.fullResult,
        newClientPack: true,
        haveCalled: this.data.stepData.detainedPerson === 'haveCalled'
      })
    },
    existingClientPack() {
      if (this.result.primary_phone_number && this.result.primary_phone_number.length > 0) {
        this.addPhone(this.result.primary_phone_number)
      }

      let isDetainedSelf = this.data.stepData.detainedPerson === 'self';
      this.$emit('nextStep', {
        next: isDetainedSelf ? 'clientPack' : 'lawyerRequest',
        result: this.fullResult,
        existingClientPack: isDetainedSelf
      });

    },
    selectSimilar(key) {

      if (key) {
        let client = this.similar.results.find(x => x.key === key);
        this.result.similarId = client.id;
        this.result.clientPackId = client.clientPackId;
        this.result.detentionId = client.detentionId;
      } else {
        this.result.similarId = null;
        this.result.clientPackId = null;
        this.result.detentionId = null;
      }

    },
  },
  computed: {

    displayFullName() {
      let displayName = this.fullResult.client?.full_name;

      if (!displayName) {
        if (this.data.stepData.detainedPerson === 'self') {
          if (this.data.stepResults?.detainedName?.client?.full_name) {
            displayName = this.data.stepResults?.detainedName?.client?.full_name
          }
        } else if (this.data.stepData.detainedPerson === 'previousCaller') {
          if (this.data.stepResults['detainedName:haveCalled'].client?.full_name) {
            displayName = this.data.stepResults['detainedName:haveCalled'].client?.full_name
          }
        } else if (this.data.stepData.detainedPerson === 'notSelf') {
          if (this.data.stepResults['detainedName:notSelf'].client?.full_name) {
            displayName = this.data.stepResults['detainedName:notSelf'].client?.full_name
          }
        }
      }

      return displayName
    },
    fullResult() {
      let result = {};

      if (this.result.similarId) {
        result.client = this.similar.results.find(x => x.id === this.result.similarId && x.clientPackId === this.result.clientPackId)
      } else if (this.result.noInfoOnDetained) {
        result.client = {full_name: ''}
      } else {
        result.client = {full_name: this.result.full_name, birth_date: this.result.birth_date};
      }

      if (result.client.phone_number_json) {
        result.client.phone_number_json = [...result.client.phone_number_json, ...this.result.phone_number_json];
      } else {
        result.client.phone_number_json = this.result.phone_number_json;
      }

      if (!result.clientPack) {
        result.clientPack = {};
      }

      if (this.result.clientPackId) {
        result.client.clientPack = this.result.clientPackId;
        result.clientPack.id = this.result.clientPackId;
      }
      if (this.result.detentionId) {
        result.client.detentionId = this.result.detentionId;
      }
      return result
    }
  }
}
</script>

<style lang="less">
.not-found {
  color: @red;
  font-size: .85em;

  svg {
    height: 1.3em;
    margin-right: .4em;
    vertical-align: bottom;
  }
}

.inputs > *:not(:last-child) {
  flex-basis: 0;
  flex-grow: 1;
}

#DetainedName_full_name {
  min-width: max-content;
}

.subtitle {
  margin: 0 15px !important;
}

.date-input {
  min-width: fit-content;
  flex: inherit !important;
}
</style>
