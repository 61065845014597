<template>
  <div ref="editableTable" @scroll="onScroll">
    <table>
    <tbody>
    <tr>
      <th class="header-info sticky">
        <div class="header-info__toggle">
          <Checkbox @input="toggleAll" :value="selectedRows.length === data.length"/>
        </div>
      </th>
      <th v-for="(column, key) in columns" :key="key">
                <span v-if="!column.hideTitle && !column.titleType" >{{ column.title }}
                </span>
                <span v-if="column.titleType === 'Icon'">
                  <Icon :name="column.title" :style="column.titleStyle"/>
                </span>
                <span v-if="getSortingDirection(key)" style="cursor: pointer;" @click="sort(key, column)">
                  <Icon name="triangle" :class="{['sort__asc']:getSortingDirection(key) === 'asc', ['sort__desc']:getSortingDirection(key) === 'desc'}"/>
                </span>
      </th>
    </tr>
    <tr v-for="(item, index) in data" :key="item.copied ? item.id : 0 - item.id" :class="{['selected']: selectedRows.some(i => i === item.id), copied: copiedRow.includes(index)}">
      <td class="row-info sticky">
        <span class="row-info__number">{{ index + 1 }}</span>
        <Checkbox @input="$emit('select', item.id)" :value="isSelectedItem(item)" />
      </td>
      <td v-for="(field, key) in columns" :key="key"
          v-on:click.capture="onClick(item, key, $event, field)"
          v-on:mousedown.capture="onClick(item, key, $event)"
          :ref="isBulkEditBlocking(item, key) ? 'blocking' : 'normal'"
          :class="{
                ['small']: field.type === 'checkbox',
                [`bulkEdit__blocking`]: isBulkEditBlocking(item, key),
                [`bulkEdit__reference_${ bulkEditBlocked ? 'blocked' : 'editable' }`]: isBulkEditReference(item, key),
                [`bulkEdit__target_${ bulkEditBlocked ? 'blocked' : 'editable' }`]: isBulkEditTarget(item, key),
                ['clickable']: field.clickEvent,
                }">
        <TextInput v-if="field.type === 'text'" :value="getValue(key, item)"
                   @blur="change(item, key, $event)" :mask="field.mask" :inputStyle="field.inputStyle"
                   table
                   :disabled="field.readonly"
        />
        <TextInput v-if="field.type === 'number'" :value="getValue(key, item)"
                   @blur="change(item, key, $event)" :mask="field.mask" :inputStyle="field.inputStyle"
                   table type="number"
                   :disabled="field.readonly"
        />
        <span v-if="field.type==='link'">
                    <a @click="reroute(getValue(key, item))">{{getValue(key, item)}}</a>
                </span>

        <SelectNew v-if="(field.type === 'multiselect' || field.type === 'tags')"
                   :optionsList="field.optionsList"
                   :value="getValue(key, item) || []" :disabled="field.readonly"
                   :name="`${key}_${item.id}`" table
                   @input="setValue(key, $event, item)"
                   @blur="change(item, key, $event)" :multiple="true" :idAsValue="true"/>

        <DateTimeInput v-if="field.type === 'datetime'" :value="getValue(key, item)"
                       @blur="change(item, key, $event)" :format="field.format"
                       :disabled="field.readonly" table
                       :before-today="field.beforeToday"/>

        <Checkbox v-if="field.type === 'checkbox'" :value="getValue(key, item)"
                  @input="change(item, key, $event)"
                  :disabled="field.readonly" :icon="field.icon" :tooltip="field.tooltip"/>
        <SelectNew v-if="field.type === 'selectAjax'" :value="getValue(key, item)"
                   @input="change(item, key, $event)" :disabled="field.readonly"
                   v-bind:ajaxUrl="field.url" v-bind:ajaxSearch="field.query" :displayKey="field.displayKey"
                   :placeholder="field.title" :idAsValue="true"
                   :name="`${key}_${item.id}`" :optionsList="field.url"
                   :extendedView="field.extendedView"
                   :extendedOptionTitle="field.extendedOptionTitle"
                   :tableView="true"
                   :extendedOptionSubTitle="field.extendedOptionSubTitle"
        />
        <SelectNew v-if="field.type === 'select'" :value="getValue(key, item)"
                   @input="change(item, key, $event)" :disabled="field.readonly"
                   :options="field.options" :idAsValue="true"
                   :optionsList="field.optionsList"
                   :tableView="true"
                   :name="`${key}_${item.id}`" :attention="Object.keys(field).includes('attention') && !getValue(field.attention, item)"
        />
        <LinkList v-if="field.type === 'linkList'" :value="getArray(key, item)" :type="field.url" />

      </td>
    </tr>
    </tbody>
  </table>
    <ViolenceCommentsModal v-if="showViolenceCommentsModal" :data="violenceCommentsModalData"
                           @close="showViolenceCommentsModal=false"/>

  </div>
</template>

<script>
import TextInput from './input/TextInput';
import Checkbox from './input/Checkbox';
import SelectNew from './input/SelectNew';
import DateTimeInput from './input/DateTimeInput';
import LinkList from './LinkList';
import Icon from './Icon';
import ViolenceCommentsModal from '../autozaki/ViolenceCommentsModal'

export default {
  name: 'EditableTable',
  components: {
    TextInput,
    Checkbox,
    SelectNew,
    DateTimeInput,
    LinkList,
    Icon,
    ViolenceCommentsModal,
  },
  props: ['columns', 'data', 'selectMode', 'errors', 'selectedRows', 'copiedRow', 'sortBy'],
  data() {
    return {
      bulkEdit: {
        active: false,
        column: null,
        referenceItem: null,
        mode: 'init',
        multiAddValues:[],

      },
      showViolenceCommentsModal: false,
      violenceCommentsModalData: null,
      sortByValue: null
    }
  },
  watch: {
    selectedItems(val) {
      this.bulkEdit.active = val.length > 1;
      if (!this.bulkEdit?.active || !val.some(i => i === this.bulkEdit?.referenceItem?.id)) {
        this.resetBulkEdit()
      }
    },
  },
  computed: {
    selectedItems() {
      if(!this.data || !this.selectedRows) {
        return []
      } else {
        return this.data.filter(i => this.selectedRows.some(r => i.id === r))
      }
    },
    bulkEditBlocked() {
      return this.bulkEditBlockingItems?.length > 0
    },
    bulkEditReferenceValue() {
      if (this.bulkEdit?.referenceItem && this.bulkEdit?.column)
        return this.getValue(this.bulkEdit.column, this.bulkEdit.referenceItem);

      return null
    },
    bulkEditBlockingItems(){
      if(this.bulkEdit.column && this.selectedItems?.length > 0){
        let referenceValue = this.bulkEditReferenceValue;
        return this.selectedItems.filter(i => !this.areEqual(this.getValue(this.bulkEdit.column, i), referenceValue))
      }
      return []
    }
  },
  methods: {
    showBulkeditBlockedAlert() {
      let blockingIndices = this.bulkEditBlockingItems.map(item => this.data.findIndex(i => item.id === i.id) + 1);
      if(blockingIndices.length > 1) {
        this.$notify(({ type: 'warn', title:'Массовое редактирование невозможно:', text: `Значения в строках ${blockingIndices.join(' ')} отличаются от остальных` }))
      } else if (blockingIndices.length > 0) {
        this.$notify(({ type: 'warn', title:'Массовое редактирование невозможно:', text: `Значение в строке ${blockingIndices.join(' ')} отличается от остальных` }))
      }
    },
    resetBulkEdit() {
      this.bulkEdit.mode = 'init';
      this.bulkEdit.referenceItem = null;
      this.bulkEdit.column = ''
    },
    areEqual(first, second) {
      if(first == null && second == null){
        return true
      }

      if(this.isDate(first) && this.isDate(second) ) {
        let minutes = 1000*60;
        let firstTimeMinutes = Math.floor(new Date(first).getTime() / minutes);
        let secondTimeMinutes = Math.floor(new Date(second).getTime() / minutes);
        return Math.abs(firstTimeMinutes - secondTimeMinutes) < 60
      } else if (Array.isArray(first) && Array.isArray(second)) {
        return this.areArraysEqual(first, second)
      } else if(first?.id || second?.id) {
        return first?.id === second?.id
      } else {
        return first === second
      }
    },
    areArraysEqual(inFirst, inSecond){

      let first = [...inFirst].sort();
      let second = [...inSecond].sort();

      // if the other array is a falsy value, return
      if (!first || !second)
        return !first === !second;

      // compare lengths - can save a lot of time
      if (first.length !== second.length)
        return false;

      for (var i = 0, l= first.length; i < l; i++) {
        // Check if we have nested arrays
        if (first[i] instanceof Array && second[i] instanceof Array) {
          // recurse into the nested arrays
          if (!first[i].equals(second[i]))
            return false;
        }
        else if (first[i] !== second[i]) {
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false;
        }
      }
      return true;
    },
    isDate(date){
      if(!date){
        return false
      }
      return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date)) && date?.length > 4
    },
    preventAll(e) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault()
    },
    isBulkEditReference(item, key) {
      return this.bulkEdit
                    && (this.bulkEdit?.mode === 'selection' || this.bulkEdit?.mode === 'add')
                    && this.bulkEdit.referenceItem
                    && this.bulkEdit.referenceItem.id === item.id
                    && this.bulkEdit.column === key
    },
    isBulkEditTarget(item, key) {
      return !this.isBulkEditReference(item, key)
                    && this.bulkEdit?.mode === 'selection'
                    && this.bulkEdit?.column === key
                    && this.selectedItems?.some(i => i.id === item.id)
    },
    isBulkEditBlocking(item, key) {
      return !this.isBulkEditReference(item, key)
                    && this.bulkEdit?.mode === 'selection'
                    && this.bulkEdit?.column === key
                    && this.bulkEditBlockingItems.some(i => i.id === item.id)
    },
    onClick(item, key, e, field) {
      // если для столбца не включено массовое редактирование - не перехватывает событие
      if (field && field?.clickEvent === 'comments'
              && this.getValue(key, item) && this.columns[key].bulkEdit !== true ) {
        this.showViolenceCommentsModal = true;
        this.violenceCommentsModalData = item;
        return;
      }

      if (this.columns[key].bulkEdit !== true)
        return;

      if (this.bulkEdit?.active) {

        if(this.bulkEdit?.mode === 'add')
          return;

        if (this.bulkEdit?.mode === 'selection'
                        && item.id === this.bulkEdit.referenceItem?.id
                        && key === this.bulkEdit.column) {

          if (this.bulkEditBlocked) {
            this.preventAll(e);
            this.$refs.blocking[0].scrollIntoView({behavior: 'smooth'});
            if (e.type === 'click')
              this.showBulkeditBlockedAlert()
          }
        } else {

          this.preventAll(e);

          if (e.type !== 'click')
            return;

          if (e.ctrlKey && key === this.bulkEdit.column) {
            this.$emit('select', item.id);
            return
          }

          document.activeElement.blur();

          if (this.selectedItems.some(i => i.id === item.id)) {
            this.bulkEdit.referenceItem = item;
            this.bulkEdit.column = key;
            this.bulkEdit.mode = 'selection';
            this.showBulkeditBlockedAlert()
          }
        }
      }

    },
    isSelectedItem(item){
      return this.selectedRows?.includes(item.id) ?? false
    },
    reroute(id){
      let routeData = this.$router.resolve({name: 'Autozaki2', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    getValue(key, root = this.data) {
      if (key === undefined) return;
      key = key.replace(/\[(\d+)]/, '.$1');

      if (key === 'articles' && root['case_json']) {
        // key = 'case_json';
        return root.case_json.map(a => a.article).filter(a => a);
      }

      return key.split('.').reduce((o, i) => o ? o[i] : o, root);
    },

    setValue(key, value, root = this.data) {

      if (key === 'articles') {
        root.case_json = value?.map(x => ({article: x}));
        return
      }

      key = key.replace(/\[(\d+)]/, '.$1');
      var keys = key.split('.');
      let rootKeys = keys.slice(0, keys.length - 1);
      let leaf = rootKeys.reduce((o, i) => o ? o[i] : o, root);

      if(!value){
        leaf[keys[keys.length - 1]] = value
        return
      }

      if (value.id >= 0) {
        leaf[keys[keys.length - 1]] = value.id;
      }
      else if (value.length > 0 && value[0].id >= 0) {
        leaf[keys[keys.length - 1]] = value.map(a => a.id);
      }
      else {
        leaf[keys[keys.length - 1]] = value;
      }
    },

    change(item, key, event) {

      this.emitChangeEvent(item, key, event);
      if (this.bulkEdit?.active && this.selectedItems?.length > 0 && key === this.bulkEdit.column) {
        let referenceValue = this.bulkEditReferenceValue;
        this.selectedItems
          .filter(i => i.id !== this.bulkEdit.referenceItem.id)
          .forEach(i => {
            this.setValue(key, referenceValue, i);
            //this.$emit('change', i, key, event.id);
            this.emitChangeEvent(i, key, event)
          })
        this.resetBulkEdit()
      }
      this.$emit('blur');
    },
    emitChangeEvent(item, key, event){
      if (event?.id >= 0) {
        this.$emit('change', item, key, event.id);
      }
      else if (event?.length > 0 && event[0].id >= 0) {
        let ev = event.map(a => a.id);
        this.$emit('change', item, key, ev);
      }
      else {
        this.$emit('change', item, key, event);
      }
    },
    getArray(key, root = this.data) {
      if (key === 'court-cases') {
        key = 'case_json';
        return key.split('.').reduce((o, i) => o ? o[i] : o, root) || [];
      }
      return key.split('.').reduce((o, i) => (o ? o[i] : o) || '', root) || [];
    },
    toggleAll(val){
      if (val) {
        let notSelected = this.data.filter(i => this.selectedRows.every(si => si !== i.id));
        notSelected.forEach(i => this.$emit('select', i.id))
      } else {
        let selected = this.data.filter(i => this.selectedRows.some(si => si === i.id));
        selected.forEach(i => this.$emit('select', i.id))
      }
    },
    sort(key, column){
      if(!column.sortable){
        return;
      }

      if(!this.sortByValue){
        this.sortByValue = `${key},asc`
      } else {
        let sortByValueSplitted = this.sortByValue.split(',')
        let direction = sortByValueSplitted[1] === 'asc' ? 'desc' : 'asc';
        this.sortByValue = `${key},${direction}`
      }
      this.$emit('sort', this.sortByValue);
    },
    onScroll(){
      let el = this.$refs.editableTable;
      if((el.offsetHeight + el.scrollTop) >= el.scrollHeight - 10) {
        this.$emit('scrolledToBottom');
      }
    },
    getSortingDirection(key){
      if(!this.sortByValue) {
        return null;
      }
      let sortBySplitted = this.sortByValue.split(',');
      let sortColumn = sortBySplitted[0];
      if(sortColumn !== key){
        return null;
      }
      return sortBySplitted[1];
    }
  },
  mounted () {
    const tableHeight = document.querySelector('.view').offsetHeight - 50;
    this.$refs.editableTable.style.maxHeight = tableHeight + 'px';
    this.$refs.editableTable.style.overflow = 'scroll';
    this.sortByValue = this.sortBy;
  }
}
</script>

<style lang="less" scoped>
    table tr:first-child {
      position: sticky;
      top: 0;
      z-index: 10;
    }
    .header-info{
        display: flex;
        flex-direction: row;
        &__toggle{
            padding-left: 21px
        }
    }
    .row-info {
        padding-right: 10px;
        display: flex;
        align-items: center;
        min-height: 32px;

        > :not(:last-child) {
            margin-right: 15px;
        }
        &__number{
            font-size: .85rem !important;
            color: @green !important;
            white-space: nowrap;
        }
    }
    .bulkEdit {

        @reference-opacity: 100;
        @target-opacity: 40;

        @blocked-color: #EE9A6A;//#EF9E3E;
        @editable-color: #67C1BC;
        @blocking-color: red;//#A880E9;

        .boxShadow(@color, @opacity, @blur: 5px, @spread: 0px, @inset: true ) {
            box-shadow: if(@inset, inset 0px 0px @blur @spread fade(@color, @opacity), 0px 0px @blur @spread fade(@color, @opacity));
        }

        &__target {
            &_editable {
                .boxShadow(@editable-color, @target-opacity)
            }
        }

        &__reference {
            &_editable, &_blocked {
                .boxShadow(@editable-color, @reference-opacity, @inset: false)
            }
        }

        &__blocking {
            .boxShadow(@blocking-color, @target-opacity, 2px, 1px, false);
        }

    }

    table {
        border-spacing: 0;
        width: 100%;
        padding: 5px 0;

        th {
            color: @cold-grey;
            font-size: .85em;
            font-weight: 600;
            padding: 18px 20px;
            white-space: nowrap;

            &.header__clientInfo {
                display: flex;
                flex-direction: row;

                .toggleAll {
                    padding-left: 13px
                }
            }
        }

        th, td {
            position: relative;
            &.center {
                text-align: center;
            }

            &.sticky {
                position: sticky;
                left: 0;
                background-color: inherit;
                z-index: 9;
            }
        }

        tr {
            --background: @bg-lighter;
            background-color: var(--background);
        }

        tr:nth-of-type(2n) {
            --background: #FAFAFA;
        }

        tbody tr:hover, tr.selected {
            --background: #F0F6F6;
        }

        td {
            padding: 15px 20px;
            vertical-align: top;

            &.small {
                padding: 15px 6px;

                &:first-child {
                    padding-left: 12px;
                }
            }

            &.number {
                font-size: .9em;
                color: @cold-grey;
            }

            &.nowrap {
                white-space: nowrap;
            }

            .cell__widget {
                display: flex;
                flex-direction: column;
                padding: 3px;
                position: absolute;
                top: 1px;
                right:-23px;
                border:solid 0;
                z-index:1000;
                background-color: white;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, .09);

                button:not(:last-child) {
                    margin-bottom: 5px;
                }

                button svg{
                    transform: rotate(45deg);
                }
            }
        }

        /deep/ .client-info-name {
            /deep/ span {
                font-size: .8em;
                color: @green;
                font-weight: 500;
                white-space: nowrap;
            }
        }

        .error {
            border: 2px solid red;
        }
    }

    .circle-btn {
        background: none;
        border: none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .09);
        border-radius: 50%;
        width: 16px;
        height: 16px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @green;
        position: relative;
        cursor: pointer;

        svg {
            height: 14px;
            width: auto;
        }
    }

  .clickable {
    cursor: pointer;
    font-weight: bold;
  }
  tr.copied {
    position: relative;

    &::before {
      content: 'СКОПИРОВАНО В БУФЕР';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: #67C1BC !important;
      color: white;
      z-index: 999;
      padding-left: 5em;
      padding-top: 1em;
    }
  }
  .sort {
    cursor: pointer;
    &__asc {
      transform: rotate(180deg);
    }
  }
</style>
