<template>
  <div class="login">
    <h3>Авторизация</h3>
    <form class="block" @submit.prevent="submit">
      <TextInput name="username" v-model="loginData.username" placeholder="Логин"/>
      <TextInput name="password" type="password" v-model="loginData.password" placeholder="Пароль"/>
      <Button type="submit">Войти</Button>
      <vue-telegram-login class="telegram"
                          mode="callback"
                          :telegram-login="telegram_bot_name"
                          @callback="onTelegramAuth"/>

    </form>
    <p v-if="showError" id="error">Не удалось авторизоваться!</p>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import TextInput from './components/ui/input/TextInput';
import Button from './components/ui/input/Button';
import {vueTelegramLogin} from 'vue-telegram-login'

let errorAlerts = function (error) {
  if (error?.response?.status === 403) {
    alert("Вы заблокированы, напишите в волонтёрский бот")
  }
  else {
    alert('Ой! Кажется, Коростель не видит, в какой роли вы будете на этом штабе! Напишите в волонтёрский бот — поправим');
  }
};

export default {
  name: 'Login',
  components: {Button, TextInput, vueTelegramLogin},
  data() {
    return {
      telegram_bot_name: process.env.VUE_APP_TELEGRAM_BOT_NAME,
      telegram_bot_redirect_url: process.env.VUE_APP_TELEGRAM_AUTH_ENDPOINT,
      loginData: {
        username: '',
        password: '',
      },
      showError: false
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
  },
  methods: {
    ...mapActions(['logIn', 'logInTelegram']),
    async onTelegramAuth(user) {
      try {
        await this.logInTelegram(user);
        this.showError = false;

        if (this.$route.path !== '/call') await this.$router.push('/call');
      } catch (error) {
        errorAlerts(error);
      }
    },
    async submit() {
      try {
        await this.logIn(this.loginData);
        this.showError = false;

        if (this.$route.path !== '/call') await this.$router.push('/call');
      } catch (error) {
        errorAlerts(error);
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="less">
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-width: 300px;
    margin: 0 auto;

    input {
      margin-bottom: 15px;
    }

    .telegram {
      margin: 10px 0;
    }
  }
}
</style>
