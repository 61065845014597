const state = {
  events: {}
};

let loading = [];
const actions = {
  async getEvent(ctx, id) {
    let event = null;
    if (!ctx.state.events[id] && !loading.includes(id)) {
      loading.push(id);
      event = await this._vm.$api.get('event/' + id + '/').catch(e => {
        throw new Error(e)
      }).finally(() => {
        let idIndex = loading.indexOf(id);
        if (idIndex !== -1) {
          loading.splice(idIndex, 1);
        }
      });
      ctx.commit('setEvent', event?.data);
      return event?.data;
    } else if (loading.includes(id)) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(ctx.dispatch('getEvent', id));
        }, 500);
      });
    } else {
      event = ctx.state.events[id]
    }
    return event
  }
};

const mutations = {
  setEvent(state, data) {
    state.events[data.id] = data
  }
};

export default {
  state,
  actions,
  mutations
};
