<template>
  <InlineTable :columns="tableHeader" :data="results" selectMode="obzvon"
               @giveup="giveup" @reroute="reroute"
               @take="take"/>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import InlineTable from '../components/ui/InlineTable';

export default {
  name: 'Obzvon',
  components: {InlineTable},
  data() {
    return {
      connection: null,
      host: process.env.VUE_APP_WSS_HOST + '/ws/call-queue/',
      tableHeader: {
        key: 'Номер автозака',
        city: 'Город',
        clients_count_auto: 'Число задержанных',
        status_label: 'Статус',
        last_update_before: 'Последнее обновление',
        max_severity: 'Уровень жести',
        time_zone_conv: 'Местное время',
        take: 'Взять в работу',
        //giveup: 'Отдать',
        responsible_user: 'Оператор'
      },
      results: []
    }
  },

  computed: {
    ...mapGetters({
      access: 'access',
      detention_status: 'detention_status'
    }),
    protocol() {
      return ['Bearer', this.access];
    },
    statuses() {
      let ans = this.detention_status;
      return ans.reduce((a, x) => ({...a, [x.id]: [x.id, x.name, x.name]}), {});
    },
  },
  methods: {
    ...mapActions(['setTitle']),
    localTime(offset) {
      let d = new Date();
      let localTime = d.getTime();
      let localOffset = d.getTimezoneOffset() * 60000;
      let utc = localTime + localOffset;
      let newLocalTime = utc + (3600000 * offset);
      let nd = new Date(newLocalTime);
      return nd.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    },
    timeBefore(delta) {
      let hours = parseInt(delta/3600);
      let minutes = parseInt((delta - 3600*hours)/60);
      let txt = minutes + ' мин назад';
      if (hours != 0) {
        txt = hours + ' ч ' + txt;
      }
      return txt;
    },
    reroute(id) {
      let routeData = this.$router.resolve({name: 'Autozaki2', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    giveup(id) {
      let request = {'type': 'take_from_me', 'client_pack_id': String(id)};
      this.connection.send(JSON.stringify(request));
    },
    take(id) {

      let request = {'type': 'take_on_me', 'client_pack_id': String(id)};

      this.connection.send(JSON.stringify(request));

      let routeData = this.$router.resolve({name: 'Autozaki2', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    updateList(data) {

      let res = JSON.parse(data);

      this.results = [];

      for (let each of res.order) {
        res.queue[each].key = each;
        res.queue[each].status_label = this.statuses[res.queue[each].status] ?
          this.statuses[res.queue[each].status][1] : res.queue[each].status;
        res.queue[each].time_zone_conv = this.localTime(res.queue[each].time_zone);
        res.queue[each].last_update_before = this.timeBefore(res.queue[each].seconds_from_last_update);
        this.results.push(res.queue[each]);
      }
    },
    socket() {
      if (this.connection) {
        this.connection.close();
      }

      // eslint-disable-next-line no-console
      console.log('Starting connection to WebSocket');

      this.connection = new WebSocket(this.host, this.protocol);

      this.connection.onerror = function (error) {
        throw new Error(error)
      };

      this.connection.onmessage = (event) => {
        this.updateList(event.data);
      };
    }
  },
  beforeDestroy() {
    if (this.connection) {
      this.connection.close();
    }
  },
  mounted() {
    this.setTitle({text: 'Обзвон'});
    setTimeout(() => {
      this.socket();
    }, 1000);
  }
}

</script>

<style>
a {
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}
</style>
