<template>
  <v-select :ref="name" :class="[{empty: !value||!value.length}, {table : tableView}]" :disabled="disabled" :label="displayKey"
            :multiple="multiple" :options="displayOptions" :placeholder="placeholder" :searchable="searchable"
            :value.sync="displayValue" :appendToBody="appendToBody" @input="emit"
            @search="fetchOptions"
  >
    <template #open-indicator>
      <Icon class="red" v-if="attention" name="shield-excl"/>
      <Icon v-else name="triangle"/>
    </template>
    <template #no-options>
      Начните вводить значение
    </template>
    <template v-if="this.extendedView" #option="option" :config="'this.extendedView'">
      <div>
        <div v-if="extendedOptionTitle" style="white-space: normal;">{{ option[extendedOptionTitle] }}</div>
        <div v-if="extendedOptionSubTitle" style="white-space: normal;">{{
            option[extendedOptionSubTitle]
          }}
        </div>
      </div>
    </template>
    <template #selected-option="item">
      <div :data-tooltip="item[displayKey]" :data-tooltip-position="tooltipPosition" class="vs__selected-container" > 
        <div class="vs__selected-item">{{ item[displayKey] }}</div>
      </div>
    </template>
  </v-select>
</template>

<script>
import Icon from '../Icon';
// import {createPopper} from '@popperjs/core'
import debounce from 'lodash/debounce'
import { mapActions } from 'vuex';

export default {
  name: 'SelectNew',
  components: {Icon},
  props: {
    value: null,
    options: {
      type: [Object, Array],
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Выбрать'
    },
    displayKey: {
      type: String,
      default: 'name'
    },
    ajaxSearch: String,
    ajaxUrl: String,
    ajaxHandle: {
      type: Function,
      default: raw => raw
    },

    multiple: Boolean,
    optionsList: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean
    },
    idAsValue: Boolean,
    extendedView: Boolean,
    extendedOptionTitle: String,
    extendedOptionSubTitle: String,
    name: String,
    openByDefault: Boolean,
    searchable: {
      type: Boolean,
      default: true
    },
    lazyOptions: Boolean,
    appendToBody: {
      type: Boolean,
      default: true
    },
    attention: Boolean,
    tableView: Boolean,
    tooltipPosition: {
      type: String,
      default: 'top'
    }
  },
  data() {
    return {
      ajaxDisplayValue: null,
      jurists: null,
      fetchedOptions: null,
      optionsLists: {
        relations: ['Друг', 'Родственник', 'Свидетель', 'Другое'],
        special_marks: [],
        violence: [],
        article: [],
        help_type_request: [],
        help_type_provision: [],
        city: [],
        event: [],
        police_station: [],
      },
      placement: 'bottom'
    }
  },
  methods: {
    ...mapActions(['getStation', 'getEvent', 'getCity', 'getByUrlWithId', 'getByUrl']),
    async fetchDisplayValue(val = null) {
      if (this.value && this.value[this.displayKey]) {
        this.ajaxDisplayValue = this.value;
        return;
      }
      if (val !== null && val[this.displayKey]) {
        this.ajaxDisplayValue = val;
        return;
      }

      let id = this.value?.id || null;
      if (this.value && Object.prototype.toString.call(this.value) === '[object Number]') {
        id = this.value;
      }


      if (id) {
        if (this.$store.state.dictionaries[this.optionsList]) {
          let temp = this.$store.state.dictionaries[this.optionsList].filter(x => x.id === id);
          if (temp.length > 0) {
            this.ajaxDisplayValue = temp[0];
            return;
          }
        }
        const ajaxUrls = {
          'police-station': this.getStation,
          'city': this.getCity,
          'event': this.getEvent
        };
        const t = this;
        if(!Object.keys(ajaxUrls).includes(this.ajaxUrl)) {
          this.getByUrlWithId({url: this.ajaxUrl, id: id})
            .then(r => {
              t.ajaxDisplayValue = r.data?.results || r.data;
            });
        } else {
          this.ajaxDisplayValue = await ajaxUrls[this.ajaxUrl](id);
        }
      }
    },
    fetchOptions(search, loading) {
      let requestUrl = this.ajaxSearch ? this.ajaxUrl + this.ajaxSearch : this.ajaxUrl;
      if ((/\d/.test(search) || search.length >= 2) && this.ajaxUrl) {
        if (requestUrl.includes('%l') && this.ajaxUrl === 'client' && this.$store.state.dictionaries['help_type_provision']) {
          requestUrl = requestUrl.replace('%l', this.$store.state.dictionaries['help_type_provision']);
        }
        loading(true);
        this.fetchWithDebonce(requestUrl, search, loading, this)
      } else if (!search.length && this.ajaxUrl) {
        this.fetchedOptions = this.options;
        this.adjustDropdown()
      }

      if (!this.ajaxUrl && search.length >= 2 && this.$store.state.dictionaries[this.optionsList]) {
        this.fetchedOptions = this.$store.state.dictionaries[this.optionsList].filter(opt => opt[this.displayKey] && opt[this.displayKey].toLowerCase().includes(search.toLowerCase()))
      } else {
        this.fetchedOptions = []
      }
    },
    fetchWithDebonce: debounce((requestUrl, search, loading, vm) => {
      vm.getByUrl(requestUrl.replace('%s', search)).then(r => {
        loading(false);
        vm.fetchedOptions = vm.ajaxHandle(r.data?.results || r.data)
      }).catch(() => {
        loading(false)
      })
    }, 500),
    emit(val) {
      if (this.idAsValue && Object.prototype.toString.call(this.value) === '[object Array]') {
        if (Object.prototype.toString.call(val) === '[object Array]') {
          val = val.map(v => v.id);
        } else {
          val = [val?.id]
        }
      }
      if (this.ajaxUrl) this.fetchDisplayValue(val);

      this.$emit('input', val);
      this.$emit('blur', val);
    },
    adjustDropdown() {
      if (this.extendedView) {
        this.$refs[this.name].calculatePosition(this.$refs[this.name].$refs.dropdownMenu, this.$refs[this.name], '250px')
      }
    }
  },
  computed: {
    displayOptions() {
      if (this.optionsList in this.$store.state.dictionaries && !this.lazyOptions)
        return this.$store.state.dictionaries[this.optionsList];
      return this.fetchedOptions || this.options
    },
    fullOptions(){
      if (this.optionsList in this.$store.state.dictionaries)
        return this.$store.state.dictionaries[this.optionsList];
      return this.fetchedOptions || this.options
    },
    displayValue() {
      let displayText = null;
      if (this.value === null) return displayText;

      if (this.idAsValue && Object.prototype.toString.call(this.value) === '[object Array]'){
        displayText = this.fullOptions.filter(i => this.value.includes(i?.id));
      } else if (this.idAsValue) {
        displayText = this.fullOptions?.find(i => i?.id === this.value)
      }

      if (!displayText || (!displayText[this.displayKey] && Object.prototype.toString.call(displayText) !== '[object Array]')) {
        if (this.value && this.value[this.displayKey]) {
          displayText = this.value;
        } else if (this.ajaxUrl) {
          displayText = this.ajaxDisplayValue;
        }
        if (this.ajaxUrl && ((!displayText) || (displayText && displayText.id !== this.value))) {
          this.fetchDisplayValue();
        }
      }

      return displayText
    }
  },
  mounted() {
    if (this.ajaxUrl) this.fetchDisplayValue();
    if (this.openByDefault === true) {
      this.$nextTick(() => {
        this.$refs[this.name].open = this.openByDefault
      });
    }
  },
  watch: {
    name(val) {
      this.$nextTick(() => {
        this.$refs[val].open = this.openByDefault
      });
    }
  }
}
</script>

<style lang="less" scoped>
@import '~vue-select/dist/vue-select.css';

.v-select {
  width: 250px;

  &.flex {
    width: 100%;
    flex: 1;
  }

  /deep/ .vs {
    &__dropdown-toggle {
      background-color: @bg-input;
      border: none;
      padding: 0;
      border-radius: 20px;
      width: 100%;
    }

    &__search, &__search:focus {
      padding: 10px 20px;
      margin: 0;
      line-height: initial;
      border: none;
      font-size: .9em;
      color: @cold-grey-dark;
      background: none;

      &::placeholder {
        color: @cold-grey;
        font-weight: 400;
        font-family: inherit;
      }
    }

    &__actions {
      padding: 10px 20px;
      color: @cold-grey;
    }

    &__clear {
      display: none;
    }

    &__selected {
      line-height: initial;
      font-size: .9em;
      margin: 0;
      padding: 10px 20px;
      color: @cold-grey-dark;
      white-space: nowrap;
      max-width: 100%;
      z-index: unset;

      &-options {
          max-width: calc(100% - 33px);
      }
       
      &-item {
          max-width: 100%; 
          overflow: hidden; 
          text-overflow: ellipsis;
      }

      &-container{
          max-width: 100%;
      }
    }

    &__spinner {
      width: 16px;
      height: 16px;
      border-width: 2px;
      margin-left: 10px;
    }
  }

  &.vs--open /deep/ .vs {
    &__dropdown-toggle {
      border-radius: 20px 20px 0 0;
      background-color: white;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
      height: 100%;
    }

    &__actions {
      display: none;
    }

    &__selected-options {
      overflow: hidden;
    }
  }

  &.vs--open.vs--single /deep/ .vs {
    &__selected {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.vs--multiple /deep/ .vs {
    &__selected {
      background: white;
      border: none;
      font-size: 0.75em;
      padding: 5px 10px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    &__deselect {
      background-color: #efefef;
      border-radius: 50%;
      padding: 2px;

      svg {
        transform: scale(.6);
      }
    }
  }

  &.drop-up /deep/ .vs {
    &__dropdown-toggle {
      border-radius: 0 0 20px 20px;
    }
  }

  &.vs--multiple /deep/ .vs__selected-options {
    padding: 10px 5px 5px 10px;
  }

  &.vs--multiple.empty /deep/ .vs__selected-options {
    padding: 0 2px;
  }

   &.table {

    &:not(.vs--open) {
      overflow: visible;
    }

    /deep/ .vs {
      &__dropdown-toggle {
        border: none;
        padding: 0;
        width: 100%;
        min-height: 30px;
      }

      &__search {
        padding: 0px 20px;
        font-size: @middle-font;
      }

      &__search:focus {
        padding: 0px 20px;
      }
      &__selected {
        line-height: initial;
        font-size: @middle-font;
        margin: 0;
        padding: 0;
        padding: 5px 5px 5px 20px;
        background: rgba(0,0,0,0);
        color: @cold-grey-dark;
        white-space: nowrap;
        overflow: visible;
        text-overflow: ellipsis;
        z-index: unset;
        max-width: 100%;

        &-options {
          max-width: calc(100% - 33px);
        }

        &-container{
          max-width: 100%;
        }

        &-item {
          max-width: 100%; 
          overflow: hidden; 
          text-overflow: ellipsis;
        }

       }

       &__deselect {
          background-color: rgba(0,0,0,0);
        }

       &__actions {
         padding: 0px 10px;
         color: @cold-grey;
       }

       &__dropdown-menu {
         border-radius: 0 0 20px 20px;
         border: none;
         border-top: 1px solid @warm-grey-light;
         box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
         padding: 0px;
         margin: 1px;
         &[data-popper-placement="top"] {
           border-radius: 20px 20px 0 0;
           box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.1);
         }
       }

       &__dropdown-option {
          padding: 5px 20px;
          color: @cold-grey-dark;
          font-size: .75em;
          line-height: initial;
          white-space: normal;
          word-break: break-word;
          &--highlight {
            background-color: fade(@warm-grey-light, 99%);
          }
        }
     }

      &.vs--open /deep/ .vs {
        &__selected {
          &-item {
            white-space: normal;
            word-break: break-word;
          }

          &-options {
            max-width: 100%;
          }

          &-container::before {
            visibility: hidden;
          }
        }
      }

      &.vs--multiple /deep/ .vs {
        &__selected {
          padding: 5px 0px;
          &-container{
            max-width: calc(100% - 15px);
          }
        }
      }
   }
}



.v-select:not(.vs--open):not(.vs--searching) /deep/ .vs__search:not(:placeholder-shown) {
  position: absolute;
}

.vs--disabled /deep/ div {
  background-color: inherit !important;
}

.vs--disabled /deep/ .vs__actions {
  display: none;
}

</style>

<style lang="less">
.dropdown-menu__extended-full {
  border-radius: 20px 20px 20px 20px !important;
}

.vs {
  &__dropdown-menu {
    border-radius: 0 0 20px 20px;
    border: none;
    border-top: 1px solid @warm-grey-light;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    padding: 0;

    &[data-popper-placement="top"] {
      border-radius: 20px 20px 0 0;
      box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.1);
    }
  }

  &__dropdown-option {
    padding: 10px 20px;
    color: @cold-grey-dark;
    font-size: .9em;
    line-height: initial;
    white-space: normal;
    word-break: break-word;
    &--highlight {
      background-color: fade(@warm-grey-light, 50%);
    }
  }

  &__no-options {
    font-size: .85em;
    padding: 10px 20px;
    text-align: start;
    color: @cold-grey;
  }
}
</style>
