<template>
  <Modal v-if="showModal">
    <div class="incoming-call">
      <div class="caller-avatar">
        <Icon class="caller-avatar-placeholder" name="person-fill"/>
      </div>

      <div class="caller-info">
        <div class="caller-phone">
          <div v-if="!isIncomingCall">{{ outgoingCallNumber }}</div>
          <div v-else>{{ sipInfo.phone_number }}</div>
          <div v-if="sipInfo.phone_number_id" class="verified-check">
            <Icon name="check"/>
          </div>
        </div>
        <div v-if="isIncomingCall" class="caller-type">Входящий звонок</div>
        <div v-else class="caller-type">Исходящий звонок</div>
      </div>
      <div v-if="isIncomingCall && this.phoneState === 'establishing'" class="call-establishing">Соединение...</div>
      <div class="call-actions">
        <button v-if="phoneState === 'incoming'" class="accept-call" @click="this.acceptCall">
          <Icon name="phone"/>
        </button>
        <button class="reject-call" @click="this.rejectCall">
          <Icon name="phone"/>
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../ui/Modal';
import Icon from '../ui/Icon';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import dayjs from 'dayjs'

export default {
  name: 'IncomingCall',
  components: {Icon, Modal},
  props: ['name'],
  data() {
    return {
      sipInfo: {},
      incomingAudio: new Audio(require('../../assets/sound/incoming.wav')),
      incomingSilentAudio: new Audio(require('../../assets/sound/incoming.wav')),
      outgoingAudio: new Audio(require('../../assets/sound/beeps.wav')),
      busyAudio: new Audio(require('../../assets/sound/busy.wav')),
      uncompleteSessionAudio: new Audio(require('../../assets/sound/uncomplete_session.mp3')),
      callEnd: new Audio(require('../../assets/sound/call_end.mp3')),
      isIncomingCall: true,
      uncompletedSessionAlertId: null,
      incomingNotification: null
    }
  },
  beforeDestroy() {
    console.log('IncomingCall: beforeDestroy');
    try {
      this.incomingAudio.pause();
      this.refreshClientId();
      this.refreshClientPackId();
    } catch (error) {
      throw new Error(error);
    }
  },
  mounted() {
    console.log('IncomingCall: mounted');
    this.setAudio();
    this.incomingAudio.loop = true;
    this.outgoingAudio.loop = true;
    this.incomingSilentAudio.loop = true;
    this.incomingSilentAudio.volume = 0.3;
  },
  computed: {
    ...mapState({
      phoneState: state => state.call.phoneState,
      sipSession: state => state.call.sipSession,
      outgoingCallInfo: state => state.call.outgoingCallInfo,
      callSession: state => state.call.session,
      callQueue: state => state.call.callQueue
    }),
    ...mapGetters({
      hasCall: 'call/hasCall',
      incomingAllowed: 'call/incomingAllowed',
      user_id: 'user_id',
      client_id: 'getClientId',
      client_pack_id: 'getClientPackId'
    }),
    showModal() {
      return (this.phoneState === 'incoming' || this.phoneState === 'calling' || this.phoneState === 'establishing' || this.phoneState === 'earlyMedia' || this.phoneState === 'busy')
    },
    outgoingCallNumber() {
      return this.outgoingCallInfo.phoneNumber
    },
    activateSilentIncoming() {
      return this.callQueue.length > 0 && this.phoneState === 'idle' && !this.incomingAllowed;
    },
    activateUncomleteSession() {
      return this.phoneState === 'idle' && this.hasCall;
    }
  },

  watch: {
    showModal(val) {
      if (!val) {
        this.incomingAudio.pause();
        this.outgoingAudio.pause();
      } else {
        this.setAudio()
      }
    },
    activateSilentIncoming(val) {
      if(!val){
        this.incomingSilentAudio.pause();
      } else {
        this.setAudio();
      }
    },
    activateUncomleteSession(val){
      if(val) {
        this.scheduleUncompletedSessionAlert(30*1000, 3*60*1000);
      } else {
        clearTimeout(this.uncompletedSessionAlertId);
      }
    },

    phoneState(val, oldval) {
      console.log(`IncomingCall: phoneState watcher starting: ${oldval} -> ${val}`);
      switch (val) {
      case 'call-in-progress':
        if (this.outgoingCallInfo) {
          console.log(`IncomingCall: phoneState watcher: it's an outgoing call`);
          this.setSession({
            call_id: this.outgoingCallInfo.callId,
            phone_number: this.outgoingCallInfo.phoneNumber,
            full_name: this.outgoingCallInfo.fullName,
            client_id: this.outgoingCallInfo.clientId,
            call_started: new Date(),
            call_finished: null
          })
        } else {
          console.log(`IncomingCall: phoneState watcher: incoming call in progress`);
          if (this.callSession) {
            this.updateCallSession(2);
          }
          this.setSession({
            call_id: this.sipInfo.call_id,
            phone_number: this.sipInfo.phone_number,
            full_name: this.sipInfo.client_full_name,
            client_id: this.sipInfo.client_id,
            call_started: new Date(),
            call_finished: null
          });
          this.$bus.$emit('newCall')
          console.log(`IncomingCall: phoneState watcher: incoming call session has been set`);
        }
        this.setCallStartedDate(new Date());
        this.setCallFinishedDate(null);
        this.saveCallSession();
        break;
      case 'incoming':
        const that = this;
        this.extractSessionInfo(this.sipSession);
        this.isIncomingCall = true;
        
        Notification.requestPermission().then(function(permission) {
          if (permission === "granted") {
            var notification = new Notification('Коростель', {
              body: 'Входящий звонок!',
              icon: 'https://korostel.one/favicon.ico',
              requireInteraction: true,
              vibrate: [200, 100, 200]
            });
            notification.onclick = async function() {
              console.log("Уведомление кликнуто: звонок принят");
              window.focus();
              that.incomingNotification = null;
              await that.acceptCall();
            };
            notification.onclose = async function() {
              console.log("Кликнута отмена звонка");
              that.incomingNotification = null;
              that.rejectCall();
            };
            that.incomingNotification = notification;
          }
        });
        break;
      case 'calling':
        this.isIncomingCall = false;
        break;
      case 'earlyMedia':
        this.incomingAudio.pause();
        this.outgoingAudio.pause();
        break;
      case 'busy':
        this.busyAudio.play();
        break;
      case 'idle':
        if (oldval === 'call-in-progress') {
          this.callEnd.play()
          this.setCallFinishedDate(new Date());
          this.updateCallSession(!this.callSession.transferTo ? 1 : 3);
        }
        break;
      default:
        break
      }
      console.log(`IncomingCall: phoneState watcher: ${oldval} -> ${val} finished`);
    }
  },
  methods: {
    ...mapActions({
      setSession: 'call/setSession',
      resetSession: 'call/resetSession',
      refreshClientId: 'refreshClientId',
      refreshClientPackId: 'refreshClientPackId'
    }),
    ...mapMutations({setCallStartedDate: 'call/setCallStartedDate', setCallFinishedDate: 'call/setCallFinishedDate'}),
    async acceptCall() {
      if (this.incomingNotification) {
        this.closeIncomingNotification();
      }
      if (this.$router.currentRoute.name !== 'Call')
        await this.$router.push('/call');
      await this.$sip.acceptCall();
    },
    scheduleUncompletedSessionAlert(timeout, nextTimeout){
      this.uncompletedSessionAlertId = setTimeout(() => {
        this.uncompleteSessionAudio.play();
        this.scheduleUncompletedSessionAlert(nextTimeout, nextTimeout)
      }, timeout);
    },
    closeIncomingNotification() {
      console.log("Closing incoming call notification")
      this.incomingNotification.onclose = null;
      this.incomingNotification.close();
      this.incomingNotification = null;
    },
    setAudio() {
      this.incomingAudio.pause();
      this.outgoingAudio.pause();
      this.incomingSilentAudio.pause();

      if (this.phoneState === 'incoming') {
        this.incomingAudio.play()
      } else if (this.phoneState === 'calling') {
        this.outgoingAudio.play()
      } else if(this.activateSilentIncoming) {
        this.incomingSilentAudio.play()
      }
    },
    rejectCall() {
      if (this.incomingNotification) {
        this.closeIncomingNotification();
      }
      this.$sip.endCall();
    },
    extractSessionInfo(session) {

      let sipInfoHeader = session.request.headers['X-Stats'];
      if (sipInfoHeader.length > 0) {
        let sipInfoString = sipInfoHeader[0].raw;
        let sipInfoObject = JSON.parse(sipInfoString);
        sipInfoObject.call_started = new Date();
        sipInfoObject.phone_number = (sipInfoObject.phone_number ?? sipInfoObject.current_call_phone_number);
        if (sipInfoObject.phone_number &&
            sipInfoObject.phone_number.length > 4 &&
            !sipInfoObject.phone_number.startsWith('+')) {
          sipInfoObject.phone_number = '+' + sipInfoObject.phone_number
        } else if (sipInfoObject.phone_number && sipInfoObject.phone_number.length === 4) {
          sipInfoObject.phone_number = '+7900000' + sipInfoObject.phone_number
        }
        sipInfoObject.client_first_name = this.unicodeToString(sipInfoObject.client_first_name);
        sipInfoObject.client_last_name = this.unicodeToString(sipInfoObject.client_last_name);
        sipInfoObject.client_full_name = this.unicodeToString(sipInfoObject.client_full_name);
        this.sipInfo = sipInfoObject
      } else {
        throw new Error('sipInfoHeader not found')
      }
    },
    unicodeToString(uStr) {
      if (!uStr)
        return uStr;
      return uStr.replace(/\\u[\dA-F]{4}/gi,
        function (match) {
          return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    },
    saveCallSession() {

      if (this.outgoingCallInfo?.isTest === true) {
        return
      }

      this.refreshClientId();
      this.refreshClientPackId();

      let sessionData = {
        session_type: 'call',
        is_active: true,
        start_time: dayjs(this.callSession.call_started)?.format('YYYY-MM-DDTHH:mm:ssZ'),
        call: this.callSession.call_id,
        user: this.user_id,
        client: this.sipInfo.client_id,
      };
      try {
        this.$api.post('session/', sessionData)
          .then(r => {
            if (r.status === 201) {
              this.callSession.id = r.data.id;
            }
          })
          .catch(e => {
            throw new Error('Unable to save call session', e)
          })

      } catch (error) {
        throw new Error(error);
      }

    },

    // closingType: 1 - normal, 2 - forced, 3 - transferred
    updateCallSession(closingType) {

      try {
        if (this.outgoingCallInfo?.isTest === true) {
          this.resetSession();
          return
        }
        if (!this.callSession.id)
          return;

        let sessionPatchData = {
          id: this.callSession.id,
          is_active: false,
          end_time: dayjs(this.callSession.call_ended)?.format('YYYY-MM-DDTHH:mm:ssZ'),
          client: this.sipInfo.client_id ? this.sipInfo.client_id : this.client_id,
          client_pack: this.client_pack_id,
          call: this.callSession.call_id,
          user: this.user_id,
          session_closing_type: closingType ?? 1 // normal is default
        };

        this.$api.patch(`session/${this.callSession.id}/`, sessionPatchData)
          .catch(e => {
            throw new Error('Unable to patch call session', e);
          })

      } catch (error) {
        throw new Error(error);
      }
    },
  },
}
</script>

<style lang="less" scoped>
.incoming-call {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  flex: 1;

  .caller {
    &-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: fade(@cold-grey, 65%);
      position: relative;

      &-placeholder {
        display: block;
        width: 40px;
        height: 40px;
        color: white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &-info {
      margin-top: 30px;
    }

    &-phone {
      font-size: 1.2em;
      font-weight: 500;
      display: flex;
      align-items: center;

      .verified-check {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: @green-light;
        color: white;
        margin-left: 5px;

        svg {
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
        }
      }
    }

    &-type {
      font-size: .65em;
      letter-spacing: .1em;
      color: @cold-grey;
      margin-top: 6px;
      text-transform: uppercase;
    }
  }

  .call-actions {
    margin-top: 50px;
    margin-bottom: 30px;

    button {
      display: inline-block;
      border: none;
      background: none;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      color: white;
      cursor: pointer;

      &.accept-call {
        background-color: @green;

        svg {
          transform: rotate(-130deg);
        }
      }

      &.reject-call {
        background-color: @red;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      svg {
        display: block;
        margin: auto;
      }
    }
  }

  .call-establishing {
    font-size: .80em;
    letter-spacing: .1em;
    color: @cold-grey;
    margin-top: 10px;
    text-transform: uppercase;
  }
}
</style>
