<template>
  <ul>
    <li v-for="v in value" :key="v.id">
      <a target="_blank" v-bind:href="type + v.id"> {{ displayValue(v) }}</a>
    </li>
  </ul>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'LinkList',
  props: ['value', 'type'],
  methods: {
    displayValue(elem) {
      if (this.type === '/court/') {
        return elem.article.name || this.articleObj[elem.article].name || elem.id
      }
      return 'text';
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      article: 'article',
      articleObj: 'articleObj',
    }),
  }
}
</script>
<style lang="less" scoped>
ul {
    list-style-type: circle;
    font-size: @middle-font;
  a {
      color: @green;
      text-underline-position: under;
    }
}
</style>
