<template>
    <CallCard :active="data.active">
        <template #number>{{ data.number }}</template>
        <template #title>В каком отделе полиции?
        </template>
        <template #inputs>
            <div>
                <SelectNew v-model="result.police_station" id="police_station"
                           displayKey="name" extendedOptionSubTitle="full_address" :disabled="result.noInfoOnDetained"
                           extendedOptionTitle="short_name" :extendedView="true" :idAsValue="true"
                           name="police_station" optionsList="police-station"
                           placeholder="Отдел полиции"
                           ajaxSearch="/?name__icontains=%s"
                           ajaxUrl="police-station"
                           :appendToBody="false"
                           style="width:100%"
                />
                <span></span>

                <Button :disabled="(!result.police_station) || result.noInfoOnDetained || similar.state ==='loading'" :nosubmit="false" :type="similar.state==='loading'?'loading':'primary'"
                        square @click="getSimilar">
                    <Icon name="search"/>
                </Button>
            </div>

            <div v-if="similar.ready && similar.state === 'not-found' && !result.noInfoOnDetained" class="space-between">
                <div class="not-found">
                    <Icon name="search"/>
                    {{ errorMessage }}
                </div>
            </div>

            <div>
                <Checkbox v-model="activeDetentions">Активные задержания</Checkbox>
            </div>

            <div v-if="(similar.ready || similar.state === 'loading') && similar.results.length && !result.noInfoOnDetained">
                <InlineTable :columns="tableHeader" :data="activeDetentions ?  filterActiveDetentions : similar.results"
                             :loading="similar.state === 'loading'" select-mode="radio"
                             type="clients" @reroute="reroute" @select="selectSimilar"/>
            </div>

            <div v-if="(similar.ready || similar.state === 'loading') && similar.results.length && !result.noInfoOnDetained && !error"
                 class="right">

                <Button :disabled="similar.state === 'loading' || !result.clientPackId" @click="existingClientPack">Перейти к
                    автозаку
                </Button>
            </div>
        </template>
        <template #outputs>
            {{ result.noInfoOnDetained ? 'Нет информации' : result.police_station }}
        </template>
    </CallCard>
</template>

<script>
  import CallCard from '../call/CallCard';
  import Checkbox from '../ui/input/Checkbox';
  import Icon from '../ui/Icon';
  import Button from '../ui/input/Button';
  import InlineTable from '../ui/InlineTable';
  import {mapActions} from 'vuex';
  import SelectNew from '../ui/input/SelectNew';

  const DAY_IN_MS = 1000 * 60 * 60 * 24;

  export default {
    name: 'PoliceSearch',
    components: {InlineTable, CallCard, Icon, Button, Checkbox, SelectNew},
    props: ['data'],
    data() {
      return {
        similar: {
          state: 'idle',
          results: []
        },
        error: false,
        errorMessage: '',
        tableHeader: {

          clientPackId: 'Автозак',
          full_name: 'ФИО',
          clientPackText: 'Задержание',
          event_name: 'Акция',
        },
        result: {
          id: null,
          police_station: null,

          noInfoOnDetained: false,
          similarId: null,
          clientPackId: null,
          detentionId: null,
        },
        resultsLimit: 20,
        activeDetentions: true,
      }
    },
    mounted() {
      this.result.full_name = this.data?.sessionData?.caller?.full_name;
      this.result.primary_phone_number = this.data?.sessionData?.caller?.phone_number;

      if (this.result.primary_phone_number && this.result.primary_phone_number.length > 0) {
        this.addPhone(this.result.primary_phone_number)
      }
    },
    methods: {
      ...mapActions(['getDetentionByPoliceStation']),
      reroute(id) {
        let routeData = this.$router.resolve({name: 'Autozaki2', params: {id: id}});
        window.open(routeData.href, '_blank');
      },
      randomId() {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
      },
      async getSimilar() {
        this.similar.state = 'loading';
        this.similar.ready = false;
        this.similar.results = [];

        this.error = false;
        let results = await this.getDetentionByPoliceStation(this.result.police_station.id).catch(e => {
          // TODO: вынести обработку ошибок тоже в store или даже в axios.interceptors
          if (e.response.status === 400 || e.response.status === 800) {
            this.$emit('errors', e.response.data.detail_json);
          }
          this.error = true;
          this.errorMessage = 'Ошибка: повторите попытку';
          this.similar.state = 'not-found';
          this.similar.ready = true;
        });
        for (let i = 0; i < results.length; i++) {
          results[i].full_name = results[i]?.client?.full_name;

          results[i].clientPackId = results[i]?.client_pack;

          results[i].clientPackText = (results[i].city ? results[i].city.name : '-') + ', ' +
            new Date(Date.parse(results[i].detention_time)).toLocaleDateString('ru-RU', {timeZone: 'Europe/Moscow'});


          results[i].event_name = results[i].event ? results[i].event.name : '-'
        }

        this.similar.results = results?.slice(0, this.resultsLimit);

        for (let each in this.similar.results) {
          this.similar.results[each].key = this.randomId();
        }

        if (this.similar.results.length !== 0) {
          this.similar.state = 'found';
          this.similar.ready = true;
        } else {
          this.similar.state = 'not-found';
          this.similar.ready = true;
          this.errorMessage = 'Упс, задержания с таким отделом в базе нет';
        }
      },
      existingClientPack() {
        this.$emit('nextStep', {
          next: 'clientPack',
          result: this.fullResult,
          existingClientPack: true
        });
      },
      selectSimilar(key) {
        if (key) {
          let client = this.similar.results.find(x => x.key === key);
          this.result.similarId = client.id;
          this.result.clientPackId = client.clientPackId;
          this.result.detentionId = client.detentionId;
        } else {
          this.result.similarId = null;
          this.result.clientPackId = null;
          this.result.detentionId = null;
        }

      }
    },
    computed: {
      filterActiveDetentions() {
        return this.similar.results.filter(a => {
          return a?.detention_time
            ? Math.abs(new Date(a.detention_time).getTime() - (new Date().getTime())) / DAY_IN_MS <= 2
            : false
        });
      },
      fullResult() {
        let result = {};

        if (this.result.similarId) {
          result.client = this.similar.results.find(x => x.id === this.result.similarId && x.clientPackId === this.result.clientPackId)
        } else if (this.result.noInfoOnDetained) {
          result.client = {full_name: ''}
        } else {
          result.client = {full_name: this.result.full_name};
        }

        if (result.client.phone_number_json) {
          result.client.phone_number_json = [...result.client.phone_number_json, ...this.result.phone_number_json];
        } else {
          result.client.phone_number_json = this.result.phone_number_json;
        }

        if (!result.clientPack) {
          result.clientPack = {};
        }

        if (this.result.clientPackId) {
          result.client.clientPack = this.result.clientPackId;
          result.clientPack.id = this.result.clientPackId;
        }
        if (this.result.detentionId) {
          result.client.detentionId = this.result.detentionId;
        }
        return result
      }
    }
  }
</script>

<style lang="less">
    .not-found {
        color: @red;
        font-size: .85em;

        svg {
            height: 1.3em;
            margin-right: .4em;
            vertical-align: bottom;
        }
    }
</style>
