<template>
  <div class="textarea">
    <div
      v-html="getLinkString(textModel)"
      role="textbox"
      contentEditable="true"
      class="fakeTextarea"
      :style="{'--placeholder': `'${placeholder}'`}"
      @blur="blur($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'TextareaEditable',
  props: {
    value: String,
    placeholder: String,
    linkMode: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: [Number, String],
      default: 268
    }
  },

  data() {
    return {
      textModel: this.value || ''
    }
  },

  methods: {
    blur(e) {
      const innerText = e.target && e.target.innerText;

      if (!innerText) {
        this.$emit('blur', '');
        return;
      }

      const innerTextMax = innerText.substring(0, this.maxlength);

      if (this.linkMode) {
        this.textModel = innerTextMax;
      }

      this.$emit('blur', innerTextMax);
    },
    getLinkString(str) {
      const textArr = str.replace(/\s/g, ' ').split(' ');

      return textArr
        .map(word => {
          if ((word.includes('https://') || word.includes('www.')) && !word.includes('<a')) {
            return `<a href="${word}" target="_blank">${word}</a>`;
          } else {
            return word;
          }
        })
        .join(' ');
    }
  }
}
</script>

<style lang="less" scoped>
.textarea {
  display: block;
  flex: 1;
  height: 100%;

  .fakeTextarea {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100px;
    margin-right: 10px;
    background-color: @bg-input;
    border: none;
    padding: 14px 18px;
    font-family: inherit;
    font-size: .85em;
    line-height: 1.3;
    border-radius: 22px;
    color: @cold-grey-dark;
    transition: background-color .2s, color .2s;
    resize: none;
    overflow: hidden;

    &:empty::before {
      content: var(--placeholder);
      display: block;
      color: @cold-grey;
      transition-duration: .2s;
    }

    &:not(.disabled) {
      &:hover {
        background-color: fade(@cold-grey, 15%);
      }

      &:focus {
        background-color: fade(@green-light, 20%);
        color: @cold-grey-dark;

        &::placeholder {
          color: fade(@cold-grey-dark, 60%);
        }
      }
    }

    &.disabled {
      background-color: fade(@cold-grey, 30%);
      opacity: .5;
    }
  }
}
</style>
