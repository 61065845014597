<template>
  <inline-svg :src="path"/>
</template>

<script>
export default {
  name: 'Icon',
  props: ['name'],
  computed: {
    path() {
      return require(`@/assets/img/icons/${this.name}.svg`)
    }
  }
}
</script>

<style scoped>

</style>
