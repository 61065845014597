const state = {
  sessions: [],
};

const getters = {
  getAutozakiSession: (state) => (user) => state.sessions.filter(a => a.session === 'autozaki' && a.user === user).slice(-1).pop(),
  getAllAutozakiSession: (state) => (user) => state.sessions.filter(a => a.session === 'autozaki' && a.user === user),
  getCallSession: (state) => (user, id) => state.sessions.filter(a => a.session === 'call' && a.user === user && (!id || a.call_id === id)).slice(-1).pop(),
  getAllCallSession: (state) => (user) => state.sessions.filter(a => a.session === 'call' && a.user === user),
  getChatSession: (state) => (user) => state.sessions.filter(a => a.session === 'chat' && a.user === user).slice(-1).pop(),
  getChatSessionById: (state) => (chatId) => state.sessions.find(a => a.session === 'chat' && JSON.parse(a.result).telegram?.id === chatId),
  getAllChatSession: (state) => (user) => state.sessions.filter(a => a.session === 'chat' && a.user === user),
  getFreeEntrySession: (state) => (user) => state.sessions.filter(a => a.session === 'freeEntry' && a.user === user).slice(-1).pop(),
  getAllFreeEntrySession: (state) => (user) => state.sessions.filter(a => (a.session === 'freeEntry' && a.user === user)),
};

const actions = {
  async saveSession({commit}, session) {
    commit('addSession', session);
  },
  async eraseSession({commit}, sessionId, user) {
    let session = state.sessions.filter(a => a.id === sessionId);
    if (session.user === user) {
      commit('removeSession', sessionId);
    }
  },
  async renewSession({commit}, sessionId, user) {
    let session = state.sessions.filter(a => a.id === sessionId);
    if (session.user === user) {
      commit('moveSession', sessionId);
    }
  },
  async resetAllSessions({commit}, user) {
    commit('resetAllSessions', user);
  },
  async resetOldSessions({commit}) {
    commit('resetOldSessions');
  },
  async updateSession({commit}, session) {
    commit('updateSession', session);
  }
};

const mutations = {
  addSession(state, session) {
    state.sessions.push(session);
    //state.sessions = state.sessions.slice(-5);
  },
  removeSession(state, sessionId) {
    state.sessions.splice(state.sessions.findIndex(a => a.id === sessionId), 1);
  },
  moveSession(state, sessionId) {
    state.sessions.push(state.sessions.splice(state.sessions.findIndex(a => a.id === sessionId), 1)[0]);
  },
  updateSession(state, session) {
    state.sessions.splice(state.sessions.findIndex(a => a.id === session.id), 1)
    state.sessions.push(session)
  },
  resetAllSessions(state, user) {
    state.sessions = state.sessions.filter(a => a.user !== user);
  },
  resetOldSessions(state) {
    state.sessions = state.sessions.filter(a => new Date() - a.now <= 12 * 60 * 60 * 1000);
  },
  cleanupChatSessions(state, chatId){
    state.sessions = state.sessions.filter(a => a.session !== 'chat' || a.telegram_id !== chatId)
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
