<template>
  <header v-if="isAuthenticated" class="header">
    <div class="title">
      <router-link v-if="title.url" :to="title.url" >{{ title.text }}</router-link>
      <span v-else>{{ title.text }}</span>
      <Button v-if="$route.name.includes('Chat') || $route.name.includes('FreeEntry') || $route.name.includes('Autozaki')" class="btn-close"
              @click="newSession">
        <Icon name="close-light"/>
      </Button>
    </div>
    <SipControl v-if="sip_on === 'true'"/>
    <CallHeaderSession/>
  </header>
</template>

<script>
import CallHeaderSession from '../call/CallHeaderSession';
import SipControl from '../call/SipControl';
import Icon from '../ui/Icon'
import Button from '../ui/input/Button';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Header',
  data() {
    return {
      sip_on: process.env.VUE_APP_SIP_ON,
    }
  },
  components: {CallHeaderSession, Icon, Button, SipControl},
  computed: {
    ...mapGetters({isAuthenticated: 'isAuthenticated', title: 'title'}),
  },
  methods: {
    ...mapActions(['setTitle']),
    newSession() {
      this.$bus.$emit('newSession', this.$route.name)
    },
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  background-color: @bg-lighter;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  .title {
    color: @cold-grey;
    display: flex;
    align-items: center;
    padding: 20px 0 20px 40px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-size: .75em;

    a {
      color: @green;
      text-underline-position: under;
    }

    .session-control {
      margin-left: 20px;

      /deep/ svg {
        width: 12px;
      }
    }

    .btn-close {
      margin-left: 10px;
      /deep/ svg {
        width: 10px;
        margin-left: auto;
        margin-right: auto;
        display: block !important;
      }
      background-color: white;
      color: @green-dark;
      width: 28px;
      height: 25px;
      padding: 0px;
      border-radius: 6px;
      border: 1px solid;
      border-color: @green-dark;
    }
  }
}
</style>
