<template>
  <div class="wrapper">
    <div class="search_bar">
      <div class="city_choice search_field">
        <input id="moscow" v-model="search_data.city" type="radio" value="moscow" @change="changeCity">
        <label for="moscow">Москва</label>
        <input id="spb" v-model="search_data.city" required type="radio" value="spb" @change="changeCity">
        <label for="spb">Санкт-Петербург</label>
        <input id="regions" v-model="search_data.city" required type="radio" value="regions" @change="changeCity">
        <label for="regions">Регионы</label>
        <input id="all" v-model="search_data.city" required type="radio" value="all" @change="changeCity">
        <label for="all">Все</label>
      </div>

      <SelectNew id="police_station" v-model="search_data.police_station" :extendedOptionSubTitle="'full_address'"
                 :extendedOptionTitle="'short_name'" :extendedView=true :idAsValue="true"
                 ajaxSearch="/?name__icontains=%s" ajaxUrl="police-station"
                 class="search_field"
                 displayKey="name"
                 optionsList="police-station"
                 placeholder="Отдел полиции"
      />

      <SelectNew id="lawyer_status"
                 v-model="search_data.lawyer_status" :idAsValue="true" class="search_field" optionsList="lawyer_status"
                 placeholder="Статус адвоката"/>

      <SelectNew id="lawyer_request_status"
                 v-model="search_data.lawyer_request_status" :idAsValue="true"
                 :multiple="true"
                 class="search_field"
                 optionsList="lawyer_request_status" placeholder="Запрос адвоката"/>
      <Button :nosubmit="false" :type="search_data.state==='loading'?'loading':'primary'" square
              @click="searchSimilar" :disabled="search_data.state==='loading'">
        <Icon name="search"/>
      </Button>
    </div>


    <div v-if="full_data && full_data.length" class="table">
      <Table v-for="(tab, index) in tabs" :key="index" :columns="tab.columns"
             :data="full_data" :selectedRows="selectedRows" @change="changeData"
             @select="toggleItem"
      />
    </div>
    <div v-else-if="search_data.state  === 'not-found'" class="larger not-found">Данные не найдены. Уточните запрос.
    </div>
    <div v-else class="larger not-found">Уточните условия поиска.</div>

    <Loader v-if="loading || search_data.state === 'loading'" />
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import debounce from 'lodash/debounce';

import Table from '../components/ui/EditableTable';
import SelectNew from '../components/ui/input/SelectNew';
import Icon from '../components/ui/Icon';
import Button from '../components/ui/input/Button';
import Loader from '../components/ui/Loader';

export default {
  name: 'JurHelp',
  components: {SelectNew, Table, Button, Icon, Loader},
  data() {
    return {
      host_read: '/detention-full-latest/',
      host_write: '/detention/',
      search_data: {
        city: 'all',
        police_station: null,
        lawyer_status: null,
        lawyer_request_status: [1, 3],
        state: 'idle',
        ready: true,
      },
      full_data: [],
      selectedRows: [],
      tabs: {
        jurist: {
          title: 'Юристы',
          columns: {
            'client.full_name': {
              title: 'ФИО',
              type: 'text',
              readonly: true,
            },
            'client.phone_number_json.0.phone_number': {
              title: 'Телефон',
              type: 'text',
              readonly: true,
            },
            'client.birth_date': {
              title: 'Дата рождения',
              type: 'datetime',
              readonly: true,
            },
            'client_pack': {
              title: 'ID автозака',
              type: 'link',
              readonly: true,
            },
            'status': {
              title: 'Статус',
              type: 'select',
              optionsList: 'detention_status',
              readonly: true,
            },
            'lawyer_request_status': {
              title: 'Запрос адвоката',
              type: 'select',
              optionsList: 'lawyer_request_status',
              bulkEdit: true,
            },
            'lawyer': {
              title: 'Адвокат',
              type: 'selectAjax',
              url: 'lawyer',
              displayKey: 'full_name',
              query: '/?full_name_similar=%s',
              bulkEdit: true,
            },
            'lawyer.phone_number_json.0.phone_number': {
              title: 'Телефон адвоката',
              type: 'text',
              readonly: true
            },
            'lawyer_status': {
              title: 'Статус адвоката',
              type: 'select',
              optionsList: 'lawyer_status',
              bulkEdit: true,
            },
            'city.name': {
              title: 'Город',
              type: 'text',
              readonly: true,
            },
            'police_station': {
              title: 'Отдел полиции', //+
              type: 'selectAjax',
              url: 'police-station',
              query: '/?name__icontains=%s',
              readonly: true,
            },
          }
        }
      },
      loading: false,
      counter: 0
    }
  },
  mounted() {
    this.setTitle({text: 'Юристы'});
  },
  computed: {
    ...mapGetters({
      city: 'city',
    }),
  },
  methods: {
    ...mapActions(['setTitle', 'getByUrlWithQuery', 'patchByUrlWithId']),
    cityId(name) {
      let city = this.city.find(x => x.name === name);
      if (city) return city.id;
    },
    searchSimilar() {
      this.search_data.state = 'loading';
      this.search_data.ready = false;
      let query = [];

      if (this.search_data.city) {
        let cityId;
        switch (this.search_data.city) {
        case 'moscow':
          cityId = this.cityId('Москва');
          break;
        case 'spb':
          cityId = this.cityId('Санкт-Петербург');
          break;
        case 'regions':
          break;
        case 'all':
          break;
        }

        if (cityId) {
          let subquery = 'city__id=' + cityId;
          query.push(subquery);
        }
      }

      if (this.search_data.police_station) {
        let subquery = 'police_station__id=' + this.search_data.police_station.id;
        query.push(subquery);
      }

      if (this.search_data.lawyer_status) {
        let subquery = 'lawyer_status=' + this.search_data.lawyer_status.id;
        query.push(subquery);
      }

      if (this.search_data.lawyer_request_status.length) {
        let subquery = 'lawyer_request_status__in=' + this.search_data.lawyer_request_status;
        query.push(subquery);
      }

      let t = this;

      this.getByUrlWithQuery({url: this.host_read, query: query})
        .then((r) => {

          let res = r.data?.results || r.data;
          if (t.search_data.city === 'regions' && res.length > 0) {
            res = res.filter(x =>
                  x?.city?.id !== this.cityId('Москва') && x?.city?.id !== this.cityId('Санкт-Петербург'));
          }

          this.$vueSet(this, 'full_data', res);

          if (this.full_data.length !== 0) {
            this.search_data = Object.assign({}, this.search_data, {state: 'found'});
          } else {
            this.search_data = Object.assign({}, this.search_data, {state: 'not-found'});
          }
          this.search_data = Object.assign({}, this.search_data, {ready: true});
        })
        .catch(e => {
          if (e.response.status === 400 || e.response.status === 800) {
            this.errors(e.response.data.detail_json);
          }
          this.search_data = Object.assign({}, this.search_data, {state: 'not-found'});
          this.search_data = Object.assign({}, this.search_data, {ready: true});
        });
    },
    changeCity() {
      this.search_data.police_station = null;
    },

    changeData(row = null, key = null, val = null) {
      this.loading = true;
      let itemIndex = this.full_data.findIndex(
        function (item) {
          return item.id === row.id;
        }
      );
      this.$vueSet(this.full_data[itemIndex], key, val);
      this.enterData(itemIndex);

      this.loading = false;
    },
    async enterData(index) {
      if (this.full_data[index]) {

        this.counter ++;
        let data = this.full_data[index];


        data.city = data.city?.id;
        data.client = data.client?.id;
        data.event = data.event?.id;
        data.police_station = data.police_station?.id;

        if (data.lawyer?.id) {
          data.lawyer = data.lawyer?.id;
        }

        await this.patchByUrlWithId({url: this.host_write, id: data.id, data: data});

        if (this.selectedRows && this.selectedRows.length === this.counter) {
          this.counter = 0;
          this.refreshWithDebounce(this);
        }
      }
    },
    refreshWithDebounce: debounce((vm) => {
      vm.searchSimilar();
    }, 250),
    toggleItem(id) {
      if (this.selectedRows.includes(id)) {
        this.selectedRows = this.selectedRows.filter(function (value) {
          return value !== id
        });
      } else {
        this.selectedRows.push(id);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.search_field {
  display: flex;
  align-items: center;
  margin: 0 10px;
  color: @green-dark;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 99;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 0.85em;

  /deep/ input.vs__search {
    font-size: 1.2em!important;
  }
}

.search_bar {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, white 65%, transparent);

  button {
    margin: auto 5px;
  }
}

.v-select[data-v-7329b974]:not(.vs--open) {
  overflow: hidden;
}

.table {
  overflow: auto;
}

.wrapper {
  position: relative;
}
</style>
