const state = {
  stations: {}
};

const getters = {
  policeStation: (state) => (id) => {
    return state.stations[id];
  }
}
let loading = [];
const actions = {
  async getStation(ctx, id) {

    let station = null;
    if (!ctx.state.stations[id] && !loading.includes(id)) {
      loading.push(id);
      station = await this._vm.$api.get('police-station/' + id + '/').catch(e => {
        throw new Error(e)
      }).finally(() => {
        let idIndex = loading.indexOf(id);
        if (idIndex !== -1) {
          loading.splice(idIndex, 1);
        }
      });
      ctx.commit('setStation', station?.data);
      return station?.data;
    } else if (loading.includes(id)) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(ctx.dispatch('getStation', id));
        }, 500);
      });
    } else {
      station = ctx.state.stations[id]
    }
    return station
  }
};

const mutations = {
  setStation(state, data) {
    state.stations[data.id] = data
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
