<template>

  <CardTransition :states="states" initialPath="isDetained" session_type="chat"></CardTransition>

</template>

<script>
import CardTransition from '../components/call/Card-Transition';
const states = {

  'isDetained': {
    component: 'IsDetained'
  },
  'detainedName': {
    component: 'DetainedName',
    detainedPerson: 'self'
  },
  'haveCalled': {
    component: 'HaveCalled'
  },
  'newClientPack': {
    component: 'NewClientPack'
  },
  'detainedName:haveCalled': {
    component: 'DetainedName',
    detainedPerson: 'previousCaller'
  },
  'callerName': {
    component: 'CallerName'
  },
  'wantSpecificPerson': {
    component: 'WantSpecificPerson'
  },
  'detainedName:notSelf': {
    component: 'DetainedName',
    detainedPerson: 'notSelf'
  },
  'clientPack': {
    component: 'ClientPack'
  },
  'whoToDetained': {
    component: 'WhoToDetained',
  },
  'city': {
    component: 'City'
  },
  'lawyerRequest': {
    component: 'LawyerRequest'
  }
};

export default {
  name: 'Chat',
  components: {
    CardTransition,
  },
  props: {
    chatId: [String, Number],
    id: [String, Number]
  },
  data() {
    return {
      states,
    }
  },
  mounted() {
    if(this.$route.params.query?.chatId) {
      setTimeout(() => {
        this.$bus.$emit('openChat', {chatId: this.$route.params.query.chatId, src: true});
      }, 1000);
    }
  }
}
</script>
