<template>
  <div>
    <div v-show="!$route.params.id" id="search_bar">
      <label>
        ФИО
        <TextInput v-model="search_data.full_name" name="full_name" placeholder="ФИО"/>
      </label>
      <label>
        Дата задержания
        <DateTimeInput v-model="search_data.detention_date" format="date" @blur="updateDate"/>
      </label>
      <label>
        Статья
        <TextInput v-model="search_data.article" name="article" placeholder="Статья"/>
      </label>
      <Button :nosubmit="false" :type="search_data.state==='loading'?'loading':'primary'" square
              @click="search_similar" :disabled="search_data.state==='loading'">
        <Icon name="search"/>
      </Button>
    </div>
    <div v-if="$route.params.id && resultByRouterId" id="info_bar">
      ФИО: <b>{{ resultByRouterId.detention.client.full_name }}</b>
      <br/>
      Дата задержания: <b>{{
        new Date(Date.parse(resultByRouterId.detention.detention_time)).toLocaleString('ru-RU', {timeZone: 'Europe/Moscow'})
      }}</b>
      <br/>
      Статья: <b>{{ resultByRouterId.article.name }}</b>
    </div>
    <div v-if="full_data && full_data.length">
      <Table :columns="tabs.court_results.columns"
             :data="full_data" :selectedRows="selectedRows" @change="changeData"
             @select="toggleItem" :copiedRow="copiedRow"
      />
    </div>
    <div v-else-if="search_data.state  === 'not-found'" class="not-found larger">Данные не найдены. Уточните запрос.
    </div>

  </div>

</template>

<script>
import Table from '../components/ui/EditableTable';
import Icon from '../components/ui/Icon';
import TextInput from '../components/ui/input/TextInput';
import DateTimeInput from '../components/ui/input/DateTimeInput';
import Button from '../components/ui/input/Button';

import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Court',
  components: {Table, TextInput, Button, Icon, DateTimeInput},
  data() {
    return {
      activeTab: null,
      selectedRows: [],
      copiedRow: [],
      default_url: '/case-full/',
      host: '/case-full/',
      search_data: {
        full_name: null,
        detention_date: undefined,
        article: null,
        state: 'idle',
        ready: true,
      },
      tabs: {
        court_results: {
          title: 'Результаты суда',
          columns: {
            'id': {
              title: 'Номер дела',
              type: 'text',
              readonly: true,
            },
            'detention.client_pack': {
              title: 'Номер автозака',
              type: 'link',
              readonly: true,
            },
            'detention.client.full_name': {
              title: 'ФИО',
              type: 'text',
              readonly: true,
            },
            'detention.event_name': {
              title: 'Акция',
              type: 'text',
              readonly: true,
            }
            ,
            'detention.detention_time': {
              title: 'Дата задержания',
              type: 'datetime',
              format: 'datetime',
              readonly: true,
            },
            'article.name': {
              title: 'Статья',
              type: 'text',
              readonly: true,
            },
            'first_court_session.appointed_time': {
              title: 'Дата суда 1 инст',
              type: 'datetime',
              format: 'datetime'
            },
            'first_court_session.court': {
              title: 'Суд 1 инст',
              type: 'selectAjax',
              url: 'court',
              query: '/?name__icontains=%s'
            },
            'first_court_session.lawyer': {
              title: 'Защитник в 1 инст',
              type: 'selectAjax',
              url: 'lawyer',
              displayKey: 'full_name',
              query: '/?full_name_similar=%s',
            },
            'first_court_session.result': {
              title: 'Результат 1 инст',
              type: 'select',
              optionsList: 'court_session_first',
            },
            'first_court_session.punishment_size': {
              title: 'Размер наказания',
              type: 'number',
            },
            'appeal_court_session.appointed_time': {
              title: 'Дата апелляции',
              type: 'datetime',
              format: 'datetime'
            },
            'appeal_court_session.court': {
              title: 'Апелляционный суд',
              type: 'selectAjax',
              url: 'court',
              query: '/?name__icontains=%s'
            },
            'appeal_court_session.lawyer': {
              title: 'Защитник в апелляции',
              type: 'selectAjax',
              url: 'lawyer',
              displayKey: 'full_name',
              query: '/?full_name_similar=%s',
            },
            'appeal_court_session.result': {
              title: 'Результат апелляции',
              type: 'select',
              optionsList: 'court_session_appeal',
            },
            'appeal_court_session.punishment_size': {
              title: 'Размер наказания',
              type: 'number',
            },
            'first_court_session.detention_center': {
              title: 'Спецприемник',
              type: 'selectAjax',
              url: 'detention-center',
              query: '/?name__icontains=%s'
            },
          }
        },

      },
      full_data: [],
      tableId: this.randomId()
    }
  },

  computed: {
    ...mapGetters(['events']),
    resultByRouterId() {
      let res = {};
      if (this.$route.params.id) {
        res = this.full_data.filter(x => x.id === this.$route.params.id)[0]
      }
      return res;
    },
  },
  methods: {
    ...mapActions(['setTitle', 'getByUrlWithQuery', 'getByUrl', 'patchByUrl']),
    enterData(index, key, val) {
      for (let i = 0; i < this.full_data[index].court_sessions.length; i++) {
        if (this.full_data[index].court_sessions[i].type === 10) {
          this.full_data[index].court_sessions[i] = this.full_data[index]['first_court_session'];
        } else if (this.full_data[index].court_sessions[i].type === 20) {
          this.full_data[index].court_sessions[i] = this.full_data[index]['appeal_court_session'];
        } else if (this.full_data[index].court_sessions[i].type === 30) {
          this.full_data[index].court_sessions[i] = this.full_data[index]['cassation_court_session'];
        }
      }

      if (!this.full_data[index].court_sessions.find(x => x.type === 10) && !this.full_data[index]['first_court_session'].empty) {
        this.full_data[index].court_sessions.push(this.full_data[index]['first_court_session']);
      }
      if (!this.full_data[index].court_sessions.find(x => x.type === 20) && !this.full_data[index]['appeal_court_session'].empty) {
        this.full_data[index].court_sessions.push(this.full_data[index]['appeal_court_session']);
      }
      if (!this.full_data[index].court_sessions.find(x => x.type === 30) && !this.full_data[index]['cassation_court_session'].empty) {
        this.full_data[index].court_sessions.push(this.full_data[index]['cassation_court_session']);
      }
      let [newKey, newValue] = key.split('.');
      const payload = {
        id: this.full_data[index].id,
        court_sessions: [
          {
            id: this.full_data[index].court_sessions[0].id,
            type: 10,
          },
          {
            id: this.full_data[index].court_sessions[1].id,
            type: 20,
          }
        ]
      };

      newKey === 'first_court_session'
        ? payload.court_sessions[0][newValue] = val
        : payload.court_sessions[1][newValue] = val;

      this.patchByUrl(payload)
        .catch(e => {
          if (e.response.status === 400 || e.response.status === 800) {
            this.errors(e.response);
          }
        });
    },
    errors(errors) {
      alert(errors);
    },
    changeData(caseItem = null, key = null, val = null) {
      let caseIndex = this.full_data.findIndex(
        function (item) {
          return item.id === caseItem.id;
        }
      );

      if (key.includes('first_court_session')) {
        this.full_data[caseIndex].first_court_session.empty = false;
      } else if (key.includes('appeal_court_session')) {
        this.full_data[caseIndex].appeal_court_session.empty = false;
      } else if (key.includes('cassation_court_session')) {
        this.full_data[caseIndex].cassation_court_session.empty = false;
      }

      this.$vueSet(this.full_data[caseIndex], key, val);
      this.enterData(caseIndex, key, val);
    },
    reroute(id) {
      let routeData = this.$router.resolve({name: 'Autozaki2', params: {id: id}});
      window.open(routeData.href, '_blank');
    },
    updateFullData(data) {

      for (let i in data) {
        let event = this.events.filter(x => x.id === data[i].detention.event)[0];
        data[i].detention.event_name = event ? event.name : '-';

        let first_court_session = {case: data[i].id, court: {}, type: 10, empty: true};
        let appeal_court_session = {case: data[i].id, court: {}, type: 20, empty: true};
        let cassation_court_session = {case: data[i].id, court: {}, type: 30, empty: true};

        if (data[i].court_sessions.length > 0) {
          for (let each of data[i].court_sessions) {
            if (each.type === 10) {
              first_court_session = each;
            } else if (each.type === 20) {
              appeal_court_session = each;
            } else if (each.type === 30) {
              cassation_court_session = each;
            }
          }
        }

        data[i].first_court_session = first_court_session;
        data[i].appeal_court_session = appeal_court_session;
        data[i].cassation_court_session = cassation_court_session;
      }

      return data;
    },
    randomId() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },
    updateDate(val) {
      this.search_data.detention_date = val;
    },
    search_similar() {
      this.search_data.state = 'loading';
      this.search_data.ready = false;
      let query = [];


      if (this.search_data.full_name) {
        let subquery = 'detention__client__full_name__icontains=' + this.search_data.full_name;
        query.push(subquery);
      }

      if (this.search_data.detention_date) {
        let subquery = 'detention__detention_time__date=' + this.search_data.detention_date;
        query.push(subquery);
      }

      if (this.search_data.article) {
        let subquery = 'article__name__icontains=' + this.search_data.article;
        query.push(subquery);
      }

      this.getByUrlWithQuery({url: this.host, query: query})
        .then((r) => {

          let res = r.data?.results;

          res = this.updateFullData(res);
          this.$vueSet(this, 'full_data', res);
          this.updateFullData();

          this.tableId = this.randomId();

          if (this.full_data.length !== 0) {
            this.search_data = Object.assign({}, this.search_data, {state: 'found'});
          } else {
            this.search_data = Object.assign({}, this.search_data, {state: 'not-found'});
          }
          this.search_data = Object.assign({}, this.search_data, {ready: true});

        })
        .catch(e => {
          if (e.response.status === 400 || e.response.status === 800) {
            this.errors(e.response.data.detail_json);
          }
          this.search_data = Object.assign({}, this.search_data, {state: 'not-found'});
          this.search_data = Object.assign({}, this.search_data, {ready: true});
          throw new Error('error', e);
        });

    },
    toggleItem(id) {
      if (this.selectedRows.includes(id)) {
        this.selectedRows = this.selectedRows.filter(function (value) {
          return value !== id
        });
      } else {
        this.selectedRows.push(id);
      }
    }
  },
  beforeDestroy() {

  },
  mounted() {
    this.setTitle({text: 'Результаты суда'});

    if (this.$route.params.id) {
      this.host = this.default_url + this.$route.params.id;
    } else {
      this.host = this.default_url
    }

    this.getByUrl(this.host)
      .then(
        r => {

          let res;

          if (Object.keys(r.data).includes('results')) {
            res = r.data?.results;
          } else {
            res = r.data;
          }

          if (!(Object.prototype.toString.call(res) === '[object Array]')) {
            res = [res];
          }

          res = this.updateFullData(res);

          this.$vueSet(this, 'full_data', res);
        }
      )
      .catch(e => {
        if (e.response.status === 400 || e.response.status === 800) {
          this.errors(e.response.data.detail_json);
        }
      });
  }
}
</script>

<style lang="less">
#search_bar {
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  align-content: center;
  justify-content: center;

  label {
    color: @green-dark;
    text-transform: uppercase;
    letter-spacing: .02em;
    font-weight: 500;

  }

}

.larger {
  font-size: 1.2em;
  text-align: center;
  margin: 20px;
}

</style>
