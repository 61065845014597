<template>
  <div class="client-info">
    <div class="client-info-main">
      <span class="client-info-number">{{ rowNumber }}</span>
      <Checkbox :value="selected" @input="$emit('select', client.id || client.tempId)"/>
      <div class="client-info-attrs">
        <Checkbox :icon="'speakerphone' + (client.publication_forbidden ? '-off' : '')"
                  :tooltip="client.publication_forbidden ? 'Не публиковать' : 'Можно публиковать'"
                  tooltipPosition="right"
                  :value="getValue('publication_forbidden', client)"
                  @input="$emit('change', client, 'publication_forbidden', $event)"

        />
        <Checkbox :tooltip="unknownData" :value="getValue('client_json.is_unknown', client)" disabled
                  icon="question-bold" tooltipPosition="right" />
        <Checkbox :tooltip="client.is_underage ? 'Несовершеннолетний': 'Совершеннолетний'" :value="getValue('is_underage', client)"
                  icon="underage" tooltipPosition="right"
                  @input="$emit('change', client, 'is_underage', $event)"
        />
      </div>
      <TextInput :value="getValue('client_json.full_name', client)" class="client-info-name"
                 table
                 @blur="$emit('change', client, 'client_json.full_name', $event)"
      />
    </div>

    <div v-show="showDetails" class="client-info-details">
      Some additional content here
    </div>
  </div>
</template>

<script>
import Checkbox from '../ui/input/Checkbox';
import TextInput from '../ui/input/TextInput';

export default {
  name: 'ClientInfoColumn',
  components: {TextInput, Checkbox},
  props: ['client', 'rowNumber', 'selected'],
  data() {
    return {
      showDetails: false,
      showComments: false
    }
  },
  methods: {
    getValue(key, root = this.client) {

      key = key.replace(/\[(\d+)]/, '.$1');
      return key.split('.').reduce((o, i) => o ? o[i] : o, root);
    },
    comment(id, item) {
      this.$emit('comment', id, item);
    }
  },
  computed: {
    unknownData() {
      if (this.client?.client_json?.is_unknown) {
        let fields = [
          this.client?.client_json?.first_name ? null : 'имени',
          this.client?.client_json?.last_name ? null : 'фамилии',
          this.client?.client_json?.birth_date ? null : 'даты рождения',
          this.client?.client_json?.phone_number?.length || this.client?.client_json?.telegram_id?.length
            ? null : 'телефона или Telegram',
        ];
        return `Не хватает: ${fields.filter(Boolean).join(', ')}`
      }
      return null
    }
  }
}
</script>

<style lang="less" scoped>
.client-info {
  padding-right: 10px;

  &-main {
    display: flex;
    align-items: center;
    min-height: 32px;

    > :not(:last-child) {
      margin-right: 15px;
    }
  }

  &-number {
    font-size: @middle-font !important;
    color: @cold-grey-dark !important;
    white-space: nowrap;
    min-width: 15px;
  }

  &-attrs {
    display: flex;

    /deep/ .checkbox:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  /deep/ .checkbox-label-icon {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.09);

    svg {
      height: 16px;
    }
  }

  &-name {
    /deep/ input {
      min-width: 15rem;
      font-size: inherit;
    }

    /deep/ span {
      font-weight: 700;
      color: @cold-grey-dark !important;
      white-space: nowrap;
    }
  }

  &-show-details {
    cursor: pointer;
    color: @green;
    width: 12px;
    height: 12px;

    &.active {
      transform: rotate(180deg);
    }
  }

  &-details {
    margin-top: 10px;
  }
}
</style>
