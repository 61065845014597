<template>
  <TextInput
      :disabled="disabled"
      :invalid="!!error"
      :loading="loading"
      :mask="mask"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :table="table"
      :tooltip="tooltip"
      :value="inputValue"
      @blur="blur"
      @complete="input"
  />
</template>

<script>
import TextInput from './TextInput';
import IMask from 'imask';
import dayjs from 'dayjs';
import * as CustomParseFormat from 'dayjs/plugin/customParseFormat'

const formatBlocks = {
  YYYY: {
    mask: IMask.MaskedRange,
    from: 1900,
    to: 2100
  },
  MM: {
    mask: IMask.MaskedRange,
    from: 1,
    to: 12
  },
  DD: {
    mask: IMask.MaskedRange,
    from: 1,
    to: 31
  },
  HH: {
    mask: IMask.MaskedRange,
    from: 0,
    to: 23
  },
  mm: {
    mask: IMask.MaskedRange,
    from: 0,
    to: 59
  }
};

dayjs.extend(CustomParseFormat);
dayjs.extend(require('dayjs/plugin/utc'));

export default {
  name: 'DateTimeInput',
  components: {TextInput},
  props: {
    tooltip: String,
    value: String,
    placeholder: Date,
    name: Date,
    required: Boolean,
    disabled: Boolean,
    loading: Boolean,
    table: Boolean,
    format: {
      type: String,
      default: 'date'
    },
    beforeToday: Boolean,
    timezone: {
      type: Number,
      default: 3
    }

  },
  data() {
    return {
      error: null
    }
  },
  methods: {
    input(val) {
      if (!val) this.$emit('input', null);
      else {
        val = dayjs(val, this.pattern);

        if (this.timezone && this.format !== 'date') {
          let temp = val.add(-this.timezone, 'hours');
          let offset = new Date(temp).getTimezoneOffset();

          temp = temp.add(-offset, 'minutes');
          val = temp;
        }

        this.validate(val);
        this.$emit('blur', val.format(this.format === 'date' ? 'YYYY-MM-DD' : null))
      }
    },
    blur(val) {
      if (!val) this.$emit('blur', null);
      else {
        val = dayjs(val, this.pattern);

        if (this.timezone && this.format !== 'date') {
          let temp = val.add(-this.timezone, 'hours');
          let offset = new Date(temp).getTimezoneOffset();

          temp = temp.add(-offset, 'minutes');
          val = temp;
        }

        this.validate(val);
        this.$emit('blur', val.format(this.format === 'date' ? 'YYYY-MM-DD' : null))
      }
    },
    validate(val) {
      if (!val.isValid()) return false;
      if (this.beforeToday && this.format === 'date') {
        if (!val.isBefore(dayjs().startOf('day'))) {
          this.error = 'Дата должна быть раньше сегодня';
          return false
        }
      } else if (this.beforeToday && this.format === 'datetime') {
        if (!val.isBefore(dayjs())) {
          this.error = 'Дата должна быть раньше текущего момента';
          return false
        }
      }
      this.error = null;
      return true
    }
  },
  computed: {
    inputValue() {
      if (this.value) {

        if (this.timezone) {
          let temp = dayjs.utc(this.value);
          temp = temp.add(this.timezone, 'hours');
          return temp.format(this.pattern);
        } else {
          return dayjs(this.value).format(this.pattern);
        }
      }
      return null
    },
    pattern() {
      let pattern;
      switch (this.format) {
      case 'time':
        pattern = 'HH:mm';
        break;
      case 'datetime':
        pattern = 'DD.MM.YYYY HH:mm';
        break;
      case 'date':
      default:
        pattern = 'DD.MM.YYYY';
        break;
      }
      return pattern
    },
    mask() {
      return {mask: this.pattern, blocks: formatBlocks, lazy: false}
    }
  }
}
</script>
