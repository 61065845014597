<template>
  <div class="sidebar-content">
    <div v-if="!collapsed" class="session">
      <div class="session-info">
        <div class="session-primary">
          <span v-if="data.session.name" class="session-name">Звонок №{{ data.session.name }}</span>
          <span v-if="data.time" class="session-time">{{ data.time }}</span>
        </div>
        <div class="session-details">
          <Icon name="clock"/>
          {{ data.session.time }}
        </div>
        <div class="session-details">
          <Icon name="phone-small"/>
          {{ data.phone }}
        </div>
        <div class="session-details">
          <Icon name="person-fill"/>
          {{ data.person }}
        </div>
      </div>
      <div v-if="call.phoneState === 'call-in-progress'" class="session-actions">
        <button :class="['mic-toggle', 'outline', {active: call.mute}]"
                :data-tooltip="call.mute ? 'Включить микрофон' : 'Выключить микрофон'"
                data-tooltip-small
                @click="$sip.toggleMute(!call.mute)">
          <Icon v-if="call.mute" name="mic-off"/>
          <Icon v-else name="mic"/>
        </button>
        <button class="forward" data-tooltip="Переадресовать звонок" data-tooltip-small @click="transfer">
          <Icon name="forward"/>
        </button>
        <button class="end-call" data-tooltip="Завершить звонок" data-tooltip-small @click="$sip.endCall()">
          <Icon name="phone"/>
        </button>
        <button class="end-session" data-tooltip="Отправить СМС" data-tooltip-small @click="sendSMS">
          SMS
        </button>
      </div>
      <div v-if="call.phoneState === 'idle' && hasCall" class="session-actions">
        <button class="end-session fitWidth" data-tooltip="Прослушать звонок" data-tooltip-small @click="listenAudio">
          Прослушать звонок
        </button>
      </div>
      <div v-if="call.phoneState === 'idle' && hasCall" class="session-actions">
        <button :disabled="!hasCall" class="end-call fitWidth" data-tooltip="Завершить сеанс" data-tooltip-small
                @click="newSession">
          Завершить сеанс
        </button>
        <button class="end-session" data-tooltip="Отправить СМС" data-tooltip-small @click="sendSMS">
          SMS
        </button>
      </div>
    </div>

    <div v-else class="session">
      <div class="small-session">
        <div>
          <span class="small-session-time">{{ data.time }}</span>
        </div>
        <div v-if="call.phoneState === 'call-in-progress'" class="session-actions session-actions__vertical">
          <button :class="['mic-toggle', 'outline', {active: call.mute}]" :data-tooltip="call.mute ? 'Включить микрофон' : 'Выключить микрофон'"
                  data-tooltip-position="left" @click="$sip.toggleMute(!call.mute)">
            <Icon v-if="call.mute" name="mic-off"/>
            <Icon v-else name="mic"/>
          </button>
          <button class="forward" data-tooltip="Переадресовать звонок" data-tooltip-position="left" @click="transfer">
            <Icon name="forward"/>
          </button>
          <button :disabled="!hasCall" class="end-call" data-tooltip="Завершить звонок" data-tooltip-position="left"
                  @click="$sip.endCall()">
            <Icon name="phone"/>
          </button>
          <button class="end-session" data-tooltip="Отправить СМС" data-tooltip-position="left" data-tooltip-small
                  @click="sendSMS">
            SMS
          </button>
        </div>
        <div v-if="call.phoneState === 'idle' && hasCall" class="session-actions session-actions__vertical">
          <button class="end-session fitWidth" data-tooltip="Прослушать звонок" data-tooltip-position="left"
                  @click="listenAudio">
            <Icon name="speakerphone"/>
          </button>
        </div>
        <div v-if="call.phoneState === 'idle' && hasCall" class="session-actions session-actions__vertical">
          <button class="end-session" data-tooltip="Завершить сеанс" data-tooltip-position="left" @click="newSession">
            <Icon name="close"/>
          </button>
          <button class="end-call" data-tooltip="Отправить СМС" data-tooltip-position="left" data-tooltip-small
                  @click="sendSMS">
            SMS
          </button>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Icon from '../ui/Icon';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

export default {
  name: 'CallSidebarSession',
  components: {Icon},
  props: {
    data: {},
    collapsed: Boolean
  },
  computed: {
    ...mapState(['call']),
    ...mapGetters({
      hasCall: 'call/hasCall'
    }),
  },
  methods: {
    ...mapMutations({transferCall: 'call/setTransferCall'}),
    ...mapActions({resetSession: 'call/resetSession'}),
    transfer() {
      this.transferCall(true)
    },
    newSession() {
      console.log('CallSideBarSession: newSession');
      this.resetSession()
    },
    sendSMS() {
      this.$emit('sendSMS');
    },
    listenAudio() {
      this.$emit('listenAudio')
    },
  },
}
</script>

<style lang="less" scoped>

.sidebar-content {
  height: 100%;

  .session {
    background-color: @bg-lighter;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &-primary {
      background-color: #EBEDF3;
      color: @cold-grey-dark;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
    }

    &-name {
      font-size: .9em;
    }

    &-time {
      font-size: 1.8em;
    }

    &-details {
      display: flex;
      align-items: center;
      padding: 20px 15px;
      color: @red-light;
      font-size: .9em;
      line-height: 1;
      border-bottom: 1px solid rgba(197, 199, 217, 0.2);

      svg {
        height: 18px;
        width: 18px;
        margin-right: 15px;
        color: @cold-grey;
      }
    }

    &-actions {
      display: flex;
      justify-content: center;
      padding: 40px;

      &__vertical {
        background-color: @bg-lighter;
        color: @cold-grey-dark;
        padding: 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
          cursor: pointer;
        }

        button.end-session {
          color: white;
          cursor: pointer;
          background-color: var(--color);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

          svg {
            margin: 15px
          }
        }
      }

      button {
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
        --color: @red;
        color: var(--color);
        position: relative;
        font-family: inherit;
        margin: 10px;

        &:only-child {
          width: 100%;
        }

        &.outline.active {
          box-shadow: 0 0 0 1px var(--color), 0 0 13px rgba(0, 0, 0, 0.1);
        }

        &.end-call {
          color: white;
          cursor: pointer;
          background-color: var(--color);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

          &:disabled {
            background-color: @red-light;
            cursor: not-allowed;
          }
        }
      }
    }

    &-comment {
      flex: 1;

      & /deep/ textarea {
        border-radius: 0;
      }
    }
  }

  .small-session {
    background-color: @bg-lighter;
    flex: 1;
    text-align: center;
    color: @cold-grey-dark;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-time {
      font-weight: 500;
    }
  }
}

.fitWidth {
  width: fit-content !important;
}

</style>
